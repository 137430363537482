import { Component, OnInit, EventEmitter, Input, Inject, enableProdMode, NgModule } from '@angular/core';
import { DataService } from '../../Core/Services/data.service'
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'nh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  protected dataSvc: DataService;
  public weatherForeCast: Object;
  public pingResponse: string;
  public submitResponse: string;
  public GetUpdatedJobsResponse: string;
  public GetDocumentsResponse: string;

  constructor(private data: DataService, private authenticateService: AuthenticateService) {
    this.dataSvc = data;
    //this.authenticateService.IsAuthenticated();
  }

  ngOnInit() {

  }

  PingPST() {
    this.data.PingPST().subscribe(data => {
      this.pingResponse = data;
    });
  }
  SubmitTransaction() {
    this.data.SubmitTransaction().subscribe(data => {
      this.submitResponse = data;
    });
  }

  GetUpdatedJobs() {
    this.data.GetUpdatedJobs().subscribe(data => {
      this.GetUpdatedJobsResponse = data;
    });
  }

  GetDocuments() {
    this.data.GetDocuments().subscribe(data => {
      this.GetDocumentsResponse = data;
    });
  }
}
