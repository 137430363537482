import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'nh-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  ds: DataService;
  xmlForm: FormGroup;
  submitResponse: string;
  public xmlfile: string = "";
  constructor(private formBuilder: FormBuilder,private _ds: DataService) {
    this.ds = _ds;
  }
  get f() { return this.xmlForm.controls; }

  ngOnInit() {
    this.xmlForm = this.formBuilder.group({
      xmlfile: ['', Validators.required]
    });
  }

  postXML() {
    this.ds.SubmitTransactionWithXML(this.f.xmlfile.value).subscribe(data => {
      this.submitResponse = data;
    });
  }
}
