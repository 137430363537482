import {
  Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, Type
} from '@angular/core';
import { PopupComponent } from './popup.component';
import { PopupInjector } from './Popup.Injector';
import { PopupConfig } from './popup-config';
import { PopupRef } from './popup-ref';
//import {}


@Injectable({
  providedIn: 'root'
})

export class PopupService {

  popupComponentRef: ComponentRef<PopupComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector){

      //document.querySelector('#popupBox').setAttribute("style", "max-width: 40vw;min-height: 50vh;");
  }

  appendPopupComponentToBody(config: PopupConfig) {
    const map = new WeakMap();
    map.set(PopupConfig, config);
    const popupRef = new PopupRef();
    map.set(PopupRef, popupRef);
    const sub = popupRef.afterClosed.subscribe(() => {
      this.removePopuComponentFormBody();
      sub.unsubscribe();
    });
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PopupComponent);
    const componentRef = componentFactory.create(new PopupInjector( this.injector,map));
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as
      EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.popupComponentRef = componentRef;
    this.popupComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });
    return popupRef;
  }

  private removePopuComponentFormBody() {
    this.appRef.detachView(this.popupComponentRef.hostView);
    this.popupComponentRef.destroy();
  }

  public open(componentType: Type<any>, config: PopupConfig) {
  //  console.log(config);
    // alert(config.data);
    const dialogRef = this.appendPopupComponentToBody(config);
   // this.appendPopupComponentToBody();
    //console.log(componentType);
    //alert(componentType);
    this.popupComponentRef.instance.childComponentType = componentType;
    return dialogRef;
  }

  private removeDialogComponentFromBody() {
    this.appRef.detachView(this.popupComponentRef.hostView);
    this.popupComponentRef.destroy();
  }
}
