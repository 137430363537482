import { Component, OnInit, EventEmitter, Output, OnChanges, SimpleChange, KeyValueDiffer, OnDestroy } from '@angular/core';
import { DataService } from "src/app/Core/Services/data.service";
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { IncomingLog } from "src/app/Core/Models/IncomingLog/incoming-log";
import { DoCheck, KeyValueDiffers } from '@angular/core';
import { isUndefined } from 'util';

@Component({
  selector: 'nh-incominglog',
  templateUrl: './incominglog.component.html',
  styleUrls: ['./incominglog.component.less']
})

export class IncominglogComponent implements OnInit, OnDestroy {

  ds: DataService;
  xmlForm: FormGroup;
  submitResponse: string;
  public xmlfile: string = "";
  incomingLogs: Array<IncomingLog>;
  failedLogs: Array<IncomingLog>;
  ComponentName = "Incoming-Log";
  order = true;
  temp: Array<IncomingLog>;
  searchedData: Array<IncomingLog> = null;
  emptyResult: boolean = false;

  filteredFor: string = 'View Failed';

  // data being used for sorting process.   
  sortStatus: any = [
    { "label": "incomingLog_ID", 'value': 1 },
    { "label": "batchPriority_Id", 'value': 1 },
    { "label": "lawFirmName", 'value': 1 },
    { "label": "receivedDate", 'value': 1 },
    { "label": "processSuccessfulYN", 'value': 1 },
    { "label": "errorDetailShort", 'value': 1 },
  ];

  // add sortable columns here.
  sortableTableHeader = [
    { "label": "batchPriority_Id" },
    { "label": "lawFirmName" },
    { "label": "receivedDate" },
    { "label": "processSuccessfulYN" },
  ];

  differ: KeyValueDiffer<string, any>;

  constructor(private formBuilder: FormBuilder, private _ds: DataService) {
    this.ds = _ds;
  }

  get f() { return this.xmlForm.controls; }

  ngOnInit() {
    // API CALL DON'T Remove this.
    
    this.ds
      .incominglog()
      .subscribe(data => {

        try {
          this.incomingLogs = data.incomingloglist;

          // building mail-to string here.
          //this.incomingLogs.forEach(function (value, index) {
          //    value.contacts.forEach(function (data, index) {
          //      if (index > 0) {
          //          value.mailto = value.mailto + data.contactEmail;
          //      } else {
          //          value.mailto = data.contactEmail;
          //        }
          //    });
          //});

          // temp used to reset the incoming log, after performing sorting and searching.
          // In any edge case to reset the data need an another API call.
          this.temp = this.incomingLogs;

          this.failedLogs = _.filter(this.incomingLogs, function (data) {
                              if (data) {
                                return !data.processSuccessfulYN;
                              }
          });
          setTimeout(function () {
            let gridBody = document.getElementById("IncomingLogGrid").children.item(1);// getElementsByClassName("tablebody").item(0)
            let topHeight = document.getElementById("IncomingLogGrid").offsetTop;
            
            let gridHeight = window.innerHeight - topHeight-54;
            gridBody.setAttribute("style", "height:" + gridHeight + "px"); }, 500);
         

        } catch (error) {
          console.error(error);
        }
        
      });

    // add class to the table
    var section = document.querySelector('#rootSectionId');
    section.className = "wrapper background tableComponent";
  }

  ngOnDestroy() {
    // remove class to the table
    var section = document.querySelector('#rootSectionId');
    section.className = "wrapper background";
  }

  //sendMail(id) {
  //  this.ds
  //    .sentIncomingLogActionEmail(id)
  //    .subscribe(data => {
  //      this.submitResponse = data;
  //    });
  //}
  sendMail(IncomingLog: IncomingLog) {
    if (!isUndefined(IncomingLog)) {
      console.log(IncomingLog);
      IncomingLog.subject = "Error Information from N and H for " + IncomingLog.lawFirmName + " on " + IncomingLog.receivedDate;
      window.location.href = "mailto:" + IncomingLog.mailto + "?subject=" + IncomingLog.subject + "&body=" + (IncomingLog.errorDetailLong);
    }
  }
  changeSortIcon(ColumnName: string): number {
    var value: number = null;
    this.sortStatus.forEach(element => {
      if (element.label == ColumnName) {
        if (element.value != 3) {
          element.value++;
        } else {
          element.value = 1;
        }
        value = element.value;
      } else {
        element.value = 1;
      }
    });
    return value;
  }

  // sorting Column
  sort(ColumnName: string) {

    try {
      var sortValue = this.changeSortIcon(ColumnName);

      if (this.filteredFor == "View Failed") {

        if (sortValue != 1) {
          if (sortValue == 2) {
            this.failedLogs = _.orderBy(this.failedLogs, [ColumnName], ['asc']);
            this.order = false;
          } else {
            this.failedLogs = _.orderBy(this.failedLogs, [ColumnName], ['desc']);
            this.order = true;
          }
        } else {
          
          this.failedLogs = _.filter(this.incomingLogs, function (data) {
                          if (data) {
                            return !data.processSuccessfulYN;
                          }
                        });;
        }

      } else {

        if (sortValue != 1) {
          if (sortValue == 2) {
            this.failedLogs = _.orderBy(this.incomingLogs, [ColumnName], ['asc']);
            this.order = false;
          } else {
            this.failedLogs = _.orderBy(this.incomingLogs, [ColumnName], ['desc']);
            this.order = true;
          }
        } else {
          
          this.failedLogs = this.temp;
        }

      }

    } catch (error) {
      console.log(error);
    }

  }

  // sort priority column
  LawFarmPriorityFilter(priority) {
    try {
      this.incomingLogs = _.filter(this.incomingLogs, function (data, index) {
        return data.BatchPriority_Id == priority;
      });
    } catch (error) {
      console.log(error);
    }
  }

  // get's searched details from the search component to the incoming log.
  searchValues(data: any) {

    if (data.filterStatus) {

      if (data.filterFor == "View All") {                
        this.filteredFor = data.filterFor;
        this.failedLogs = this.temp;
        this.sortStatus = [
                          { "label": "incomingLog_ID", 'value': 1 },
                          { "label": "batchPriority_Id", 'value': 1 },
                          { "label": "lawFirmName", 'value': 1 },
                          { "label": "receivedDate", 'value': 1 },
                          { "label": "processSuccessfulYN", 'value': 1 },
                          { "label": "errorDetailShort", 'value': 1 },
        ];
        this.emptyResult = false;
          return;
      } else {
        this.filteredFor = data.filterFor;
        this.failedLogs = data.data;
        this.sortStatus = [
                            { "label": "incomingLog_ID", 'value': 1 },
                            { "label": "batchPriority_Id", 'value': 1 },
                            { "label": "lawFirmName", 'value': 1 },
                            { "label": "receivedDate", 'value': 1 },
                            { "label": "processSuccessfulYN", 'value': 1 },
                            { "label": "errorDetailShort", 'value': 1 },
                          ];
          return;
      }

    }

    try {
      if (data == 'reset') {
        this.incomingLogs = this.temp;
      } else {

        if (data.length) {
          this.emptyResult = false;
        } else {
          this.emptyResult = true;
        }

        // searchedData is used when sort on searched result.
        this.searchedData = data;
        // incomingLogs is used to show searched result
        //this.incomingLogs = data;
        this.failedLogs = data;
      }
    } catch (error) {
      console.log(error);
    }
    
  }

}
