export class Batch {

  constructor() {
    this.LawFirmDetails = new LawFirmDetials();
    this.DefendantDetails = new Array<DefendantDetails>();
    this.PlainTiffDetails = new PlainTiffDetails();
  }

  LawFirmDetails: LawFirmDetials;
  PlainTiffDetails: PlainTiffDetails;
  DefendantDetails: Array<DefendantDetails>;

}

class LawFirmDetials {

  constructor() {
    this.lawFirmName = "";
    this.priority = "";
    this.contactName = "";
    this.contactEmail = "";
  }

  lawFirmName: string;
  priority: string;
  contactName: string;
  contactEmail: string;
}

class PlainTiffDetails {

  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.courtName = "";
    this.county = "";
    this.leadDefendantName = "";
    this.deliveryByDate = "";
  }

  firstName: string;
  lastName: string;
  courtName: string;
  county: string;
  leadDefendantName: string;
  deliveryByDate: string;
}

export class DefendantDetails {

  constructor() {
    this.defendantName = "";
    this.streetAddress1 = "";
    this.streetAddress2 = "";
    this.streetAddress3 = "";
    this.streetAddress4 = "";
    this.streetAddress5 = "";
    this.state = "";
    this.zipCode = "";
    this.city = "";
    this.country = "";
    this.defendantFiles = new Array<BatchFile>();
    this.batchFiles = "";
    this.orderNumber = "";
    this.fileNames = "";
    this.suffix = "";
  }

  defendantName: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  streetAddress4: string;
  streetAddress5: string;
  country: string;
  state: string;
  zipCode: string;
  city: string;
  defendantFiles: Array<BatchFile>;
  batchFiles: string;
  orderNumber: string;
  fileNames: string;
  suffix: string;
}

export class Defendantdetails1 {

  constructor() {
    this.DefendantName = ""; 
    this.DefendantAddressGroup = new Array<DefendantAddressGroup>();
    this.DefendantDocumentDetails = new Array<DefendantDocumentDetails>();
  }

  DefendantName: string;
  DefendantAddressGroup: Array<DefendantAddressGroup>;
  DefendantDocumentDetails: Array<DefendantDocumentDetails>;
}

export class DefendantAddressGroup {

  constructor() {
  this.Defendantaddress =  Array<Defendantaddress>();
  }

  Defendantaddress: Array<Defendantaddress>;
}

export class Defendantaddress {

  constructor() {
    this.Ordernumber = null;
    this.streetaddress = new Array<streetLine>();
    this.city = null;
    this.state = null;
    this.zipCode = null;
    this.country = null;
  }

  Ordernumber: string;
  streetaddress: Array<streetLine>;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export class streetLine {

  constructor() {
    this.streetLine = new Array<string>();
  }

 streetLine: Array<string>;
}

export class DefendantDocumentDetails {
  DocumentFileName: Array<string>;
}

export class DefendantSub {

  constructor() {
    this.DefendantName = "";
    this.streetAddress1 = "";
    this.streetAddress2 = "";
    this.streetAddress3 = "";
    this.streetAddress4 = "";
    this.streetAddress5 = "";
    this.state = "";
    this.zipCode = "";
    this.city = "";
    this.country = "";
    this.fileNames = "";
  }

  DefendantName: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  streetAddress4: string;
  streetAddress5: string;
  country: string;
  state: string;
  zipCode: string;
  city: string;
  fileNames: string;

}

export class BatchFile {

  constructor() {
    this.file = null;
    this.pagecount =  0;
  }

  file: any;
  pagecount: number;
}

