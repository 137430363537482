export class Ridersummarysearch {
  plaintiffRefId: string;
  plaintiffName: string;
  caseId: string;
  dateFilterType: number;
  fromDate: string;
  toDate: string;
  priorityID: number;
  isDefault: number;  
}

export class Ridersummarydetails {
  caseId: string;
  plaintiffRefId: string;
  plaintiffName: string;
  priority: string;
  submitDate: string;
  serveByDate: string;
  ServedDetails: string;
  eFiledDetails: string;
  invoicedDetails: string;
}
