import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopupConfig } from 'src/app/Shared/Popup/popup-config';
import { PopupRef } from 'src/app/Shared/Popup/popup-ref';
import { DataService } from 'src/app/Core/Services/data.service';
import { DefendantDataModelService } from './DefendantDataModelService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DefendantDetails, BatchFile } from 'src/app/Core/Models/Batch/BatchModel';
import _ from 'lodash';

@Component({
  selector: 'nh-defendant-details',
  providers: [DefendantDataModelService],
  templateUrl: './DefendantDetails.component.html',
  styleUrls: ['./DefendantDetails.component.less']
})

/** DefendantDetails component */
export class DefendantDetailsComponent implements OnInit, OnDestroy {

  dataService: DataService;
  defendantFormGroup: FormGroup;
  editDefendantDetails: DefendantDetails = new DefendantDetails();
  attachedFileNames: string = "";
  edit: boolean = false;

  // US States and Canada States  
  usAndCaStatesLists = [{
    "name": "Alabama",
    "abbreviation": "AL"
  }, {
    "name": "Alaska",
    "abbreviation": "AK"
  }, {
    "name": "American Samoa",
    "abbreviation": "AS"
  }, {
    "name": "Arizona",
    "abbreviation": "AZ"
  }, {
    "name": "Arkansas",
    "abbreviation": "AR"
  }, {
    "name": "California",
    "abbreviation": "CA"
  }, {
    "name": "Colorado",
    "abbreviation": "CO"
  }, {
    "name": "Connecticut",
    "abbreviation": "CT"
  }, {
    "name": "Delaware",
    "abbreviation": "DE"
  }, {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  }, {
    "name": "Federated States Of Micronesia",
    "abbreviation": "FM"
  }, {
    "name": "Florida",
    "abbreviation": "FL"
  }, {
    "name": "Georgia",
    "abbreviation": "GA"
  }, {
    "name": "Guam",
    "abbreviation": "GU"
  }, {
    "name": "Hawaii",
    "abbreviation": "HI"
  }, {
    "name": "Idaho",
    "abbreviation": "ID"
  }, {
    "name": "Illinois",
    "abbreviation": "IL"
  }, {
    "name": "Indiana",
    "abbreviation": "IN"
  }, {
    "name": "Iowa",
    "abbreviation": "IA"
  }, {
    "name": "Kansas",
    "abbreviation": "KS"
  }, {
    "name": "Kentucky",
    "abbreviation": "KY"
  }, {
    "name": "Louisiana",
    "abbreviation": "LA"
  }, {
    "name": "Maine",
    "abbreviation": "ME"
  }, {
    "name": "Marshall Islands",
    "abbreviation": "MH"
  }, {
    "name": "Maryland",
    "abbreviation": "MD"
  }, {
    "name": "Massachusetts",
    "abbreviation": "MA"
  }, {
    "name": "Michigan",
    "abbreviation": "MI"
  }, {
    "name": "Minnesota",
    "abbreviation": "MN"
  }, {
    "name": "Mississippi",
    "abbreviation": "MS"
  }, {
    "name": "Missouri",
    "abbreviation": "MO"
  }, {
    "name": "Montana",
    "abbreviation": "MT"
  }, {
    "name": "Nebraska",
    "abbreviation": "NE"
  }, {
    "name": "Nevada",
    "abbreviation": "NV"
  }, {
    "name": "New Hampshire",
    "abbreviation": "NH"
  }, {
    "name": "New Jersey",
    "abbreviation": "NJ"
  }, {
    "name": "New Mexico",
    "abbreviation": "NM"
  }, {
    "name": "New York",
    "abbreviation": "NY"
  }, {
    "name": "North Carolina",
    "abbreviation": "NC"
  }, {
    "name": "North Dakota",
    "abbreviation": "ND"
  }, {
    "name": "Northern Mariana Islands",
    "abbreviation": "MP"
  }, {
    "name": "Ohio",
    "abbreviation": "OH"
  }, {
    "name": "Oklahoma",
    "abbreviation": "OK"
  }, {
    "name": "Oregon",
    "abbreviation": "OR"
  }, {
    "name": "Palau",
    "abbreviation": "PW"
  }, {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  }, {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  }, {
    "name": "Rhode Island",
    "abbreviation": "RI"
  }, {
    "name": "South Carolina",
    "abbreviation": "SC"
  }, {
    "name": "South Dakota",
    "abbreviation": "SD"
  }, {
    "name": "Tennessee",
    "abbreviation": "TN"
  }, {
    "name": "Texas",
    "abbreviation": "TX"
  }, {
    "name": "Utah",
    "abbreviation": "UT"
  }, {
    "name": "Vermont",
    "abbreviation": "VT"
  }, {
    "name": "Virgin Islands",
    "abbreviation": "VI"
  }, {
    "name": "Virginia",
    "abbreviation": "VA"
  }, {
    "name": "Washington",
    "abbreviation": "WA"
  }, {
    "name": "West Virginia",
    "abbreviation": "WV"
  }, {
    "name": "Wisconsin",
    "abbreviation": "WI"
  }, {
    "name": "Wyoming",
    "abbreviation": "WY"
  },
  {
    "name": "Alberta",
    "abbreviation": "AB"
  },
  {
    "name": "British Columbia",
    "abbreviation": "BC"
  },
  {
    "name": "Manitoba",
    "abbreviation": "MB"
  },
  {
    "name": "New Brunswick",
    "abbreviation": "NB"
  },
  {
    "name": "Newfoundland and Labrador",
    "abbreviation": "NL"
  },
  {
    "name": "Northwest Territories",
    "abbreviation": "NT"
  },
  {
    "name": "Nova Scotia",
    "abbreviation": "NS"
  },
  {
    "name": "Nunavut",
    "abbreviation": "NU"
  },
  {
    "name": "Ontario",
    "abbreviation": "ON"
  },
  {
    "name": "Prince Edward Island",
    "abbreviation": "PE"
  },
  {
    "name": "Quebec",
    "abbreviation": "QC"
  },
  {
    "name": "Saskatchewan",
    "abbreviation": "SK"
  },
  {
    "name": "Yukon Territory",
    "abbreviation": "YT"
  }
  ];
  countrys = [{
    "name": "United States",
    "abbreviation": "US"
  }, {
    "name": "Canada",
    "abbreviation": "CA"
  }];
  attachmentsError: boolean = false;
  defendantDocument: boolean = false;
  validationError: boolean = false;
  validationErrors = {
    "defendantName": {
      "required": "defendantName is required",
      "maxlength": "defendantName cannot be more than 500 characters long",
    },
    "streetAddress1": {
      "required": "streetAddress1 is required",
      "maxlength": "streetAddress1 cannot be more than 500 characters long",
    },
    "streetAddress2": {
      "required": "streetAddress2 is required",
      "maxlength": "streetAddress2 cannot be more than 500 characters long",
    },
    "country": {
      "required": "country is required",
      "maxlength": "country cannot be more than 2 characters long",
    },
    "state": {
      "required": "state is required",
      "maxlength": "state cannot be more than 500 characters long",
    },
    "zipCode": {
      "required": "zipCode is required",
      "maxlength": "zipCode cannot be more than 500 characters long",
    },
    "city": {
      "required": "city is required",
      "maxlength": "city cannot be more than 500 characters long",
    },
    "defDoc1": {
      "required": "Document is required",
      "maxlength": "Documents cannot be more then 4 - 5 per Defendant",
    },
    "emptyFields": "Some of the required fields are empty.",
  };
  attachmentsAny: any;
  files: Array<BatchFile> = new Array<BatchFile>();
  docRequiredValidation: boolean = false;
  submitted: boolean = false;
  PageTitle: string = "";
  OrderNumberValid: boolean = true;

  /** DefendantDetails constructor */
  constructor(private popupConfig: PopupConfig,
    private popup: PopupRef,
    private ds: DataService,
    private defendantDataService: DefendantDataModelService,
    private formBuilder: FormBuilder) {
    //console.log(this.popupConfig.pageName);
    this.ds.setPageName(this.popupConfig.pageName);
  }

  ngOnInit() {

    // if edit or add build the form with that.
    if (this.popupConfig.data.message != null) {
      this.editDefendantDetails = this.popupConfig.data.message.def;
      this.files = this.editDefendantDetails.defendantFiles;
      this.PageTitle = " Edit Defendant details";
      this.edit = true;
      this.defendantFormGroup = this.formBuilder.group({
        // Defendant Details
        defendantName: [this.editDefendantDetails.defendantName, [Validators.required, Validators.maxLength(500)]],
        suffix: [this.editDefendantDetails.suffix],
        streetAddress1: [this.editDefendantDetails.streetAddress1, [Validators.required, Validators.maxLength(250)]],
        streetAddress2: [this.editDefendantDetails.streetAddress2, [Validators.maxLength(250)]],
        country: [this.editDefendantDetails.country, [Validators.required, Validators.maxLength(2)]],
        state: [this.editDefendantDetails.state, [Validators.required, Validators.maxLength(50)]],
        zipCode: [this.editDefendantDetails.zipCode, [Validators.required, Validators.maxLength(50)]],
        city: [this.editDefendantDetails.city, [Validators.required, Validators.maxLength(50)]],
        defDoc1: [],
        orderNumber: [this.editDefendantDetails.orderNumber]
      }, { updateOn: 'blur' });
    } else {
      this.edit = false;
      this.PageTitle = "Add Defendant details";
      this.defendantFormGroup = this.formBuilder.group({
        // Defendant Details
        defendantName: ["", [Validators.required, Validators.maxLength(500)]],
        suffix: [""],
        streetAddress1: ["", [Validators.required, Validators.maxLength(250)]],
        streetAddress2: ["", [Validators.maxLength(250)]],
        country: ["", [Validators.required, Validators.maxLength(2)]],
        state: ["", [Validators.required, Validators.maxLength(50)]],
        zipCode: ["", [Validators.required, Validators.maxLength(50)]],
        city: ["", [Validators.required, Validators.maxLength(50)]],
        defDoc1: ["-"],
        orderNumber: [""],
      }, { updateOn: 'blur' });
    }

  }

  ngOnDestroy() { }

  get f() { return this.defendantFormGroup.controls }

  buildDefData() {

    try {
      var def: DefendantDetails = new DefendantDetails();

      def.city = this.f.city.value;
      def.country = this.f.country.value;
      def.defendantName = this.f.defendantName.value;
      def.state = this.f.state.value;
      def.streetAddress1 = this.f.streetAddress1.value;
      def.streetAddress2 = this.f.streetAddress2.value;
      def.zipCode = this.f.zipCode.value;
      def.orderNumber = this.f.orderNumber.value;
      def.suffix = this.f.suffix.value;

      // file names
      def.defendantFiles = this.files;

      _.map(def.defendantFiles, 'file').forEach(function (value, index) {
        if (index == 0) {
          def.fileNames = value;
        } else {
          def.fileNames = def.fileNames + ',' + value;
        }
      });

      def.batchFiles = JSON.stringify(this.files);

      let _this = this;
      if (this.popupConfig.data.message != null) {
        // remove old data from the batch.
        this.ds.batch.DefendantDetails = _.filter(this.ds.batch.DefendantDetails, function (value, key) {
          //console.log('key_for in ds',key,'sent_key_index', _this.popupConfig.data.message.index);
          return (key !== _this.popupConfig.data.message.index);
        });
        this.ds.batch.DefendantDetails.push(def);
      } else {
        // add new data
        this.ds.batch.DefendantDetails.push(def);
      }

    } catch (e) {
      console.log(e);
    }

  }

  changeColor(event: Event) {
    event.srcElement.className = event.srcElement.className.replace("error", "");
    // console.log();
  }

  focusOutFunction() {

    if (this.ds.LawFirmId && this.f.orderNumber.value) {

      type OrderNumberValidation = {
        LawFirm_ID: number;
        OrderNumber: number;
      }

      const payload = {} as OrderNumberValidation;

      payload.LawFirm_ID = this.ds.LawFirmId;
      payload.OrderNumber = this.f.orderNumber.value;

      var _this = this;

      setTimeout(function () {
        // call the api to validate
        _this
          .ds
          .OrderNumberValidation(payload)
          .subscribe(data => {
            _this.OrderNumberValid = data.data;
          });
      }, 1000);

    }

  }

  // add new def-details.
  clearAndReset(close: string = null) {
    try {

      //console.log(" defendant form group.... ",this.defendantFormGroup);

      this.submitted = true;

      // -------------- validate required-------------------.
      var defKeys = {
        defendantName: false,
        streetAddress1: false,
        country: false,
        state: false,
        zipCode: false,
        city: false,
        defDoc1: false,
      };

      if (this.popupConfig.data.message != null) {
        delete defKeys.defDoc1;
      }

      this.validationError = false
      Object.keys(defKeys).forEach(key => {
        //console.log(this.defendantFormGroup.controls[key]);
        if (this.defendantFormGroup.controls[key].value.length < 1) {
          //console.log(this.defendantFormGroup.controls[key],key);
          this.validationError = true;
        }
      });

      // invalid form
      if (this.validationError) {
        this.defendantDocument = true;
        return;
      }

      //-------------- reset the validation for defendant document---------------------
      this.defendantDocument = false;

      // if common document is selected.
      if (this.ds.commonDoc) {
        this.docRequiredValidation = false;
      } else {
        if (this.files.length == 0) {
          this.docRequiredValidation = true;
          return;
        }
      }

      /// --- validation completed ---//
      // if clicked on save and close.
      if (close != null) {
        //this.popupService.
        this.popup.close();
      }

      // copy data to the defendant object.
      this.buildDefData();

      // append fileAndPageCount to the form data.      
      this.ds.BatchForm.append('fileAndPageCount', JSON.stringify(this.files));

      if (!this.edit) {

        // clear the from and errors.
        // clear file name
        //(<HTMLInputElement>document.querySelector('#defDoc1')).value = "";
        // clear form builders
        this.files = new Array<BatchFile>();
        this.f.defendantName.setValue('');
        this.f.streetAddress1.setValue('');
        this.f.streetAddress2.setValue('');
        this.f.country.setValue('');
        this.f.state.setValue('');
        this.f.zipCode.setValue('');
        this.f.city.setValue('');
        this.f.orderNumber.setValue('');
        this.f.suffix.setValue('');

        // clear validation error
        this.defendantFormGroup.controls['defendantName'].setErrors({ required: true });

        this.defendantFormGroup.controls['streetAddress1'].setErrors({ required: true });
        this.defendantFormGroup.controls['streetAddress2'].setErrors({ required: true });
        this.defendantFormGroup.controls['country'].setErrors({ required: true });
        this.defendantFormGroup.controls['state'].setErrors({ required: true });
        this.defendantFormGroup.controls['zipCode'].setErrors({ required: true });
        this.defendantFormGroup.controls['city'].setErrors({ required: true });
        this.defendantFormGroup.controls['orderNumber'].setErrors({ required: true });

        this.defendantFormGroup.markAsUntouched();

        this.submitted = false;
      }

    } catch (e) {
      console.log(e);
    }
  }

  removeFile(file: any, event) {

    this.files = _.filter(this.files, function (value, index) {
      return !(value.file == file.file);
    });

    if (this.files.length == 1) {
      setTimeout(function () {
        document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0;max-height: 0em');
      }, 50);
    } else {
      document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0');
    }

  }

  getFileName(file) {

    // get 
    var fileData = file.name.split('.');
    var fileExtention = fileData[fileData.length - 1];

    // remove file extention form the array.
    fileData.pop();

    // join array with space and replace the string with only letter remove special char.
    var fileName = fileData.join(' ').replace(/[^a-zA-Z0-9]/g, "") + "." + fileExtention;

    return fileName;
  }

  onFileChangeDefDocs(files) {
    try {

      // validate files
      if (files.target.files.length === 0) {
        this.docRequiredValidation = true;
        return;
      }

      // remove old files on every clicks.
      //if (!isUndefined(this.attachmentsAny)) {

      //  //var currentDefendantDetailsIndex = this.ds.batch.DefendantDetails.length + 1;
      //  //for (let file of this.attachmentsAny) {
      //  //  var jsongString = JSON.stringify(
      //  //    { fileName: file.name, defendantIndex: currentDefendantDetailsIndex, }
      //  //  );
      //  //}

      //  var _this = this;
      //  this.files.forEach(function (data) {            
      //    _this.ds.BatchForm.delete(data.file);
      //  });

      //}

      this.docRequiredValidation = false;

      // if common document is selected.
      // max document validation
      // common-doc + def=doc > 5
      if (this.ds.commonDoc) {

        // check for maxLength 
        if ((this.ds.commonDoc + files.target.files.length) > 5) {
          this.attachmentsError = true;
          //console.log(this.attachmentsError);
          return;
        } else {
          this.attachmentsError = false;
        }

      } else {
        // check required.
        //check for max length in case of no common document
        if (files.target.files.length > 5) {
          this.attachmentsError = true;
          //console.log(this.attachmentsError);
          return;
        } else {
          this.attachmentsError = false;
        }
      }

      var currentDefendantDetailsIndex = this.ds.batch.DefendantDetails.length + 1;
      for (let file of files.target.files) {

        var cleanFileName = this.getFileName(file);

        // build the filename and page count.
        this.files.push({ "file": cleanFileName, "pagecount": 0 });

        var i = this.ds.commonDoc;


        var jsongString = JSON.stringify({ fileName: file.name, defendantIndex: currentDefendantDetailsIndex, });

        // append to the form data

        this.ds.BatchForm.append(cleanFileName, file);

        if (this.attachedFileNames.length < 1) {
          this.attachedFileNames = cleanFileName;
        } else {
          this.attachedFileNames = this.attachedFileNames + ',' + cleanFileName;
        }

        if (files.target.files.length == 1) {

          setTimeout(function () {
            document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0;max-height: 0em');
          }, 50);

        } else {

          setTimeout(function () {
            document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0');
          }, 50);

        }

      }

      this.attachmentsAny = files.target.files;
    } catch (e) {
      console.log(e);
    }
  }

  triggerFileUpload(elementId: string) {
    document.getElementById(elementId).click();
  }

}
