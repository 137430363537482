import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebApiHandler } from './webApiHandler';
import { concat } from 'rxjs/operators';
import { DefendantSub, Batch, BatchFile } from '../Models/Batch/BatchModel';
import { Userdetails } from '../Models/UserManagment/userdetails';
import { FormDefendantModel } from '../Models/WorkArea/DefendantModel';
import { NavigationModel, nestedNav } from '../Models/Navigation/NavigationModel';
import { isUndefined } from 'util';
import { Roles } from '../Models/Admin/NHAdmin/role';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  Environment: string = null;
  requestHeaders: HttpHeaders = new HttpHeaders();
  _apiHandler: WebApiHandler;
  route: Router;
  userRoles: Array<string>;
  userRoleName: Array<string>;
  _this = this;
  commonDocument: Array<BatchFile> = new Array<BatchFile>();
  defendantDocument: Array<BatchFile> = new Array<BatchFile>();
  commonDocumentList: string = "";
  LawFirmId: number = null;
  IsAdminPage: boolean = false;
  FormDefendantModels: Array<FormDefendantModel>;
  //serviceTime Update for workarea
  serviceTime: string;
  notificationToolTip: string;
  notificationCount: number;

  // search componet data.
  searchedWord: string = "";
  signalRStarted: string;
  signalRUpdatedIn: string;
  // batch form var.
  commonDoc: number = 0;
  batch: Batch = new Batch();
  BatchForm: FormData = new FormData();
  pageName: string;
  NavigationList: Array<NavigationModel>; 
  constructor(private http: HttpClient, private router: Router) {
    this.route = router;
    this._apiHandler = new WebApiHandler(this.http, router);
  }


  createNavigationMenu(userRoles: Array<string>) {
    // console.log(userRoles);
    if (userRoles != null && !isUndefined(userRoles)) {
      let navigationModel = new Array<NavigationModel>();
      userRoles.forEach(function (v) {
        // console.log(v);
        switch (v) {
          case Roles.NHAdmin:
            for (var i = 0; i < 6; i++) {
              switch (i) {
                case 0:
                  let nv = new NavigationModel();
                  nv.redirectURL = "/";
                  nv.title = "Work Area";
                  nv.attrib = "work-area";
                  //nv.nestedActive = true;
                  if (navigationModel.findIndex(i => i.title == nv.title) < 0)
                    navigationModel.push(nv);
                  break;
                 
                case 1:
                  let fromExcel = new NavigationModel();
                  fromExcel.redirectURL = "/create-jobs";
                  fromExcel.title = "Create Job";
                  fromExcel.attrib = "Create-Jobs";
                  fromExcel.nestedNav = new Array<nestedNav>();
                  fromExcel.nestedNav.push({
                    redirectURL: "/create-jobs",
                    title: "import from excel",
                    attrib: "import from excel",
                    //nestedNav:null
                  });
                  fromExcel.nestedNav.push({
                    redirectURL: "from-form",
                    title: "manual",
                    attrib: "manual",
                    // nestedNav: null
                  });
                  if (navigationModel.findIndex(i => i.title == fromExcel.title) < 0)
                    navigationModel.push(fromExcel);
                  break;
                
                case 2:
                  let actvitity = new NavigationModel();
                  actvitity.redirectURL = "/incoming-log";
                  actvitity.title = "Activity";
                  actvitity.attrib = "Activity";
                  actvitity.nestedNav = new Array<nestedNav>();
                  actvitity.nestedNav.push({
                    redirectURL: "/incoming-log",
                    title: "Incoming Log",
                    attrib: "incoming-log",
                    //nestedNav:null
                  });
                  actvitity.nestedNav.push({
                    redirectURL: "/outgoing-log",
                    title: "Outgoing Log",
                    attrib: "outgoing-log",
                    //nestedNav:null
                  });

                  if (navigationModel.findIndex(i => i.title == actvitity.title) < 0)
                    navigationModel.push(actvitity);
                  break;
                case 3:
                  let admin = new NavigationModel();
                  admin.redirectURL = "/nhjobsdashboard";
                  admin.title = "Admin";
                  admin.attrib = "Admin";
                  admin.nestedNav = new Array<nestedNav>();
                  admin.nestedNav.push({
                    redirectURL: "/nhjobsdashboard",
                    title: "Jobs Dashboard",
                    attrib: "Jobs Dashboard",
                    // nestedNav: null
                  });
                  admin.nestedNav.push({
                    redirectURL: "/nhreports",
                    title: "Reports",
                    attrib: "Reports",
                  });
                  //let nested = new Array<nestedNav>();
                  // admin.nestedNav.push({})
                  admin.nestedNav.push({
                    redirectURL: "/nhregisteruser",
                    title: "Add User",
                    attrib: "Add User",
                    //   nestedNav:null

                  });
                  admin.nestedNav.push({
                    redirectURL: "/nhresetuser",
                    title: "Reset User Password",
                    attrib: "Reset User Password",
                    //  nestedNav: null
                  });

                  if (navigationModel.findIndex(i => i.title == admin.title) < 0)
                    navigationModel.push(admin);
                  break;
                default:

                  break;
              }
            }
            break;
          case Roles.KSAdmin:
            for (var i = 0; i < 6; i++) {
              switch (i) {
                case 0:
                  let nv = new NavigationModel();
                  nv.redirectURL = "/";
                  nv.title = "Work Area";
                  nv.attrib = "work-area";
                  //nv.nestedActive = true;
                  if (navigationModel.findIndex(i => i.title == nv.title) < 0)
                    navigationModel.push(nv);
                  break;

                case 1:
                  let fromExcel = new NavigationModel();
                  fromExcel.redirectURL = "/create-jobs";
                  fromExcel.title = "Create Job";
                  fromExcel.attrib = "Create-Jobs";
                  fromExcel.nestedNav = new Array<nestedNav>();
                  fromExcel.nestedNav.push({
                    redirectURL: "/create-jobs",
                    title: "import from excel",
                    attrib: "import from excel",
                    //nestedNav:null
                  });
                  fromExcel.nestedNav.push({
                    redirectURL: "from-form",
                    title: "manual",
                    attrib: "manual",
                    // nestedNav: null
                  });
                  if (navigationModel.findIndex(i => i.title == fromExcel.title) < 0)
                    navigationModel.push(fromExcel);
                  break;

                case 2:
                  let actvitity = new NavigationModel();
                  actvitity.redirectURL = "/incoming-log";
                  actvitity.title = "Activity";
                  actvitity.attrib = "Activity";
                  actvitity.nestedNav = new Array<nestedNav>();
                  actvitity.nestedNav.push({
                    redirectURL: "/incoming-log",
                    title: "Incoming Log",
                    attrib: "incoming-log",
                    //nestedNav:null
                  });
                  actvitity.nestedNav.push({
                    redirectURL: "/outgoing-log",
                    title: "Outgoing Log",
                    attrib: "outgoing-log",
                    //nestedNav:null
                  });

                  if (navigationModel.findIndex(i => i.title == actvitity.title) < 0)
                    navigationModel.push(actvitity);
                  break;
                case 3:
                  let admin = new NavigationModel();
                  admin.redirectURL = "/nhjobsdashboard";
                  admin.title = "Admin";
                  admin.attrib = "Admin";
                  admin.nestedNav = new Array<nestedNav>();
                  admin.nestedNav.push({
                    redirectURL: "/nhjobsdashboard",
                    title: "Jobs Dashboard",
                    attrib: "Jobs Dashboard",
                    // nestedNav: null
                  });
                  admin.nestedNav.push({
                    redirectURL: "/nhreports",
                    title: "Reports",
                    attrib: "Reports",
                  });
                  //let nested = new Array<nestedNav>();
                  // admin.nestedNav.push({})
                  admin.nestedNav.push({
                    redirectURL: "/nhregisteruser",
                    title: "Add User",
                    attrib: "Add User",
                    //   nestedNav:null

                  });
                  admin.nestedNav.push({
                    redirectURL: "/nhresetuser",
                    title: "Reset User Password",
                    attrib: "Reset User Password",
                    //  nestedNav: null
                  });

                  if (navigationModel.findIndex(i => i.title == admin.title) < 0)
                    navigationModel.push(admin);
                  break;
                case 4:
                  let ksamin = new NavigationModel();
                  ksamin.redirectURL = "/admin";
                  ksamin.title = "KS Admin";
                  ksamin.attrib = "KS Admin";
                  ksamin.nestedNav = new Array<nestedNav>();
                  ksamin.nestedNav.push({
                    redirectURL: "/nhclientlist",
                    title: "Client Summary",
                    attrib: "Client Summary",
                    // nestedNav: null
                  });
                  ksamin.nestedNav.push({
                    redirectURL: "/nhpendingservices",
                    title: "Pending Services",
                    attrib: "Pending Services",
                    //  nestedNav: null
                  });
                  ksamin.nestedNav.push({
                    redirectURL: "/nhusers",
                    title: "User Management",
                    attrib: "User Management",
                    // nestedNav: null
                  });
                  ksamin.nestedNav.push({
                    redirectURL: "/services-status",
                    title: "Service Status",
                    attrib: "Service Status",
                    // nestedNav: null
                  });
                  if (navigationModel.findIndex(i => i.title == ksamin.title) < 0)
                    navigationModel.push(ksamin);
                  break;
                default:

                  break;
              }
            }
            break;
          case Roles.ClientAdmin:
            for (var i = 0; i < 6; i++) {
              switch (i) {
                case 0:
                  let nv = new NavigationModel();
                  nv.redirectURL = "/ridersummary";// "/ridersummary";
                  nv.title = "Rider Summary";
                  nv.attrib = "Rider Summary";
                  if (navigationModel.findIndex(i => i.title == nv.title) < 0)
                    navigationModel.push(nv);
                  break;
                  
                case 1:
                  let nvs = new NavigationModel();
                  nvs.redirectURL = "/nhjobsdashboard";
                  nvs.title = "Jobs Dashboard";
                  nvs.attrib = "Jobs Dashboard";
                  nvs.nestedNav = new Array<nestedNav>();
                  nvs.nestedNav.push({
                    redirectURL: "/nhreports",
                    title: "Reports",
                    attrib: "Reports",
                  });
                  if (navigationModel.findIndex(i => i.title == nvs.title) < 0)
                    navigationModel.push(nvs);
                  break;
              }
            }
            break;
          case Roles.NHStaff:
            for (var i = 0; i < 6; i++) {
              switch (i) {
                case 0:
                  let nv = new NavigationModel();
                  nv.redirectURL = "/";
                  nv.title = "Work Area";
                  nv.attrib = "work-area";
                  //nv.nestedActive = true;
                  if (navigationModel.findIndex(i => i.title == nv.title) < 0)
                    navigationModel.push(nv);
                  break;

                case 1:
                  let fromExcel = new NavigationModel();
                  fromExcel.redirectURL = "/create-jobs";
                  fromExcel.title = "Create Job";
                  fromExcel.attrib = "Create-Jobs";
                  fromExcel.nestedNav = new Array<nestedNav>();
                  fromExcel.nestedNav.push({
                    redirectURL: "/create-jobs",
                    title: "import from excel",
                    attrib: "import from excel",
                    //nestedNav:null
                  });
                  fromExcel.nestedNav.push({
                    redirectURL: "from-form",
                    title: "manual",
                    attrib: "manual",
                    // nestedNav: null
                  });
                  if (navigationModel.findIndex(i => i.title == fromExcel.title) < 0)
                    navigationModel.push(fromExcel);
                  break;

                case 2:
                  let actvitity = new NavigationModel();
                  actvitity.redirectURL = "/incoming-log";
                  actvitity.title = "Activity";
                  actvitity.attrib = "Activity";
                  actvitity.nestedNav = new Array<nestedNav>();
                  actvitity.nestedNav.push({
                    redirectURL: "/incoming-log",
                    title: "Incoming Log",
                    attrib: "incoming-log",
                    //nestedNav:null
                  });
                  actvitity.nestedNav.push({
                    redirectURL: "/outgoing-log",
                    title: "Outgoing Log",
                    attrib: "outgoing-log",
                    //nestedNav:null
                  });
                  if (navigationModel.findIndex(i => i.title == actvitity.title) < 0)
                    navigationModel.push(actvitity);
                  break;
                default:

                  break;
              }
            }
            break;
          case Roles.ClientStaff:
            for (var i = 0; i < 6; i++) {
              switch (i) {
                case 0:
                  let nvs = new NavigationModel();
                  nvs.redirectURL = "/nhjobsdashboard";
                  nvs.title = "Jobs Dashboard";
                  nvs.attrib = "Jobs Dashboard";
                  nvs.nestedNav = new Array<nestedNav>();
                  nvs.nestedNav.push({
                    redirectURL: "/nhreports",
                    title: "Reports",
                    attrib: "Reports",
                  });
                  if (navigationModel.findIndex(i => i.title == nvs.title) < 0)
                    navigationModel.push(nvs);
                  break;
              }
            }
            break;
        }
      });
      this.NavigationList = navigationModel;
    }

  }

  setPageName(page: string) {
    this.pageName = page;
  }

  getPageName() {
    return this.pageName;
  }
  wrapText(input: string): string {
    input = input.length > 100 ? input.slice(0, 100) : input;
    return input;
  }
  getauthRequestHeader() {
    //if (!this.requestHeaders.has("Authorization") && this.authenticationService.AuthenticateObject != null) {
    //  if (!this.authenticationService.IsTokenExpired) {
    //    return this.requestHeaders.append("Authorization", "Bearer " + this.authenticationService.AuthenticateObject.token);
    //  }
    //  else {
    //    this.route.navigate(['/authentication'])
    //  }
    //}
    return this.requestHeaders;
  }

  PingPST() {
    return this._apiHandler.GetJsonResponse("api/PSTService/pingPSTServer", this.getauthRequestHeader());
  }

  SubmitTransaction() {
    return this._apiHandler.GetJsonResponse("api/PSTService/SubmitTransaction", this.getauthRequestHeader());
  }

  GetUpdatedJobs() {
    return this._apiHandler.GetJsonResponse("api/PSTService/GetUpdatedJobs", this.getauthRequestHeader());
  }

  GetDocuments() {
    return this._apiHandler.GetJsonResponse("api/PSTService/GetDocuments", this.getauthRequestHeader());
  }

  SubmitTransactionWithXML(xmlString) {
    var self = this;
    return this._apiHandler.PostJsonResponse("api/PSTService/SubmitTransactionwithXML", { xml_string: xmlString }, this.getauthRequestHeader());
  }

  validateXmlWithSave(xmlString) {
    return this._apiHandler.PostJsonResponse('api/PSTService/ValidateXmlWithSave', { xml_string: xmlString }, this.getauthRequestHeader());
  }

  //WorkArea
  getPlaintiffs(defIDS) {
    return this._apiHandler.PostJsonResponse('api/WorkArea/GetPlaintiffs/' + defIDS, defIDS, this.getauthRequestHeader());
  }

  getWorkAreaDashBoard() {
    return this._apiHandler.GetJsonResponse('api/WorkArea/GetWorkAreaDashBoard', this.getauthRequestHeader())
  }

  incominglog() {
    return this._apiHandler.GetJsonResponse("api/getIncomingLog", this.getauthRequestHeader());
  }

  outgoinglog() {
    return this._apiHandler.GetJsonResponse("api/Outgoinglog/getOutgoingLog", this.getauthRequestHeader());
  }

  sentIncomingLogActionEmail(id) {
    return this._apiHandler.GetJsonResponse("api/getIncomingLog/SendEmail/" + id, this.getauthRequestHeader());
  }

  getDefendants(workArea) {
    return this._apiHandler.GetJsonResponse("api/WorkArea/GetDefendants/" + workArea, this.getauthRequestHeader());
  }

  getPlaintiffsDocuments(pdefs) {
    return this._apiHandler.PostJsonResponse("api/WorkArea/GetPlaintiffsDocuments/" + pdefs, pdefs, this.getauthRequestHeader());
  }

  sendToPST(DefendantIDCSV) {
    // console.log(DefendantIDCSV);
    return this._apiHandler.PostJsonResponse("api/WorkArea/SendToPst/" + DefendantIDCSV, DefendantIDCSV, this.getauthRequestHeader());
  }

  sendAllToPST(lawFirmID) {
    // console.log(DefendantIDCSV);
    return this._apiHandler.PostJsonResponse("api/WorkArea/SendAllToPST/" + lawFirmID, lawFirmID, this.getauthRequestHeader());
  }
  sendToPSTPlaintiffDefID(Plaintiff) {
    // console.log(DefendantIDCSV);
    return this._apiHandler.PostJsonResponse("api/WorkArea/SendToPSTPlaintiffDefID/" + Plaintiff, Plaintiff, this.getauthRequestHeader());
  }

  getProcessedWorkArea() {
    return this._apiHandler.GetJsonResponse('api/WorkArea/GetProcessedWorkArea', this.getauthRequestHeader())
  }

  getDocuments(document) {
    // console.log(document);
    return this._apiHandler.GetJsonResponse('api/WorkArea/DownloadDocument/' + document, this.getauthRequestHeader())
  }

  getProcessedPlaintiffs(defIDS) {
    return this._apiHandler.PostJsonResponse('api/WorkArea/GetProcessedPlaintiffs/' + defIDS, defIDS, this.getauthRequestHeader());
  }

  postFiles(data) {
    return this._apiHandler.PostJsonResponse('api/IncomingLog/uploadFile', data, this.getauthRequestHeader());
  }

  postExcelFile(data) {
    return this._apiHandler.PostJsonResponse('api/IncomingLog/UploadExcel', data, this.getauthRequestHeader());
  }

  reSendJob(queueID) {
    return this._apiHandler.PostJsonResponse('api/Outgoinglog/ResendJob/' + queueID, queueID, this.getauthRequestHeader());
  }

  getLawFirms() {
    return this._apiHandler.GetJsonResponse('api/BatchUpload/GetLawFirmList', this.getauthRequestHeader());
  }

  openPdfImage(data) {
    return this._apiHandler.PostJsonResponse('api/WorkArea/GetPdfImagePath', data, this.getauthRequestHeader());
  }

  postBatch(data) {
    return this._apiHandler.PostJsonResponse('api/BatchUpload/PostBatchForm', data, this.getauthRequestHeader());
  }

  getWindowsServicesStatus() {
    return this._apiHandler.GetJsonResponse('api/WindowsServicesStatus', this.getauthRequestHeader());
  }

  OrderNumberValidation(data) {
    return this._apiHandler.PostJsonResponse('api/BatchUpload/OrderNumberValidation', data, this.getauthRequestHeader());
  }

  //getOrderNumbers(info) {
  //  return this._apiHandler.PostJsonResponse('api/PSTService/GetOrderNumbers' , info, this.getauthRequestHeader());
  //}

  getEnvironment() {
    return this._apiHandler.GetJsonResponse('api/getAppSettingsEnvironment', this.getauthRequestHeader());
  }
  getSearchResults(search) {
    return this._apiHandler.PostJsonResponse('api/Admin/GetSearchData/' + search, search, this.getauthRequestHeader());
  }

  addNewUser(payload) {
    return this._apiHandler
      .PostJsonResponse('api/NHAdmin/AddNewUser', payload, this.getauthRequestHeader());
  }

  resetNHUserPassword(payload) {
    return this._apiHandler
      .PostJsonResponse('api/NHAdmin/ResetNHUserPassword', payload, this.getauthRequestHeader());
  }
  getNHClientList() {
    return this._apiHandler.GetJsonResponse('api/NHAdmin/GetClientList', this.getauthRequestHeader());
  }
  getNHClientSearchResult(search) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetFilteredClientList/' + search, search, this.getauthRequestHeader());
  }
  getallusers() {
    return this._apiHandler.GetJsonResponse('api/NHAdmin/GetAllUsersWithRolesList', this.getauthRequestHeader());
  }
  MapFileModel(data) {
    //console.log(data);
    // debugger;
    return this._apiHandler.PostJsonResponse('api/IncomingLog/MapFileModel', data, this.getauthRequestHeader());
  }
  //SaveMapFileModel(data) {
  //  return this._apiHandler.PostJsonResponse('api/IncomingLog/SaveMapFileModel', data, this.getauthRequestHeader());
  //}
  getNHPendingServices(search) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetPendingServices/' + search, search, this.getauthRequestHeader());
  }
  getVersionInfo() {
    return this._apiHandler.GetJsonResponse('api/GetVersionInfo', this.getauthRequestHeader());
  }

  updatedUserRoles(roles: any) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/updatedUserRoles', roles, this.getauthRequestHeader());
  }
  getUserClientSearchResult(search) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetUserClientList/' + search, search, this.getauthRequestHeader());
  }

  updateUserLawFirms(lawFirm: any) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/UpdateUserLawFirms', lawFirm, this.getauthRequestHeader());
  }


  getJobsDashboardResults(jobDashboard: any) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetJobsBySearch', jobDashboard, this.getauthRequestHeader());
  }
  getGetBatchPriorityList() {
    return this._apiHandler.GetJsonResponse('api/NHAdmin/GetBatchPriorityList', this.getauthRequestHeader());
  }
  getJobSeriveDetails(jobId: string) {
    return this._apiHandler.GetJsonResponse("api/NHAdmin/GetJobSeriveDetails/" + jobId, this.getauthRequestHeader());
  }
  getJobInvoiceDetails(wOservice_ID: string) {
    return this._apiHandler.GetJsonResponse("api/NHAdmin/GetJobInvoiceDetails/" + wOservice_ID, this.getauthRequestHeader());
  }
  openPDFFile(path: any) {

    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetPdfPath', path, this.getauthRequestHeader());
  }

  getReportResults(search: any) {
    return this._apiHandler.PostJsonResponse('api/NHAdmin/GetReportResults', search, this.getauthRequestHeader());
  }

  getRiderSummaryResults(jobDashboard: any) {
    return this._apiHandler.PostJsonResponse('api/Clients/GetRiderSummaryBySearch', jobDashboard, this.getauthRequestHeader());
  }
}

