import { PlaintiffModel } from './PlainTiffModel';
import { DefendantModel } from './DefendantModel';

export class WorkAreaView {
  id: number;
  lawFirmName: string;
  lawFirmWithCount: string;
  status: string;
  defendantCount: number;
  batchId: number;
  batchNotes: string;
  //companyName: string;
  //receivedOn: Date;
  //expDelivery: Date;
  //assignedTo: string;
  //priorityValue: string;
  showDefendants: boolean;
  displayDefendant: string;
  //transFormIcon: string;
  //receivedDate: string;
  //expDate: string;
  //priority: Priority;
  priorityString: string;
  plaintiffsCount: number;
  lawFirm_ID: number;
  priorities: PriorityTitle[];
  defStatInProgress: Array<DefendantModel> = new Array<DefendantModel>();
  defStatSucceded: Array<DefendantModel> = new Array<DefendantModel>();
  statAnyFailed: boolean;
  buttonText: string;
  buttonDisabled: boolean;
  statusStrings: Array<string>;
  logStatus: string;
  filteredCount: string;
  workAreaSummaryStarted: string;
  workAreaDetailsStarted: string;
  workAreaSummaryEnded: string;
  workAreaDetailsEnded: string;
}
export class PriorityTitle {
  title: string;
  path: string;
}
export enum Priority {
  None,
  Standard,
  High,
  Rush,
  Low,
  Medium,
  Hold
}

