import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

export class WebApiHandler {
  route: Router;

  constructor(private http: HttpClient, private router: Router) {
    this.route = router;
  }

  private handleError(error: HttpErrorResponse, _this) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.status == 401) {
        _this.route.navigate(['authentication']);

      }
      //// The backend returned an unsuccessful response code.
      //// The response body may contain clues as to what went wrong,
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  public isAuthenticate(response: Observable<any>, _this): boolean {
    if (response != undefined) {
      if (response["isAuthenticated"] != undefined) {
        if (response["isAuthenticated"] == false) {
          sessionStorage.removeItem('currentUser');
          _this.route.navigate(["/authentication"]);
        }
      }
    }
    return true;
  }

  public GetJsonResponse(urlPath: string, httpHeaders: HttpHeaders): Observable<any> {
    var self = this;
    let jsonresponse;
    return this.http.get<any>(urlPath, { headers: httpHeaders });
      //.pipe(
      //  //catchError((err: HttpErrorResponse) => { return self.handleError(err, self); })
      //  tap( // Log the result or error
      //    //data => self.isAuthenticate(data, self),
      //    (event: any) => {
      //      if (event instanceof HttpResponse) {
      //        console.log('event--->>>', event);
      //      }
      //      //return event;
      //    },
      //    error => { (err: HttpErrorResponse) => self.handleError(err, self) }
      //  ));
  }

  public PostJsonResponse(urlPath: string, paramModel: any, httpHeaders: HttpHeaders): Observable<any> {
    var self = this;
    return this.http.post(urlPath, paramModel, { headers: httpHeaders });
      //.pipe(
      //  tap( // Log the result or error
      //    //data => self.isAuthenticate(data, self),
      //    (event: any) => {
      //      if (event instanceof HttpResponse) {
      //        console.log('event--->>>', event);
      //      }
      //      //return event;
      //    },
      //    error => { (err: HttpErrorResponse) => self.handleError(err, self) }
      //  )
      //);
  }

}
