export class ReportsModel {
  serialNo: number;
  jobID: string;
  batchPriority: string;
  def_name: string;
  receivedDate: string;
  processed_date: string;
  expectedDeliveryDate: string;
  daysRemaining: number;
  served_date: string
  plaintiff_ID: number;
  plaintiffDef_ID: number;
  def_id: number;
  lawFirm_ID: number;
  lawFirm_Name: string;
  name: string;
  status: string;
  attorney: string;
  caseId: string;
  plaintiffIdentifier: string;
  plaintiffIdentifierBilling: string;
  s_CommentSeqNum: string;
  leadDefendantName: string;
  courtName: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  city: string;
  state: string;
  zip: string;
  defFullAddress: string;
}
