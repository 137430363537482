import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { NavigationModel, nestedNav } from 'src/app/Core/Models/Navigation/NavigationModel';
import { environment } from '../../../environments/environment';
import { DataService } from '../../Core/Services/data.service';
import { Userdetails } from 'src/app/Core/Models/UserManagment/userdetails';
import { isUndefined } from 'util';
import { Role, Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';

@Component({
  selector: 'nh-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit, OnChanges {

  authenticateService: AuthenticateService;
  public isAuth: Boolean;
  private isWorkarea: boolean;
  private isIncomingLog: boolean;
  private NavigationList: Array<NavigationModel>; 
  private AdminNavList: Array<NavigationModel>; 
  userDetails: Userdetails;
  userRoles: Array<string>;
 
  constructor(private authentication: AuthenticateService, private router: Router
    , private ds: DataService) {
    this.authenticateService = authentication;
    this.userDetails = this.authenticateService.getLoggedInUser();
    this.NavigationList = this.ds.NavigationList;
    //if (!isUndefined(this.userDetails) && this.userDetails != null) {
    //  this.ds.userRoles = new Array<string>();
    //  this.userRoles= this.userDetails.roles.split(',');
    //}
    //console.log("loaded");
    //if (this.NavigationList == undefined) {

    //  this.NavigationList = new Array<NavigationModel>();
    //  //TODO We need to get the menu items from Database
    //  for (var i = 0; i < 6; i++) {
    //    switch (i) {
    //      case 0:
    //        let nvs = new NavigationModel();
    //        nvs.redirectURL = "/incoming-log";
    //        nvs.title = "Incoming Log";
    //        nvs.attrib = "incoming-log";
    //        this.NavigationList.push(nvs);
    //        break;
    //      case 1:
    //        let nv = new NavigationModel();
    //        nv.redirectURL = "/";
    //        nv.title = "Work Area";
    //        nv.attrib = "work-area";
    //        //nv.nestedActive = true;

    //        this.NavigationList.push(nv);
    //        break;
    //      case 2:
    //        let out = new NavigationModel();
    //        out.redirectURL = "/outgoing-log";
    //        out.title = "Outgoing Log";
    //        out.attrib = "outgoing-log";
    //        //out.nestedNav.push({
    //        //  redirectURL: "/view-all-completed",
    //        //  title: "View All Completed",
    //        //  attrib: "view-all-completed"
    //        //});
    //        this.NavigationList.push(out);
    //        break;
    //      case 3:
    //        //let upload = new NavigationModel();
    //        //upload.redirectURL = "/create-jobs";
    //        //upload.title = "Create Jobs";
    //        //upload.attrib = "Create-Jobs";
    //        //this.NavigationList.push(upload);
    //        break;
    //      case 4:
    //        let fromExcel = new NavigationModel();
    //        fromExcel.redirectURL = "/create-jobs";
    //        fromExcel.title = "Create Job";
    //        fromExcel.attrib = "Create-Jobs";
    //        fromExcel.nestedNav = new Array<nestedNav>();
    //        fromExcel.nestedNav.push({
    //          redirectURL: "/create-jobs",
    //          title: "import from excel",
    //          attrib: "import from excel",
    //          //nestedNav:null
    //        });
    //        fromExcel.nestedNav.push({
    //          redirectURL: "from-form",
    //          title: "manual",
    //          attrib: "manual",
    //         // nestedNav: null
    //        });
    //        this.NavigationList.push(fromExcel);
    //        break;
    //      case 5:
    //        break;
    //      default:
           
    //        break;
    //    }
    //  }

    //  if (this.userDetails != null && this.userDetails.roles != null && this.userDetails.roles.indexOf(Roles.NHAdmin) >= 0) {
    //    let admin = new NavigationModel();
    //    admin.redirectURL = "/nhregisteruser";
    //    admin.title = "Admin";
    //    admin.attrib = "Admin";
    //    admin.nestedNav = new Array<nestedNav>();
    //    admin.nestedNav.push({
    //      redirectURL: "/nhclientlist",
    //      title: "Client Summary",
    //      attrib: "Client Summary",
    //     // nestedNav: null
    //    });
    //    admin.nestedNav.push({
    //      redirectURL: "/nhpendingservices",
    //      title: "Pending Services",
    //      attrib: "Pending Services",
    //    //  nestedNav: null
    //    });
    //    //let nested = new Array<nestedNav>();
    //   // admin.nestedNav.push({})
    //    admin.nestedNav.push({
    //      redirectURL: "/nhregisteruser",
    //      title: "Add User",
    //      attrib: "Add User",
    //   //   nestedNav:null

    //      });
    //    admin.nestedNav.push({
    //      redirectURL: "/nhresetuser",
    //      title: "Reset User",
    //      attrib: "Reset User",
    //    //  nestedNav: null
    //    });
       
        
    //    this.NavigationList.push(admin);
    //  }

    //  //console.log(this.NavigationList);
    //}

    /*if (this.userDetails != null && !isUndefined(this.userDetails) && this.userDetails.roles != null && !isUndefined(this.userDetails.roles) && this.userDetails.roles.indexOf(',') >= 0) {
      if (this.userDetails.roles.split(',').findIndex(i => i == Roles.NHAdmin) >= 0) {
        this.AdminNavList = new Array<NavigationModel>();
        for (var i = 0; i < 4; i++) {
          switch (i) {
            case 0:
              this.AdminNavList.push({ title: "Add User", redirectURL: "/nhregisteruser", attrib: "nhadduser", nestedActive: false, nestedNav:null })
              break;
            case 1:
              this.AdminNavList.push({ title: "Reset User", redirectURL: "/nhresetuser", attrib: "nhresetuser", nestedActive: false, nestedNav: null })
              break;
            case 2:
              this.AdminNavList.push({ title: "Clients", redirectURL: "/nhclientlist", attrib: "nhclientlist", nestedActive: false, nestedNav: null })
              break;
            case 3:
              this.AdminNavList.push({ title: "Pending Services", redirectURL: "/nhpendingservices", attrib: "nhpendingservices", nestedActive: false, nestedNav: null })
              break;
            default:
              break
          }
        }
      }
    }
    else {
      if (this.userDetails != null && !isUndefined(this.userDetails) && this.userDetails.roles != null && !isUndefined(this.userDetails.roles) && this.userDetails.roles == Roles.NHAdmin) {
        this.AdminNavList = new Array<NavigationModel>();
        for (var i = 0; i < 4; i++) {
          switch (i) {
            case 0:
              this.AdminNavList.push({ title: "Add User", redirectURL: "/nhregisteruser", attrib: "nhadduser", nestedActive: false, nestedNav: null })
              break;
            case 1:
              this.AdminNavList.push({ title: "Reset User", redirectURL: "/nhresetuser", attrib: "nhresetuser", nestedActive: false, nestedNav: null })
              break;
            case 2:
              this.AdminNavList.push({ title: "Clients", redirectURL: "/nhclientlist", attrib: "nhclientlist", nestedActive: false, nestedNav: null })
              break;
            case 3:
              this.AdminNavList.push({ title: "Pending Services", redirectURL: "/nhpendingservices", attrib: "nhpendingservices", nestedActive: false, nestedNav: null })
              break;

            default:
              break
          }
        }
      }
    }*/
  }

  navClicked(redirectUrl: string) {

    //this.NavigationList.forEach(function (value, index) {
    //  if (redirectUrl == value.redirectURL) {
    //    value.nestedActive = true;
    //  } else {
    //    value.nestedActive = false;
    //  }
    //});

  }

  ngOnChanges(changes) {
   // console.log('navbarchanges',changes);
  }

  ngOnInit() {
    
    //console.log(this.router.url);
    //console.log(window.location.href);
  }
  
  logout() {
   // console.log('logging out');
    sessionStorage.removeItem('currentUser');
   // this.NavigationList.find(i => i.title == "Work Area") ? this.NavigationList.find(i => i.title == "Work Area").isActive = true :
    //localStorage.removeItem('currentUser');
    //this.authenticateService.currentUserSubject.next(null);
    //this.ds.userRoles = new Array<string>();
    this.authenticateService.logout();
    //this.router.navigate(['/authentication'])
  }

  navigateToHome() {
    if (this.ds.userRoleName.findIndex(i => i == Roles.KSAdmin) >= 0) {
      //this.ds.IsAdminPage = true;
      this.router.navigate(['/admin']);
    } else if (this.ds.userRoleName.findIndex(i => i == Roles.ClientAdmin) >= 0 || this.ds.userRoleName.findIndex(i => i == Roles.ClientStaff) >= 0) {
      this.router.navigate(['/nhclientlist']);
    } else {
      this.router.navigate(['']);
    }
  }
  //openLoginPopup() {
  //  this.popupComponent.create('authentication');
  //}
}

