import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupConfig } from '../../Shared/Popup/popup-config';
import { PopupRef } from '../../Shared/Popup/popup-ref';
import { PlaintiffModel, PlaintiffMessages, Documents, PlaintiffDefIDs } from 'src/app/Core/Models/WorkArea/PlainTiffModel';
import { DefendantModel } from 'src/app/Core/Models/WorkArea/DefendantModel';
import { DataService } from '../../Core/Services/data.service';
import { Priority } from 'src/app/Core/Models/WorkArea/WorkAreaModel';
import * as _ from 'lodash';
import { isUndefined } from 'util';
import { _document } from '@angular/platform-browser/src/browser';
//import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'nh-workareadetails',
  templateUrl: './workareadetails.component.html',
  styleUrls: ['./workareadetails.component.less']
})

export class WorkareadetailsComponent implements OnInit {

  plaintiff: PlaintiffModel = new PlaintiffModel();
  defendantModel: DefendantModel = new DefendantModel();
  dataService: DataService;
  plaintiffModels: Array<PlaintiffModel> = new Array<PlaintiffModel>();
  plaintiffMessages: PlaintiffMessages = new PlaintiffMessages();
  defList: Array<DefendantModel>;
  changeText = false;
  ascDescOrder = false;
  pageDetails: boolean;
  showJobid: boolean;
  pdfSrc: any;
  disableNextButton: boolean;
  showPdf = false;
  temp: Array<PlaintiffModel>;

  // sorting
  sortStatus: any = [
    { 'label': 'id', 'value': false },
    { 'label': 'priority', 'value': false },
    { 'label': 'plaintiffName', 'value': false },
    { 'label': 'receivedOn', 'value': false },
    { 'label': 'expDelivery', 'value': false },
  ];

  constructor(private popupConfig: PopupConfig, private popup: PopupRef, private ds: DataService) {//, private popupService: PopupService
    this.dataService = ds;
  }
  ngOnInit() {
    try {
      this.defendantModel = this.popupConfig.data.message;
      this.loadData(this.defendantModel);
      this.ds.setPageName(this.popupConfig.pageName);
    } catch (e) {
      //console.log(e);
      document.getElementById('divMain').style.display = 'none';
      document.getElementById('divError').style.display = 'block';
      //if (this.defendantModel == undefined) {
      //}
    }
  }

  sendToPST(plaintiff: PlaintiffModel) {
    try {
      this.dataService.sendToPSTPlaintiffDefID(plaintiff).subscribe(data => {
        if (data !== undefined) {
          const response = (data as string);
          if (response.includes('Success')) {
            plaintiff.buttonDisabled = true;
            plaintiff.buttonText = 'Queued';
            plaintiff.buttonBackground = 'gray';
            this.popupConfig.data.message.isUpdated = true;
          }
        }
        // alert(data);
        // this.popupService.open()

        //Based  on response show popup or alert 
        //alert("sent" + data);
      });
    } catch (e) {

    }

    // alert("Enabled");
  }

  viewMessages(plaintiff: PlaintiffModel) {
    try {

      //  if (this.plaintiffModels.length > 1) {
      if (plaintiff.isActive) {
        plaintiff.isActive = false;
      } else {
        //this.plaintiffModels.find(i => i.isActive && (plaintiff.plaintiffId != i.plaintiffId)).isActive = false;
        for (var i = 0; i < this.plaintiffModels.length; i++) {
          if (this.plaintiffModels[i].isActive) {
            // if (this.plaintiffModels[i].plaintiffId != plaintiff.plaintiffId)
            this.plaintiffModels[i].isActive = false;
          }
        }
        plaintiff.isActive = true;
      }
      this.defendantModel.batchNotes = plaintiff.batchNotes;
      this.defendantModel.state = plaintiff.state;
      this.defendantModel.county = plaintiff.countyName;
      this.defendantModel.city = plaintiff.city;
      this.defendantModel.court = plaintiff.courtName;
      this.defendantModel.zip = plaintiff.zip;

      let pdefs = new PlaintiffDefIDs();
      pdefs.plaintiffID = plaintiff.plaintiffId;
      pdefs.defIDS = this.defendantModel.defIDS;
      this.ds.getPlaintiffsDocuments(pdefs).subscribe(data => {
        // console.log(data);
        this.plaintiffMessages = data;
        //if (this.plaintiffMessages != undefined && this.plaintiffMessages.documentsPath != undefined) {
        //  this.plaintiffMessages.documentNames = new Array();
        //  for (var i = 1; i <= this.plaintiffMessages.documentsPath.length; i++) {
        //    this.plaintiffMessages.documentNames.push("Document" + i);
        //  }
        //}
      });
      // }
    } catch (e) {
      console.log(e);
    }
  }


  getDefaultMessages(plaintiff: PlaintiffModel) {
    try {
      plaintiff.isActive = true;
      for (var i = 0; i < this.plaintiffModels.length; i++) {
        if (i != 0) {
          if (this.plaintiffModels[i].isActive) {
            // if (this.plaintiffModels[i].plaintiffId != plaintiff.plaintiffId)
            this.plaintiffModels[i].isActive = false;
          }
        }
      }
      let pdefs = new PlaintiffDefIDs();
      pdefs.plaintiffID = plaintiff.plaintiffId;
      pdefs.defIDS = this.defendantModel.defIDS;
      this.ds.getPlaintiffsDocuments(pdefs).subscribe(data => {
        this.plaintiffMessages = data;

        // this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.plaintiffMessages.documents[0].documentPath);
        // console.log(this.pdfSrc);
      });
    } catch (e) {

    }

  }
  changeSortIcon(ColumnName: string): number {
    var value: number = null;
    this.sortStatus.forEach(element => {
      if (element.label == ColumnName) {
        if (element.value != 3) {
          element.value++;
        } else {
          element.value = 0;
        }
        value = element.value;
      } else {
        element.value = 0;
      }
    });
    return value;
  }

  // sorting Column
  sort(ColumnName: string) {
    var sortValue = this.changeSortIcon(ColumnName);
    if (sortValue != 0 && sortValue != 3) {
      if (this.ascDescOrder) {
        this.plaintiffModels = _.orderBy(this.plaintiffModels, [ColumnName], ['asc']);
        this.ascDescOrder = false;
      } else {
        this.plaintiffModels = _.orderBy(this.plaintiffModels, [ColumnName], ['desc']);
        this.ascDescOrder = true;
      }
    } else {
      this.plaintiffModels = this.temp;
    }
  }
  //downloadDocument(doc: Documents) {
  //  if (!isUndefined(doc)) {

  //    this.ds.download(doc).subscribe(blob => {
  //      saveAs(blob, '.pdf', {
  //        type:'application/pdf'
  //      })
  //    })
  //  }
  //}
  //@ViewChild('pdfDetailsPopup') viewPdf: PopupComponent;
  viewPdfDetails(model: DefendantModel) {
    //this.popupService.open(PdfcontainerComponent, { data: _document, pageName: "pdfviewer" });
  }
  downloadPdf(document: Documents): void {
    window.open(document.documentPath);
  }

  openPDF(documents: Documents): void {
    try {
      let _document: Documents = documents;
      this.showPdf = true;
      this.ds.openPdfImage(_document).subscribe(data => {
        document.getElementById('pdfPopup').style.display = 'flex';
        document.getElementById('objectData').setAttribute('type', 'application/pdf');
        document.getElementById('objectData').setAttribute('data', 'data:application/pdf;base64,' + data.filePath);
        // document.getElementById("popupAlertMessage").innerText ="Document removed from Development Environment";
      });
      ////this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(_document.documentPath);
      //document.getElementById("pdfPopup").style.display = "flex";
      //document.getElementById("objectData").setAttribute("type", "application/pdf");
      //document.getElementById("objectData").setAttribute("data","data:application/pdf;base64,"+ _document.documentPath);

    }
    catch (err) {

    }
  }
  nextDefendant() {
    let indexNumber = this.defList.findIndex(i => i.defIDS == this.defendantModel.defIDS);
    if (!isUndefined(this.defList[indexNumber + 1]))
      this.loadData(this.defList[indexNumber + 1]);
    else
      this.disableNextButton = true;
    //console.log();

  }

  loadData(defModel: DefendantModel) {
    try {
      this.defendantModel = defModel;


      if (this.popupConfig.pageName == 'workareadetails') {
        this.pageDetails = true;
        this.defList = this.popupConfig.list;

        // alert(this.defendantModel.defIDS);
        this.dataService.getPlaintiffs(this.defendantModel).subscribe(data => {

          this.plaintiffModels = data;
          if (this.plaintiffModels != undefined) {
            var _this = this;

            this.showJobid = (_.filter(_this.plaintiffModels, function (data) {
              return data.jobID > 0;
            }).length > 0) ? true : false;



            for (var i = 0; i < this.plaintiffModels.length; i++) {

              if (i == 0) {
                // this.defendantModel.state = this.plaintiffModels[i].state;
                this.defendantModel.zip = this.plaintiffModels[i].zip;
                this.defendantModel.county = this.plaintiffModels[i].countyName;
                this.defendantModel.court = this.plaintiffModels[i].courtName;
                this.defendantModel.batchNotes = this.plaintiffModels[i].batchNotes;
              }
              // this.plaintiffModels[i].receivedDate = this.datePipe.transform(this.plaintiffModels[i].receivedOn, 'MM/dd/yyyy HH:mm');
              this.defendantModel.batchNotesDate = this.plaintiffModels[i].receivedDate;
              // this.showJobid = this.plaintiffModels[i].jobID > 0 ? true : false;
              //console.log(this.plaintiffModels[i].jobID);
              if (this.plaintiffModels[i].buttonText.toLowerCase() == 'failed') {
                this.plaintiffModels[i].buttonBackground = '#aa111180';
                this.plaintiffModels[i].buttonDisabled = true;
              } else if (this.plaintiffModels[i].buttonText.toLowerCase() == 'processed' || this.plaintiffModels[i].buttonText.toLowerCase() == 'process') {
                this.plaintiffModels[i].buttonBackground = '#009673';
              } else {
                this.plaintiffModels[i].buttonBackground = 'gray';
              }
              // this.plaintiffModels[i].expDate = this.datePipe.transform(this.plaintiffModels[i].expDelivery, 'MM/dd/yyyy');

              switch (this.plaintiffModels[i].priority) {
                case Priority.High:
                  this.plaintiffModels[i].priorityName = 'High';
                  break;
                case Priority.Low:
                  this.plaintiffModels[i].priorityName = 'Low';
                  break;
                case Priority.Medium:
                  this.plaintiffModels[i].priorityName = 'Medium';
                  break;
                case Priority.Hold:
                  this.plaintiffModels[i].priorityName = 'Hold';
                  break;
                case Priority.Rush:
                  this.plaintiffModels[i].priorityName = 'Rush';
                  break;
                case Priority.Standard:
                  this.plaintiffModels[i].priorityName = 'Standard';
                  break;
                default:
              }
            }
            this.getDefaultMessages(this.plaintiffModels[0]);
          }
          if ((this.plaintiffModels == undefined) || (this.plaintiffModels != undefined && this.plaintiffModels.length == 0)) {
            document.getElementById('divTable').style.display = 'none';
            document.getElementById('divError').style.display = 'block';
          }
          this.temp = this.plaintiffModels;
        }, error => {
          document.getElementById('divTable').style.display = 'none';
          document.getElementById('divError').style.display = 'block';
        });
      } else {
        //console.log(this.defendantModel);
        this.pageDetails = false;
        this.dataService.getProcessedPlaintiffs(this.defendantModel).subscribe(data => {

          this.plaintiffModels = data;
          //  console.log(data);
          if (this.plaintiffModels != undefined) {
            for (var i = 0; i < this.plaintiffModels.length; i++) {
              this.showJobid = this.plaintiffModels[i].jobID > 0 ? true : false;
              this.defendantModel.batchNotesDate = this.plaintiffModels[i].receivedDate;
              if (i == 0) {
                // this.defendantModel.state = this.plaintiffModels[i].state;
                this.defendantModel.zip = this.plaintiffModels[i].zip;
                this.defendantModel.county = this.plaintiffModels[i].countyName;
                this.defendantModel.court = this.plaintiffModels[i].courtName;
                this.defendantModel.batchNotes = this.plaintiffModels[i].batchNotes;
              }
              // this.plaintiffModels[i].receivedDate = this.datePipe.transform(this.plaintiffModels[i].receivedOn, 'MM/dd/yyyy HH:mm');
              this.defendantModel.batchNotesDate = this.plaintiffModels[i].receivedDate;
              // this.plaintiffModels[i].expDate = this.datePipe.transform(this.plaintiffModels[i].expDelivery, 'MM/dd/yyyy');
              if (this.plaintiffModels[i].buttonText.toLowerCase() == 'failed') {
                this.plaintiffModels[i].buttonBackground = '#aa111180';
              } else if (this.plaintiffModels[i].buttonText.toLowerCase() == 'processed' || this.plaintiffModels[i].buttonText.toLowerCase() == 'process') {
                this.plaintiffModels[i].buttonBackground = '#009673';
              } else {
                this.plaintiffModels[i].buttonBackground = 'gray';
              }
              switch (this.plaintiffModels[i].priority) {
                case Priority.High:
                  this.plaintiffModels[i].priorityName = 'High';
                  break;
                case Priority.Low:
                  this.plaintiffModels[i].priorityName = 'Low';
                  break;
                case Priority.Medium:
                  this.plaintiffModels[i].priorityName = 'Medium';
                  break;
                case Priority.Hold:
                  this.plaintiffModels[i].priorityName = 'Hold';
                  break;
                case Priority.Rush:
                  this.plaintiffModels[i].priorityName = 'Rush';
                  break;
                case Priority.Standard:
                  this.plaintiffModels[i].priorityName = 'Standard';
                  break;
                default:
              }
            }
            this.getDefaultMessages(this.plaintiffModels[0]);
          }
          if ((this.plaintiffModels == undefined) || (this.plaintiffModels != undefined && this.plaintiffModels.length == 0)) {
            document.getElementById('divMain').style.display = 'none';
            document.getElementById('divError').style.display = 'block';
          }
          this.temp = this.plaintiffModels;
        }, error => {
          document.getElementById('divMain').style.display = 'none';
          document.getElementById('divError').style.display = 'block';
        });

      }
    } catch (e) {
      //console.log(e);
      document.getElementById('divMain').style.display = 'none';
      document.getElementById('divError').style.display = 'block';
      //if (this.defendantModel == undefined) {
      //}
    }
  }
}
