import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { AdminSelectionList, SearchModel, AdminModel } from 'src/app/Core/Models/Admin/admin-model';
import { DataService } from 'src/app/Core/Services/data.service';
import { isNumber, isUndefined } from 'util';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { Userdetails } from 'src/app/Core/Models/UserManagment/userdetails';
import { ExcelService } from 'src/app/Core/Services/ExcelService/excel.service';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
@Component({
  selector: 'nh-adminpage',
  templateUrl: './adminpage.component.html',
  styleUrls: ['./adminpage.component.less']
})
export class AdminpageComponent implements OnInit, OnDestroy {
  private gridOptions: GridOptions;
  public rowData: any[];
  private columnDefs: any[];
  selectedValue: number=0;
  firstName: string;
  lastName: string;
  listCount: number;
  errorMessage: string = "";
  overlayNoRowsTemplate:string;
  user: Userdetails;
  hideColumns: boolean;
  showTextBox: boolean = true;
  gridData: Array<AdminModel>;
  
  private excelIO;
  private gridApi;
  private gridColumnApi;
  listArray: Array<AdminSelectionList> = new Array<AdminSelectionList>();;
  constructor(private dataService: DataService, private auth: AuthenticateService, private excelService: ExcelService) {
    this.listArray.push({ name: "Case Number", id: 1 });
    this.listArray.push({ name: "JobID", id: 2 });
    this.listArray.push({ name: "Plaintiff Name", id: 3 });
    this.listArray.push({ name: "Ordernumber", id: 4 });
    this.listArray.push({ name: "7 days ", id: 5 });
    this.listArray.push({ name: "30 days", id: 6 });
    this.listArray.push({ name: "Date Range", id: 7 });
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
    this.selectedValue = 2;
    this.user = this.auth.getLoggedInUser();
    let _this = this;
    if (this.user.roles.indexOf(',') > 0) {
      this.user.roles.split(',').forEach(function (value) {
        if (value == "NHAdmin")
          _this.hideColumns = true;
      })
    }
    else if (this.user.roles == "NHAdmin") {
      this.hideColumns = true;
    }
    
   // this.dataService.IsAdminPage = true;
  }
  localeText = {
    // for filter panel
    notEqual: 'Does not equal',
    // for number filter
    notContains: 'Does not contain',
  }
  ngOnInit() {
    let _this = this;
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }
    };
    if (this.auth.getLoggedInUser().roles.indexOf(Roles.KSAdmin) >= 0) {
      this.columnDefs = [
        {
          headerName: 'Serial', field: 'serialNumber', width: 60, sortingOrder: ["asc", "desc"]
        },
        { headerName: 'Law Firm', field: 'lawFirm_Name', tooltipField: 'lawFirm_Name', width: 120, filter: true, sortingOrder: ["asc", "desc"] },
        { headerName: 'Plaintiff Name', field: 'fullName', tooltipField: 'fullName', width: 130, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Defendant', field: 'def_Name', tooltipField: 'def_Name', filter: true, width: 140, sortingOrder: ["asc", "desc"]},
        { headerName: 'Job ID', field: 'jobID', width: 60, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
        { headerName: 'Recd from LF', field: 'receivedDate', width: 125, filter: true, sortingOrder: ["asc", "desc"]},
        {
          headerName: 'XML from LF', field: 'inputXMLfile', cellRenderer: this.XMLCellRenderer, width: 100, sortingOrder: ["asc", "desc"]
        },
        { headerName: 'Sent to PST', field: 'recUpdateDate', width: 120, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'XML Sent to PST', field: 'outgoingxmlpath', cellRenderer: this.XMLCellRenderer, width: 100, sortingOrder: ["asc", "desc"]},
        { headerName: 'Ordernumber', field: 'orderNumber', width: 118, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Case Number', field: 'caseNumber', width: 118, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Status', field: 'processStatus', width: 80, filter: true, sortingOrder: ["asc", "desc"]}
          
      ];
    } else {
      this.columnDefs = [
        {
          headerName: 'Serial', field: 'serialNumber', width: 80, sortingOrder: ["asc", "desc"]
        },
        { headerName: 'Law Firm', field: 'lawFirm_Name', tooltipField: 'lawFirm_Name', width: 150, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Plaintiff Name', field: 'fullName', tooltipField: 'fullName', width: 150, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Defendant', field: 'def_Name', tooltipField: 'def_Name', filter: true, width: 180, sortingOrder: ["asc", "desc"]},
        { headerName: 'Job ID', field: 'jobID', width: 80, sortingOrder: ["asc", "desc"]},
        { headerName: 'Recd from LF', field: 'receivedDate', width: 145, filter: true, sortingOrder: ["asc", "desc"] },
        { headerName: 'Sent to PST', field: 'recUpdateDate', width: 145, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Ordernumber', field: 'orderNumber', width: 122, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Case Number', field: 'caseNumber', width: 122, filter: true, sortingOrder: ["asc", "desc"]},
        { headerName: 'Status', field: 'processStatus', width: 100, filter: true, sortingOrder: ["asc", "desc"] }
      ];
    }
    this.rowData = [];
  }
  onGridReady(params) {
   // console.log('loaded');
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }
  XMLCellRenderer(params) {
   // console.log(params);
    if (!isUndefined(params.value) && params.value != null && params.value != "") {
      var flag = "<a target='_blank' style='color:blue;text-decoration:underline;font-weight:400' href=" + params.value + " >" + "XML" + "</a>";
      return flag;
    } else {
      return "<span>N/A</span>"
    }
  }
  ngOnDestroy() {
   // this.dataService.IsAdminPage = false;
  }
  cellClicked() {
    //alert("ccc'");
  }
  exportAsXLSX(): void {
   var header: any;
    if (this.auth.getLoggedInUser().roles.indexOf(Roles.KSAdmin) >= 0) {
      header = ["Serial",
        "Law Firm",
        "Plaintiff Name",
        "Defendant",
        "Job ID",
        "Recd from LF",
        "Sent to PST",
        "Ordernumber",
        "Case Number",
        "Status",
        "InputXMLfile",
      "Outgoingxmlpath"];
    }
    else {
     header = ["Serial",
        "Law Firm",
        "Plaintiff Name",
        "Defendant",
        "Job ID",
        "Recd from LF",
        "Sent to PST",
        "Ordernumber",
        "Case Number",
        "Status"];
    }

    this.printAllDisplayedRows();
    this.excelService.exportAsExcelFile(this.gridData, 'admingrid',header);
  }
  printAllDisplayedRows() {
    var count = this.gridApi.getDisplayedRowCount();
    this.gridData = new Array<AdminModel>();
    //console.log("## printAllDisplayedRows");
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      this.gridData.push(rowNode.data);
    }
  }
  selectionChanged() {
    if (this.selectedValue == 5 || this.selectedValue == 6) {
      this.Search() 
      this.showTextBox = false;
    }
    else {
      this.showTextBox = true;
    }
    this.lastName = ''; this.firstName = '';
    this.errorMessage = "";
  }
  values = '';
  Search() { // without type info
    if (this.selectedValue == 7) {
     // this.values = this.firstName + "," + this.lastName;
      // to get the last record of the selected date. Records are searched with time in SQL
      this.values = this.firstName + " 00:00:00.000" + "," + this.lastName + " 23:59:59.999";
      if (this.selectedValue == 7) {
        if (new Date(this.lastName) < new Date(this.firstName)) {
          this.errorMessage = "To date must be greaterthan or equal to from date ";
          return;
        }
      }
    }
    else
      this.values = this.firstName;

    if (this.selectedValue == 5 || this.selectedValue == 6) {
      this.values = "";
      let model = new SearchModel();
      model.searchString = this.values;
      model.searchType = this.selectedValue;
      this.dataService.getSearchResults(model).subscribe(data => {
        if (this.auth.getLoggedInUser().roles.indexOf(Roles.KSAdmin) >= 0) {
          this.gridData = JSON.parse(JSON.stringify(data));
          this.gridData.forEach(function (value, index) {
            value.recCreateDate = "";
            value.firstName = "";
            value.lastName = "";
            value.middleName = "";
          })
        } else {
          this.gridData = JSON.parse(JSON.stringify(data));
          this.gridData.forEach(function (value, index) {
            value.inputXMLfile = "";
            value.outgoingxmlpath = "";
            value.recCreateDate = "";
            value.firstName = "";
            value.lastName = "";
            value.middleName = "";
          })

        }
        // console.log(data);
        this.rowData = data;
        this.listCount = this.rowData.length;
      });
    }
    else if (!this.isEmptyOrSpaces(this.values) && this.selectedValue > 0) {
      this.errorMessage = "";
      let model = new SearchModel();
      model.searchString = this.values;
      model.searchType = this.selectedValue;
      this.dataService.getSearchResults(model).subscribe(data => {
        if (this.auth.getLoggedInUser().roles.indexOf(Roles.KSAdmin) >= 0) {
          this.gridData = JSON.parse(JSON.stringify(data));
          this.gridData.forEach(function (value, index) {
            value.recCreateDate = "";
            value.firstName = "";
            value.lastName = "";
            value.middleName = "";
          })
        } else {
          this.gridData = JSON.parse(JSON.stringify(data));
          this.gridData.forEach(function (value, index) {
            value.inputXMLfile = "";
            value.outgoingxmlpath = "";
            value.recCreateDate = "";
            value.firstName = "";
            value.lastName = "";
            value.middleName = "";
          })
        }
        this.rowData = data;
        this.listCount = this.rowData.length;
      });
    } else {
      this.errorMessage="Please provide valid input."
    }
    if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
      this.sortByconditions("receivedDate", "desc")
    } else {
      this.sortByconditions("fullName","asc")
    }
  }
  isEmptyOrSpaces(str) {
  return str === null || str.match(/^ *$/) !== null;
  }
  searchClick(event) {
    if (event.key == "Enter") {
      this.Search();
    }
  }


  sortByconditions(colname: string, sortvalue: string) {
   // console.log("sorted")
    var sort = [
      {
        colId: colname,
        sort: sortvalue
      }
    ];
    this.gridApi.setSortModel(sort);
  }

}
