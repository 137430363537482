import { Component } from "@angular/core";
import { DataService } from '../../Core/Services/data.service';

@Component({
  selector: 'nh-service',
  templateUrl: './services.component.html'
})

export class ServicesComponent {

  public response: any = null;
  versionInfo: any;

  constructor(private ds: DataService) { }

  ngOnInit() {

    this.ds.getWindowsServicesStatus().subscribe(data => {
      this.response = data.data;
      this.ds.signalRStarted = this.response.signalRStart;
      this.ds.signalRUpdatedIn = this.response.signalRNextUpdate;

    });
    this.ds.getVersionInfo().subscribe(data => {
      this.versionInfo = data.split('\n');
    })
   
  }

}
