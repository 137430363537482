import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { Component } from '@angular/core';
import { RiderSummaryComponent } from './rider-summary/rider-summary.component';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { RoleGuardService } from 'src/app/Core/Services/AuthGurd/role-guard.service';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';

const clientsroutes: Routes = [{
  path: 'ridersummary', component: RiderSummaryComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin + "," + Roles.ClientAdmin + "," + Roles.ClientStaff }
}];


export const clientsrouting: ModuleWithProviders = RouterModule.forChild(clientsroutes)

//export class Clientsrouter {
//}
