import { Component, OnInit, KeyValueDiffers, KeyValueDiffer, OnDestroy, ViewChild } from '@angular/core';
import { OutgoingLog } from 'src/app/Core/Models/OutgoingLog/outgoing-log';
import { DataService } from "src/app/Core/Services/data.service";
import _ from 'lodash';
import { OutgoingLogSignalRService } from 'src/app/Core/Services/SignalR/OutgoingLog/outgoing-log-signalR.service';
import { DefendantModel } from 'src/app/Core/Models/WorkArea/DefendantModel';
import { WorkareadetailsComponent } from '../workareadetails/workareadetails.component';
import { PopupComponent } from 'src/app/Shared/Popup/popup.component';
import { PopupService } from 'src/app/Shared/Popup/popup.service';
import { isUndefined } from 'util';
import { CommonService } from 'src/app/Core/Services/Common/common.service';

@Component({
  selector: 'nh-outgoinglog',
  templateUrl: './outgoinglog.component.html'
})

export class OutgoinglogComponent implements OnInit, OnDestroy {
  
  ds: DataService;
  public outgoinglogdata: Array<OutgoingLog>;
  public failedLogs: Array<any>;
  public searchedData: Array<any> = null;
  public temp: Array<OutgoingLog>;
  public ComponentName: string = "Outgoing-Log";
  public emptySearchedResults: boolean = false;
  public order: boolean = false;
    workAreaCompleted: Array<DefendantModel> = new Array<DefendantModel>();

  filteredFor: string = 'View Failed';

  sortStatus: any = [
    { "label": "jobID", 'value': 1 },
    { "label": "plaintiff_name", 'value': 1 },
    { "label": "def_name", 'value': 1 },
    { "label": "status", 'value': 1 },
    { "label": "errorDetail", 'value': 1 },
    { "label": "added", 'value': 1 },
  ];

  differ: KeyValueDiffer<string, any>;

  constructor(private _ds: DataService,
    private signalR: OutgoingLogSignalRService,private popupService: PopupService,
    private differs: KeyValueDiffers,
    private common: CommonService) {

    try {
      this.differ = this.differs.find({}).create();
      this.ds = _ds;

      // establish connection to signalR-Hub
      if (!signalR.connectionIsEstablished) {
        signalR.connectionEstablished.subscribe(() => {
          this.openOutgoingLog();
        });
        signalR.OutgoingLogStarted.subscribe(() => {
          this.startStreaming();
        });
      }

      // after connection established, we just pull data.
      this.ds.outgoinglog().subscribe(data => {
        var _this = this;
        this.outgoinglogdata = data.outgoingloglist.outgoingLogData;
        this.workAreaCompleted = data.outgoingloglist.recentlyCompleted;
        this.workAreaCompleted.forEach(function (v) {
          v.def_trimmedName = _this.ds.wrapText(v.def_name);
        });

        this.temp = this.outgoinglogdata;

        this.failedLogs = _.filter(this.outgoinglogdata, function (data) {
                              if (data) {
                                return (data.status == "FAILED" || data.status=="QUEUED" );
          }
        });
      });

    } catch (error) {
      console.log(error);
    }

  }

  //ngDoChange() {
  //  const change = this.differ.diff(this);
  //  if (change) {
  //    change.forEachChangedItem(item => {
  //      if (item.key == 'outgoinglogdata') {
  //        if (item.currentValue.length) {
  //          this.emptySearchedResults = false;
  //        } else {
  //          this.emptySearchedResults = true;
  //        }
  //      }
  //    });
  //  }
  //}

  /*
   * methods related to data pulling.
   * */
  ngOnInit(): void {

    // controller API which is might be replaced by signalR.
    //this.ds.outgoinglog()
    //        .subscribe(data => {
    //          this.outgoinglogdata = data.outgoingloglist;
    //          this.temp = data.outgoingloglist;
    //        });


    // add class to the table
    var section = document.querySelector('#rootSectionId');
    section.className = "wrapper background tableComponent";

  }

  ngOnDestroy() {
    // add class to the table
    var section = document.querySelector('#rootSectionId');
    section.className = "wrapper background";
  }

  // open connection to signalR outgoing-log route
  openOutgoingLog() {
    this.signalR.openOutgoingLog();
  }

  startStreaming() {
    this.signalR
      .startStreaming()
      .subscribe({
        next: (data) => {
         this.outgoinglogdata = data;
        //console.log('outgoing log streaming......', data);
        },
        error: (error) => {
          console.log('signalR streaming outgoing-log error ... ', error);
        },
        complete: () => {
          console.log('streaming to outgoing-log is completed');
        }
      });
  }

  /*  UI/UX
      methods related to UI and UX..
  */

  changeSortIcon(ColumnName: string): number {
    var value: number = null;
    this.sortStatus.forEach(element => {
      if (element.label == ColumnName) {
        if (element.value != 3) {
          element.value++;
        } else {
          element.value = 1;
        }
        value = element.value;
      } else {
        element.value = 1;
      }
    });
    return value;
  }
  
  sort(ColumnName: string) {
    try {
      
      var sortValue = this.changeSortIcon(ColumnName);

      if (this.filteredFor == "View Failed") {

        if (sortValue != 1) {
          if (sortValue == 2) {
            this.failedLogs = _.orderBy(this.failedLogs, [ColumnName], ['asc']);
            this.order = false;
          } else {
            this.failedLogs = _.orderBy(this.failedLogs, [ColumnName], ['desc']);
            this.order = true;
          }
        } else {

          if (this.common.getFilterText() == null) {
           
            this.failedLogs = _.filter(this.outgoinglogdata, function (data) {
              if (data) {
                return (data.status == "FAILED" || data.status == "QUEUED");
              }
            });

          } else {
            this.failedLogs = this.searchedData;
          }
      
        }

      } else {

        if (sortValue != 1) {
          if (sortValue == 2) {
            this.failedLogs = _.orderBy(this.outgoinglogdata, [ColumnName], ['asc']);
            this.order = false;
          } else {
            this.failedLogs = _.orderBy(this.outgoinglogdata, [ColumnName], ['desc']);
            this.order = true;
          }
        } else {
          
          this.failedLogs = this.temp;
        }

      }

    } catch (error) {
      console.log(error);
    }
  }

  @ViewChild('viewDetailsPopup') viewDetail: PopupComponent;
  viewDetails(model: DefendantModel) {
    this.popupService.open(WorkareadetailsComponent,
      { data: { message: model }, pageName: "viewCompleted", vH:55, vW: 74 });
  }

  searchValues(data: any) {
    try {

      if (data.filterStatus) {

        if (data.filterFor == "View All") {
          this.filteredFor = data.filterFor;
          this.failedLogs = this.temp;
          this.sortStatus = [
            { "label": "queue_Id", 'value': 1 },
            { "label": "plaintiff_name", 'value': 1 },
            { "label": "def_name", 'value': 1 },
            { "label": "status", 'value': 1 },
            { "label": "errorDetail", 'value': 1 },
            { "label": "added", 'value': 1 },
          ];

          this.emptySearchedResults = false;
          return;
        } else {
          this.filteredFor = data.filterFor;
          this.failedLogs = data.data;
          this.sortStatus = [
            { "label": "queue_Id", 'value': 1 },
            { "label": "plaintiff_name", 'value': 1 },
            { "label": "def_name", 'value': 1 },
            { "label": "status", 'value': 1 },
            { "label": "errorDetail", 'value': 1 },
            { "label": "added", 'value': 1 },
          ];
        return;
        }

      }

      if (data == 'reset') {
        console.log('reset data.');
        this.outgoinglogdata = this.temp;
        this.emptySearchedResults = false;
      } else {
        // incomingLogs is used to show searched result
        //console.log('data', data)

        if (data.length) {
          this.emptySearchedResults = false;
        } else {
          this.emptySearchedResults = true;
        }

        //this.outgoinglogdata = data;
        this.searchedData = data;
        this.failedLogs = data;
      }

    } catch (error) {
      console.log(error);
    }
  }

  resendJob(queueID: OutgoingLog) {
    this.ds.reSendJob(queueID).subscribe(response => {
      if (!isUndefined(response)) {
        let aa = response as number;
        console.log(aa);
        if (aa > 0) {
          queueID.buttonText = "Queued";
          queueID.buttonDisabled = true;
          queueID.status = "QUEUED";
        }
      }
      //console.log(response);

     
    });

  }

}
