import { Component, OnInit,OnDestroy } from '@angular/core';
import { LoaderService } from 'src/app/Core/Services/Loader/loader.service';
import { LoaderState } from 'src/app/Core/Models/Loader/LoaderState';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nh-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})

export class LoaderComponent implements OnInit, OnDestroy {

  constructor(private loaderService: LoaderService) { }
  show = false;

  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
        .subscribe((state: LoaderState) => {
          this.show = state.show;
         // console.log(this.show);
        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
