import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IStreamResult } from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})

export class OutgoingLogSignalRService {

  // events connection, start and close signalR
  connectionEstablished = new EventEmitter<Boolean>();
  OutgoingLogStarted = new EventEmitter<Boolean>();
  OutgoingLogClosed = new EventEmitter<Boolean>();

  public connectionIsEstablished = false;

  // connection to signalR hub.
  private _stockHubConnection: HubConnection;

  constructor() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    this._stockHubConnection = new HubConnectionBuilder()
      .withUrl('/outgoing-log-signalr')
      .build();
  }

  private startConnection(): any {
    this._stockHubConnection
      .start()
      .then((values) => {
        this.connectionIsEstablished = true;
       // console.log('stock connection started');
        this.connectionEstablished.emit(true);
      }).catch(err => {
        console.log('error..-->connecting to outgoing-log signalR', err);
      });
  }

  private registerOnServerEvents(): void {

    this._stockHubConnection.on("OutgoingLogStarted", () => {
        //console.log("Outgoing Log Started");
      this.OutgoingLogStarted.emit(true);
    });

    this._stockHubConnection.on("OutgoingLogClosed", () => {
      //console.log("OutgoingLogClosed");
      this.OutgoingLogClosed.emit(true);
    });
  }

  public startStreaming(): IStreamResult<any> {
    return this._stockHubConnection.stream("StreamOutgoingLog");
  }

  public getAllOutgoingLog(): Promise<any> {
    return this._stockHubConnection.invoke("GetAllOutgoingLog");
  }

  public openOutgoingLog() {
    this._stockHubConnection.invoke("StartOutgoingLog");
  }

  public CloseOutgoingLog() {
    this._stockHubConnection.invoke("CloseOutgoingLog");
  }

  public ResetMarket() {
    this._stockHubConnection.invoke("OutgoingLogReset");
  }
    
}
