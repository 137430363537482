import { Component, OnInit} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'nh-currency',
  template: `{{params.value | currency}}`,
})

export class CurrencyComponent implements ICellRendererAngularComp {

  public params: any;

  agInit(params: any): void {
  //  console.log(params);
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

}
