import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from '@angular/core';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from 'src/app/Core/Services/AuthGurd/auth.guard';
import { ClientlistComponent } from './clientlist/clientlist.component';
import { PendingservicesComponent } from './pendingservices/pendingservices.component';
import { RoleGuardService } from 'src/app/Core/Services/AuthGurd/role-guard.service';
import { ResetuserComponent } from './resetuser/resetuser.component';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
import { UsermanagementComponent } from './usermanagement/usermanagement.component'
import { JobsdashboardComponent } from './jobsdashboard/jobsdashboard.component';
import { ReportsComponent } from './reports/reports.component';

const nhadminroutes: Routes = [{
  path: 'nhregisteruser', component: RegisterComponent, canActivate: [RoleGuardService], data: { role: Roles.NHAdmin+"," + Roles.KSAdmin  } 
},
  { path: 'nhclientlist', component: ClientlistComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin } },// Roles.NHAdmin + "," + Roles.ClientAdmin + "," + Roles.ClientStaff + "," + July 12  2019
  { path: 'nhpendingservices', component: PendingservicesComponent, canActivate: [RoleGuardService], data: { role:Roles.KSAdmin } },//role: Roles.NHAdmin + "," +  July 12 2019
  { path: 'nhresetuser', component: ResetuserComponent, canActivate: [RoleGuardService], data: { role: Roles.NHAdmin + "," + Roles.KSAdmin  } },
  { path: 'nhusers', component: UsermanagementComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin } },
  { path: 'nhjobsdashboard', component: JobsdashboardComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.ClientAdmin + "," + Roles.ClientStaff } },
  { path: 'nhreports', component: ReportsComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.ClientAdmin + "," + Roles.ClientStaff } }];
export const nhadminrouting: ModuleWithProviders = RouterModule.forChild(nhadminroutes)
