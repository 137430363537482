import { Priority } from './WorkAreaModel';

export class PlaintiffModel {
  id: number;
  firstName: string;
  lastName: string;
  status: string;
  lawFirm: string;
  receivedOn: Date;
  expDelivery: Date;
  receivedDate: string;
  expDate: string;
  priority: Priority;
  priorityIcon: string;
  priorityName: string;
  showMessages: false;
  showMessage: string;
  message: string;
  messageReceivedOn: string;
  suffix: string;
  plaintiffId: number;
  documentsPath: string[];
  city: string;
  state: string;
  zip: string;
  courtName: string;
  countyName: string;
  plaintiffName: string;
  isActive: boolean;
  batchNotes: string;
  buttonText: string;
  buttonDisabled: boolean;
  plaintiffDef_ID: number;
  buttonBackground: string;
  jobID: number;
}
export class PlaintiffMessages {
  def_id: number;
  message: string;
  messageDate: string;
  documents: Array<Documents>;        
}
export class Messages {
  def_id: number;
  message: string;
  messageDate: string;
  messageDateTime: Date;
}
export class Documents {
  documentName: string;
  documentPath: string;
  pageCount: string;
}
export enum KEY_CODE {
  ESCAPE_KEYCODE = 27,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
 
}
export class PlaintiffDefIDs {
  plaintiffID: number;
  defIDS: string;
}
