import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing } from './adminrouting';
import { AdminpageComponent } from './adminpage/adminpage.component';
import { AgGridModule } from "ag-grid-angular";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AdminpageComponent],
  imports: [
    CommonModule,
    FormsModule,
    routing,
    AgGridModule.withComponents([AdminpageComponent])
  ]
})
export class AdminModule { }
