import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { PopupConfig } from 'src/app/Shared/Popup/popup-config';
import { PopupRef } from 'src/app/Shared/Popup/popup-ref';
import { isUndefined } from 'util';
import { BatchFile } from 'src/app/Core/Models/Batch/BatchModel';
import _ from 'lodash';

@Component({
  selector: 'nh-common-document-upload',
  templateUrl: './CommonDocumentUpload.html',
})

/** CommonDocumentUpload component*/
export class CommonDocumentUploadComponent implements OnInit, OnDestroy {

  dataService: DataService;
  selectedFile: string;
  attachementFiles: any;
  commonDoc: any;
  maxFileLength: boolean = false;
  validationmsgs: any = { "document": { "maxlength": "Documents cannot be more then 4 - 5 per Defendant" } };
  files: Array<BatchFile> = new Array<BatchFile>();
  attachedFileNames: string = "";

  ngOnDestroy(): void { }

  ngOnInit(): void {
    this.ds.setPageName(this.popupConfig.pageName);
    //console.log(this.ds.commonDocument);
  }

  /** CommonDocumentUpload ctor */
  constructor(
    private popupConfig: PopupConfig,
    private popup: PopupRef,
    private ds: DataService, ) { }

  triggerFileUpload(elementId: string) {
    document.getElementById(elementId).click();
  }

  getFileName(file) {

    // get 
    var fileData = file.name.split('.');
    var fileExtention = fileData[fileData.length - 1];

    // remove file extention form the array.
    fileData.pop();

    // join array with space and replace the string with only letter remove special char.
    var fileName = fileData.join(' ').replace(/[^a-zA-Z0-9]/g, "") + "." + fileExtention;

    return fileName;
  }

  onFileChangeAttachements(files) {
    try {
      this.ds.commonDocument = null;

      // remove old files
      var i = 0;
      if (!isUndefined(this.attachementFiles)) {
        for (let file of this.attachementFiles) {
          this.ds.BatchForm.delete(this.getFileName(file));
          this.ds.commonDoc = 0;
        }
      }

      // max file validation
      if (files.target.files.length > 5) {
        this.maxFileLength = true;
        //console.log('max length');
        return;
      }

      this.ds.commonDoc = files.target.files.length;
      this.attachementFiles = files.target.files;

      var i = 0;
      for (let file of files.target.files) {

        // build file name here!
        var cleanFileName = this.getFileName(file);

        // build the filename and page count.
        this.files.push({ "file": cleanFileName, "pagecount": 0 });

        var i = this.ds.commonDoc;

        // append to the form data
        this.ds.BatchForm.append(cleanFileName, file);

        if (this.attachedFileNames.length < 1) {
          this.attachedFileNames = file.name;
        } else {
          this.attachedFileNames = this.attachedFileNames + ',' + file.name;
        }

      }

      this.ds.commonDocument = this.files;

      // on single file css fix
      if (this.ds.commonDocument.length == 1) {
        setTimeout(function () {
          document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0;max-height: 0em');
        }, 50);
      } else {
        document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0');
      }

    } catch (e) {
      console.log(e);
    }
  }

  removeFile(file: any, event) {

    this.ds.commonDocument = _.filter(this.ds.commonDocument, function (value, index) {
      return !(value.file == file.file);
    });

    this.files = this.ds.commonDocument;

    this.ds.commonDoc = this.ds.commonDocument.length;

    // on single(one) file, Css fix
    if (this.ds.commonDocument.length == 1) {
      setTimeout(function () {
        document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0;max-height: 0em');
      }, 50);
    } else {
      document.querySelector('#filedetails').setAttribute('style', 'display:flex;flex-flow:row wrap;flex-basis:100%;justify-content:space-between;padding:0.125em 0;margin:0.125em 0');
    }

  }

  closePopUp() {

    if (this.maxFileLength) {
      return;
    }

    var _this = this;
    this.ds.commonDocument.forEach(function (value, index) {
      if (index == 0) {
        _this.ds.commonDocumentList = value.file;
      } else {
        _this.ds.commonDocumentList = _this.ds.commonDocumentList + '\n' + value.file;
      }
    });

    this.popup.close();
  }

}
