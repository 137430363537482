import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from './Core/Services/data.service';
import { Console } from '@angular/core/src/console';
import { AuthenticateService } from './Core/Services/authenticate.service';
import { isUndefined } from 'util';
import { Userdetails } from './Core/Models/UserManagment/userdetails';
import { Roles } from './Core/Models/Admin/NHAdmin/role';
import { NavigationModel, nestedNav } from './Core/Models/Navigation/NavigationModel';
import { Router } from '@angular/router';

@Component({
  selector: 'nh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'ClientApp';

  constructor(protected ds: DataService, private authenticationService: AuthenticateService, private router: Router) {
    // console.log(document.getElementById(""));

    // console.log("boot strapping app");
    let userRoles = new Array<string>();
    if (this.authenticationService.getLoggedInUser() != null && !isUndefined(this.authenticationService.getLoggedInUser().roles)) {
      (this.authenticationService.getLoggedInUser().roles.split(',').length > 0) ?
        (userRoles = this.authenticationService.getLoggedInUser().roles.split(',')) : '';

      let aa = userRoles.find(i => i.indexOf('Admin') > 0);
      if (aa != '' && aa != undefined) {
        this.ds.userRoles = new Array<string>();
        this.ds.userRoles.push('Roles');
      }
      // console.log(userRoles);
      this.ds.userRoleName = userRoles.sort();
      this.ds.createNavigationMenu(this.ds.userRoleName);
      // this.router.navigate(['/ksadmin'])

      //if (this.router.url == "/") {
      //  if (userRoles.findIndex(i => i == Roles.KSAdmin) >= 0) {
      //    //this.ds.IsAdminPage = true;
      //    this.router.navigate(['/admin']);
      //  } else if (userRoles.findIndex(i => i == Roles.ClientAdmin) >= 0 || userRoles.findIndex(i => i == Roles.ClientStaff) >= 0) {
      //    this.router.navigate(['/ridersummary']);
      //  } else {
      //    this.router.navigate(['']);
      //  }
      //}
    } 
    ds.getEnvironment().subscribe((data) => {
        ds.Environment = data.data;
        // tslint:disable-next-line: triple-equals
      //if (ds.Environment == "prod") {          
      //    document.getElementsByTagName("body")[0].className = "dev-body";
      //  }  
    });
  }
}
