import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var ga: Function; 
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(router: Router) {
    //if (!environment.production) return; // <-- If you want to enable GA only in production
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
       // console.log(event);
        let pageTitle = "";
        switch (event.url) {
          case "/":
            pageTitle = "Work-Area";
            break;
          case "/outgoing-log":
            pageTitle = "Outgoing-Log";
            break;
          case "/incoming-log":
            pageTitle = "Incomig-Log";
            break;
          case "/authentication":
            pageTitle = "Authentication";
            break;
          case "/view-all-completed":
            pageTitle ="View-All-Completed"
            break;
          default:
            pageTitle = event.url;
            break;
        }
       // console.log(pageTitle);
        ga('set', 'page', pageTitle);
        ga('send', 'pageview');
      }
    })
  }
}
