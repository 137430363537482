export class Clientmodel {
  serialNo: number;
  lawFirm_ID: number;
  num_Workorders: number;
  name: string;
  courtName: string;
  courtCountyName: string;
  plaintiffIdentifier: string;
  leadDefendantName: string;
  plaintiffIdentifierBilling: string;
  lawFirm_Name: string;
  def_name: string;
  status: string;
  jobID: string;
  ordernumber: string;
  receivedDate: string;
  processed_date: string;
  case_num: string;
  def_id: number;
  plaintiffDef_ID: number;
  plaintiff_ID: number;
  served_date: string;
  service_Status: string;
  wrappedleadDefendant: string;
  clients: Array<Clientmodel>;
}
