import { Component, Input, EventEmitter, Output, KeyValueDiffer, KeyValueDiffers, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { IncomingLog } from 'src/app/Core/Models/IncomingLog/incoming-log';
import { WorkAreaView } from 'src/app/Core/Models/WorkArea/WorkAreaModel';
import { isUndefined } from 'util';
import { OutgoingLog } from 'src/app/Core/Models/OutgoingLog/outgoing-log';
import { WorkAreaBatchModel } from 'src/app/Core/Models/WorkArea/WorkAreaBatchModel';
import { CommonService } from 'src/app/Core/Services/Common/common.service';
import { DataService } from '../../Core/Services/data.service';
//import { SearchService } from './search.service';

@Component({
  selector: 'nh-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  host: { ['class']: 'winnow' },
})

/** search component*/
export class Search {

  @Input('componentName') ComponentFor: string;
  @Input('LawFirmPrioritys') LawFirmPrioritys: [];
  @Input('IncomingLogs') IncomingLogs: Array<IncomingLog> = [];
  @Input('WorkArea') WorkArea: WorkAreaBatchModel;
  @Input('WorkAreaProcessed') WorkAreaProcessed: WorkAreaBatchModel;
  @Input('outgoinglogdata') OutGoing: Array<OutgoingLog> = [];

  // event emmiter has to be type any
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  searchedWord: any = "";
  searchTemp: any;
  selectionFilter: string = "expand all";
  searchedValue: Array<any> = [];
  filtervaluePrioriy: string = "Show All";
  statusFilter: string = "Show All";
  tempData: Array<any> = new Array<any>();
  differ: KeyValueDiffer<string, any>;
  filteredData: Array<WorkAreaView> = new Array<WorkAreaView>();
  tableFilter: string = "View Failed";
  uploadexcelselected: boolean = true;
  loadxmlformselected: boolean;
  hideSearch: boolean = false;
  allincomingLogSearch: Array<IncomingLog> = [];
  allOutgoingLogSearch: Array<OutgoingLog> = [];
  lastUpdated: string;
  nextUpdate: string;

  ngOnInit() {

    try {
      if (this.ComponentFor == "Manual" || this.ComponentFor == "Job From Excel" || this.ComponentFor == "Import From Excel")
        this.hideSearch = true;
      if (this.IncomingLogs.length) {
        this.searchTemp = _.filter(this.IncomingLogs, function (data) {
          if (data) {
            return !data.processSuccessfulYN;
          }
        });

        this.allincomingLogSearch = JSON.parse(JSON.stringify(this.IncomingLogs));
      }

      if (this.WorkArea != undefined) {
        this.searchTemp = JSON.parse(JSON.stringify(this.WorkArea));//this.WorkArea;
        //console.log(this.searchTemp);
        this.lastUpdated = this.WorkArea.workAreaDetailsEnded;
        this.nextUpdate = this.ds.serviceTime;
      }

      if (this.OutGoing.length) {
        this.searchTemp = _.filter(this.OutGoing, function (data) {
          if (data) {
            return (data.status == "FAILED");
          }
        });

        this.allOutgoingLogSearch = JSON.parse(JSON.stringify(this.OutGoing));
      }

      if (!isUndefined(this.WorkAreaProcessed)) {
        this.searchTemp = JSON.parse(JSON.stringify(this.WorkAreaProcessed));
      }

    } catch (error) {
      // console.log('on init search component', error);
    }

  }

  constructor(private common: CommonService, private ds: DataService) {

  }

  setSearchValue(value: string) { }

  // search function, IN-USE
  fullTextCompare(myWords, toMatch) {
    //Replace regex reserved characters
    myWords = myWords.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    //Split your string at spaces
    let arrWords: Array<any> = myWords.split(" ");
    //Encapsulate your words inside regex groups
    arrWords = _.map(arrWords, function (n) {
      return ["(?=.*" + n + ")"];
    });
    //Create a regex pattern
    let sRegex: RegExp = new RegExp("^" + arrWords.join("") + ".*$", "im");
    //Execute the regex match
    return (toMatch.match(sRegex) === null ? false : true);
  }

  searchData() {

    this.common.setFilterText(this.searchedWord);

    try {

      this.ds.searchedWord = this.searchedWord;

      // check if there is any word to search
      if (this.searchedWord.length > 1) {

        // console.log('searching....');        

        // start searching
        if (this.ComponentFor == "Incoming-Log") {
          console.log('incoming search.');
          this.searchedValue = this.Incominglogsearch();
        }

        if (this.ComponentFor == "Work-Area") {
          // console.log('Work-Area search.', this.searchedWord);
          this.searchedValue = this.WorkAreaNestedSearch().workStatusInProgress;
        }

        if (this.ComponentFor == "Outgoing-Log") {
          // console.log('outgoing log search...', this.searchedWord)
          this.searchedValue = this.OutGoingSearch();
        }

        if (this.ComponentFor == "View all completed") {
          this.searchedValue = this.WorkAreaNestedProcessedSearch().workStatusInProgress;
        }

        //if (!isUndefined(this.WorkAreaProcessed)) {
        //  this.searchTemp = JSON.parse(JSON.stringify(this.WorkAreaProcessed));
        //}

        // return searched value to the parent component
        this.search.emit(this.searchedValue);

      } else {

        if (this.WorkArea != undefined) {
          this.selectionFilter = "expand all";
          this.searchTemp = JSON.parse(JSON.stringify(this.WorkArea));
        }

        if (!isUndefined(this.WorkAreaProcessed)) {
          this.selectionFilter = "expand all";
          var temp = JSON.parse(JSON.stringify(this.WorkAreaProcessed));
          this.searchTemp = JSON.parse(JSON.stringify(temp));
        }

        if (this.ComponentFor == "Incoming-Log") {
          if (this.tableFilter == 'View Failed') {
            this.search.emit(_.filter(this.searchTemp, function (data) {
              if (data && data.processSuccessfulYN == 0) {
                return true;
              } else {
                return false;
              }
            }));
          } else {
            this.search.emit({ filterStatus: true, filterFor: 'View All' });
          }
        }

        if (this.ComponentFor == "Outgoing-Log") {
          if (this.tableFilter == 'View Failed') {
            this.search.emit(_.filter(this.searchTemp, function (data) {
              if (data) {
                return (data.status == "FAILED");
              }
            }));
            return;
          } else {
            this.search.emit({ filterStatus: true, filterFor: 'View All' });
            return;
          }
        }

        // respond to parent component with reset if there is no search string.
        this.search.emit('reset');
      }

    } catch (error) {
      console.log(error);
    }

  }

  formSelection(selection: String) {
    console.log(selection);
    if (selection == "fromExcel") {
      console.log(selection);
      this.uploadexcelselected = true;
      this.loadxmlformselected = false;
    } else {
      this.uploadexcelselected = false;
      this.loadxmlformselected = true;
    }
    this.search.emit(selection);
  }

  clearSearchWord() {
    this.searchedWord = "";
    this.searchData();
  }

  WorkAreaNestedSearch(): WorkAreaBatchModel {
    var _this = this;
    var dash = _;
    // console.log(this.searchedWord);
    this.filtervaluePrioriy = 'show all';
    this.AnimateFilter();
    this.searchTemp = JSON.parse(JSON.stringify(this.WorkArea));

    this.searchTemp.workStatusInProgress
      .forEach(function (value, index) {
        if (value.defStatInProgress) {
          _this.searchTemp.workStatusInProgress[index].defStatInProgress =
            dash.filter(value.defStatInProgress, function (data) {
              if (data) {
                var searchWord: string = data.state + ' ' + data.city + ' ' + data.def_name;;
                return _this.fullTextCompare(_this.searchedWord, searchWord);
              }
            });
        }
      });

    this.selectionFilter = "collapse all";
    return this.searchTemp;
  }

  WorkAreaNestedProcessedSearch(): WorkAreaBatchModel {
    var _this = this;
    var dash = _;
    this.statusFilter = 'show all';
    this.AnimateFilter();
    this.searchTemp = JSON.parse(JSON.stringify(this.WorkAreaProcessed));

    this.searchTemp.workStatusInProgress
      .forEach(function (value, index) {
        if (value.defStatInProgress) {
          _this.searchTemp.workStatusInProgress[index].defStatSucceded =
            dash.filter(value.defStatSucceded, function (data) {
              if (data) {
                var searchWord: string = data.state + ' ' + data.city + ' ' + data.def_name;
                return _this.fullTextCompare(_this.searchedWord, searchWord);
              }
            });
        }
      });


    this.selectionFilter = "collapse all";

    return this.searchTemp;
  }

  WorkAreaProcessedFilter(filter: string) {

    var _this = this;
    var dash = _;
    this.statusFilter = filter;
    filter = filter.replace('only', '');
    this.searchTemp = JSON.parse(JSON.stringify(this.WorkAreaProcessed));
    this.AnimateFilter();

    if (filter == 'show all') {
      this.search.emit('reset');
      this.selectionFilter = "expand all";
      return;
    } else {
      this.selectionFilter = "collapse all";
      this.searchTemp.
        workStatusInProgress
        .forEach(function (value, index) {
          if (value.defStatSucceded) {
            _this.searchTemp.workStatusInProgress[index].defStatSucceded =
              _.filter(value.defStatSucceded, function (data) {
                if (data) {
                  var searchWord: string = data.logStatus;
                  //console.log('searching for...', searchWord, ':::--> ', 'match this word ', filter);
                  return _this.fullTextCompare(filter, searchWord);
                }
                // return false in case of no data to search.
                return false;
              });
          }
          //console.log('filtered....', _this.searchTemp[index].defendants);
        });
      //console.log("Filtered",this.searchTemp);
      this.search.emit(this.searchTemp.workStatusInProgress);
    }
  }

  WorkAreaFilter(filter: string) {

    var _this = this;
    var dash = _;
    this.filtervaluePrioriy = filter;
    filter = filter.replace('only', '');
    this.common.setFilterText(filter);
    this.AnimateFilter();

    this.clearSearchWord();

    if (filter == 'show all') {
      this.ds.searchedWord = "";
      this.search.emit('reset');
      this.selectionFilter = "expand all";
    } else {
      this.ds.searchedWord = filter;
      this.selectionFilter = "collapse all";
      this.searchTemp
        .workStatusInProgress
        .forEach(function (value, index) {
          if (value.defStatInProgress) {
            // _this.searchTemp[index].defendants
            _this.searchTemp.workStatusInProgress[index].defStatInProgress =
              dash.filter(value.defStatInProgress, function (data) {
                if (data) {
                  var searchWord: string = data.priorityString;
                  //console.log('searching for...', searchWord, ':::--> ', 'match this word ', filter);
                  return _this.fullTextCompare(filter, searchWord);
                }
                // return false in case of no data to search.
                return false;
              });
            //console.log('filtered....', _this.searchTemp[index].defendants);
          }
        });
      this.search.emit(this.searchTemp.workStatusInProgress);
    }

  }

  WorkAreaSearch() {
    var _this = this;
    return _.filter(this.searchTemp, function (data) {
      if (data) {
        var searchWord: string = data.lawFirmName + ' ' + data.priorityString;
        return _this.fullTextCompare(_this.searchedWord, searchWord);
      }
      // return false in case of no data to search.
      return false;
    });
  }

  WorkareaCollapeExapand(selection: string) {
    if (selection == "expand all") {
      this.selectionFilter = 'collapse all';
      this.search.emit('expand all');
    } else {
      this.selectionFilter = 'expand all';
      this.search.emit('collapse all');
    }
  }

  Incominglogsearch() {

    try {

      var _this = this;
      var searchedData: Array<IncomingLog>;

      if (this.tableFilter == 'View Failed') {

        searchedData = _.filter(this.searchTemp, function (data) {
          if (data) {
            var searchWord: string = data.lawFirmName + ' ' + data.batchPriorityDescription;
            //console.log('word search', searchWord);
            // this will return true.
            return _this.fullTextCompare(_this.searchedWord, searchWord);
          }
          // return false in case of no data to search.
          return false;
        });

      } else {

        searchedData = _.filter(this.allincomingLogSearch, function (data) {
          if (data) {
            var searchWord: string = data.lawFirmName + ' ' + data.batchPriorityDescription;
            //console.log('word search', searchWord);
            // this will return true.
            return _this.fullTextCompare(_this.searchedWord, searchWord);
          }
          // return false in case of no data to search.
          return false;
        });

      }

    } catch (error) {
      console.log(error);
    }

    return searchedData;
  }

  OutGoingSearch() {

    try {

      var _this = this;
      var searchedData: Array<OutgoingLog>;

      if (this.tableFilter == 'View Failed') {

        searchedData = _.filter(this.searchTemp, function (data) {
          if (data) {
            var searchWord: string = data.plaintiff_name + ' ' + data.def_name;
            //  console.log('word search', searchWord);
            // this will return true.
            return _this.fullTextCompare(_this.searchedWord, searchWord);
          }
          // return false in case of no data to search.
          return false;
        });

      } else {

        searchedData = _.filter(this.allOutgoingLogSearch, function (data) {
          if (data) {
            var searchWord: string = data.plaintiff_name + ' ' + data.def_name;
            //  console.log('word search', searchWord);
            // this will return true.
            return _this.fullTextCompare(_this.searchedWord, searchWord);
          }
          // return false in case of no data to search.
          return false;
        });

      }

    } catch (error) {
      console.log(error);
    }

    return searchedData;
  }

  incomingLogFailed(filter) {

    try {
      if (this.IncomingLogs.length) {

        if (filter == 'View Failed') {

          var _this = this;
          this.tableFilter = filter;

          var data = _.filter(this.searchTemp, function (data) {
            if (data && data.processSuccessfulYN == 0) {
              return true;
            } else {
              return false;
            }
          });

          this.search.emit({ data: data, filterStatus: true, filterFor: filter });

        } else if (filter == 'View All') {
          this.tableFilter = filter;
          this.search.emit({ filterStatus: true, filterFor: filter });
        }

      }
    } catch (error) {
      console.log(error);
    }

  }

  outgoingLogFailed(filter) {

    try {
      if (this.OutGoing.length) {

        if (filter == 'View Failed') {

          var _this = this;
          this.tableFilter = filter;
          var test = this.searchTemp;
          var data = _.filter(this.searchTemp, function (data) {
            if (data) {
              return (data.status == "FAILED");
            }
          });

          this.search.emit({ data: data, filterStatus: true, filterFor: filter });

        } else if (filter == 'View All') {
          this.tableFilter = filter;
          this.search.emit({ filterStatus: true, filterFor: filter });
        }

      }
    } catch (error) {
      console.log(error);
    }

  }

  AnimateFilter() {
    // console.log("Loaded");
    var actors = { actor_1: null };

    if (document.getElementById("_SVG_icons") == null)
      return;

    var filterCXCY = (this.ComponentFor == "View all completed" ? this.statusFilter == "show all" ? 0 : 12 : this.filtervaluePrioriy == "show all" ? 0 : 12);

    actors.actor_1 = {
      node: document.getElementById("gradientCircle"),//.getElementById("gradientCircle"),
      type: "circle",
      cx: filterCXCY,
      cy: filterCXCY,
      dx: 12,
      dy: 6,
      opacity: (this.ComponentFor == "View all completed" ? this.statusFilter == "show all" ? 0 : 1 : this.filtervaluePrioriy == "show all" ? 0 : 1),
    };

    var tricks = { trick_1: null };

    tricks.trick_1 = (function (_, t) {
      t = (function (n) {
        return .5 > n ? 2 * n * n : -1 + (4 - 2 * n) * n
      })(t) % 1, t = 0 > t ? 1 + t : t;
      var i;
      i = 0.1 >= t ? 1 + (2.7 - 1) / 0.1 * t : t >= 0.8 ? 2.7 - (t - 0.8) * ((2.7 - 1) / (1 - 0.8)) : 2.7;
      var a = _._tMatrix,
        filterCircleRad = (this.ComponentFor == "View all completed" ? this.statusFilter == "show all" ? 0 : -_.cx * i + _.cx : this.filtervaluePrioriy == "show all" ? 0 : -_.cx * i + _.cx),

        r = filterCircleRad,
        x = -_.cy * i + _.cy,
        c = a[0] * i,
        n = a[1] * i,
        M = a[2] * i,
        f = a[3] * i,
        g = a[0] * r + a[2] * x + a[4],
        m = a[1] * r + a[3] * x + a[5];
      _._tMatrix[0] = c, _._tMatrix[1] = n, _._tMatrix[2] = M, _._tMatrix[3] = f, _._tMatrix[4] = g, _._tMatrix[5] = m
    });

    tricks.trick_1 = (function (_, t) {
      t = (function (n) {
        return .5 > n ? 2 * n * n : -1 + (4 - 2 * n) * n
      })(t) % 1, t = 0 > t ? 1 + t : t;
      var i;
      i = 0.1 >= t ? 1 + (2.7 - 1) / 0.1 * t : t >= 0.8 ? 2.7 - (t - 0.8) * ((2.7 - 1) / (1 - 0.8)) : 2.7;
      var a = _._tMatrix,
        filterCircleRad = (this.ComponentFor == "View all completed" ? this.statusFilter == "show all" ? 0 : -_.cx * i + _.cx : this.filtervaluePrioriy == "show all" ? 0 : -_.cx * i + _.cx),

        r = filterCircleRad,
        x = -_.cy * i + _.cy,
        c = a[0] * i,
        n = a[1] * i,
        M = a[2] * i,
        f = a[3] * i,
        g = a[0] * r + a[2] * x + a[4],
        m = a[1] * r + a[3] * x + a[5];
      _._tMatrix[0] = c, _._tMatrix[1] = n, _._tMatrix[2] = M, _._tMatrix[3] = f, _._tMatrix[4] = g, _._tMatrix[5] = m
    });

    // console.log((this.ComponentFor == "View-all-completed" ? this.statusFilter == "all" ? 0 : 3 : this.filtervaluePrioriy == "all" ? 0 : 2))

    var filtercry = (this.ComponentFor == "View all completed" ? this.statusFilter == "show all" ? 0 : 1800 : this.filtervaluePrioriy == "show all" ? 0 : 1800);
    var scenarios = { scenario_1: null };
    scenarios.scenario_1 = {
      actors: ["actor_1"],
      tricks: [{
        trick: "trick_1",
        start: 0,
        end: 1
      }],
      startAfter: 0,
      duration: filtercry,
      actorDelay: 0,
      repeat: 0,
      repeatDelay: 0
    };

    var _reqAnimFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame,//|| || window.mozRequestAnimationFramewindow.oRequestAnimationFrame,

      fnTick = function (t) {
        var r, a, i, e, n, o, s, c, m, f, d, k, w;
        for (c in actors) actors[c]._tMatrix = [1, 0, 0, 1, 0, 0];
        for (s in scenarios)
          for (o = scenarios[s], m = t - o.startAfter, r = 0, a = o.actors.length; a > r; r++) {
            if (i = actors[o.actors[r]], i && i.node && i._tMatrix)
              for (f = 0, m >= 0 && (d = o.duration + o.repeatDelay, o.repeat > 0 && m > d * o.repeat && (f = 1), f += m % d / o.duration), e = 0, n = o.tricks.length; n > e; e++) k = o.tricks[e], w = (f - k.start) * (1 / (k.end - k.start)), tricks[k.trick] && tricks[k.trick](i, Math.max(0, Math.min(1, w)));
            m -= o.actorDelay
          }
        for (c in actors) i = actors[c], i && i.node && i._tMatrix && i.node.setAttribute("transform", "matrix(" + i._tMatrix.join() + ")");
        _reqAnimFrame(fnTick)
      }; _reqAnimFrame(fnTick);
  }

}
