
export class nestedNav {
  redirectURL: string;
  title: string;
  attrib: string;
  //nestedNav: Array<nestedNav>;
}

export class NavigationModel {
  redirectURL: string;
  title: string;
  attrib: string;
  nestedActive: boolean;
  nestedNav: Array<nestedNav>;
}
