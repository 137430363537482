import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';

@Component({
  selector: 'nh-incominglog',
  templateUrl: './xml-validation.component.html',
  //styleUrls: ['./incominglog.component.less']
})

export class XmlValidationComponent implements OnInit {

  ds: DataService;
  xmlForm: FormGroup;
  public xmlfile: string = "";
  public submitResponse: any;

  constructor(private _ds: DataService, private formBuilder: FormBuilder) {
    this.ds = _ds;
  }

  get f() { return this.xmlForm.controls; }

  ngOnInit() {

    this.xmlForm = this.formBuilder.group({
                    xmlfile: ['', Validators.required]
                  });

  }

  postXML() {

    this.ds.validateXmlWithSave(this.f.xmlfile.value).subscribe(data => {
      this.submitResponse = data;
    });

  }

}
