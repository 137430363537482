import { Component, OnInit } from '@angular/core';
import { Documents } from 'src/app/Core/Models/WorkArea/PlainTiffModel';
import { PopupConfig } from '../Popup/popup-config';
import { PopupRef } from '../Popup/popup-ref';
import { DataService } from 'src/app/Core/Services/data.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'nh-pdfcontainer',
  templateUrl: './pdfcontainer.component.html',
  styleUrls: ['./pdfcontainer.component.less']
})
export class PdfcontainerComponent implements OnInit {

  document: Documents;
  dataService: DataService;
  constructor(private popupConfig: PopupConfig, private popup: PopupRef, private ds: DataService, private datePipe: DatePipe) {
    this.dataService = ds;
  }

  ngOnInit() {
    this.document = this.popupConfig.data.documentPath;
  }

}
