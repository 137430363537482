import { Routes, RouterModule } from '@angular/router';
import { AdminpageComponent } from './adminpage/adminpage.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthGuard } from 'src/app/Core/Services/AuthGurd/auth.guard';
import { RoleGuardService } from 'src/app/Core/Services/AuthGurd/role-guard.service';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
const routes: Routes=[
  //{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminpageComponent, canActivate: [RoleGuardService], data: { role: Roles.KSAdmin } },//+","+Roles.NHAdmin
];
export const routing: ModuleWithProviders = RouterModule.forChild(routes)
