import { PriorityTitle } from "./WorkAreaModel";

export class DefendantModel {
  def_id: number;
  jobId: string;
  def_name: string;
  streetAddress1: string;
  streetAddress2: string;
  streetAddress3: string;
  city: string;
  state: string;
  countryCode: string;
  zip: string;
  def_guid: string;
  recCreateDate: Date;
  recUpdateDate: Date;
  recCreated_by: string;
  recUpdated_by: string;
  status: string;
  server: string;
  plaintiffcount: number;
  plaintiffcountvalue: string;
  lawFirm: string;
  assignedTo: string;
  defIDS: string;
  county: string;
  viewicon: string;
  court: string;
  batchNotes: string;
  batchNotesDate: string;//Temporary
  buttonText: string;
  buttonDisabled: boolean;
  priorities: PriorityTitle[];
  priorityString: string;
  lawFirm_ID: number;//For Search pusrpose
  statusStrings: Array<string>;
  logStatus: string;
  isActive: boolean;
  isUpdated: boolean;
  def_trimmedName: string
}

export class JobFile {
  LoggedInUser: string;
  LawFirm_ID: number;
  Email: string;
  Name: string;
  LawFirm_Name: string;
  LawFirm_API_KEY: string;
  LawFirm_Identifier: string;
  BatchNotes: string;
  DropboxLink: string;
  isDocsFromDropbox: boolean;
  models: Array<FormDefendantModel>;
  model: string;
}


export class FormDefendantModel {
  IsChecked: boolean;
  PlaintiffId: number;
  PlaintiffName: string;
  Def_ID: number;
  DefendantName: string;
  StreetAddress1: string;
  StreetAddress2: string;
  StreetAddress3: string;
  StreetAddress4: string;
  StreetAddress5: string;
  DocumentFileName1: string;
  DocumentFileName2: string;
  DocumentFileName3: string;
  DocumentFileName4: string;
  DocumentFileName5: string;
  State: string;
  ZipCode: string;
  City: string;
  Country: string;
  FileNames: string;
  OrderNumber: string;
  BatchFiles: string;
  suffix: string;
}
