import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { DataService } from '../data.service';
import { Roles } from '../../Models/Admin/NHAdmin/role';
import { isUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  public isAuth;

  constructor(private auth: AuthenticateService, private route: Router, private dataservice: DataService) {

    //let user = this.auth.getLoggedInUser();

   

    //if (!isUndefined(user)&&!isUndefined(user.roles) && user.roles.includes(',')) {
    //  if (user.roles.split(',').findIndex(i => i == Roles.KSAdmin) >= 0) {
    //   // this.dataservice.IsAdminPage = true;
    //  }

    //} else {
    //  //if (user.roles == Roles.KSAdmin)
    //  //  this.dataservice.IsAdminPage = true;
    //}
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (this.auth.IsAuthenticated()) {
      if (!isUndefined(next) && next != null && !isUndefined(next.data) && next.data != null && !isUndefined(next.data.role) && next.data.role != null ) {
        let user = this.auth.getLoggedInUser();

        if (JSON.stringify(user.roles) === JSON.stringify(next.data.role)) {
          this.isAuth = true;
          return true;
        } else if ((user.roles.indexOf(next.data.role) >= 0) || next.data.role.indexOf(user.roles) >= 0) {
          this.isAuth = true;
          return true;
        } else {
          let list = next.data.role.split(',');
          for (var i = 0; i < list.length; i++) {
            if (user.roles.indexOf(list[i]) >= 0) {
              return true;
            }
          }
        }
        this.route.navigate(['/404']);
        return false;

      }
      this.isAuth = true;
      return true;
      // navigate to not found page
      } else {
      this.isAuth = false;
      this.route.navigate(["authentication"]);
      return false;
    }

  }

}
