import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/Core/Services/data.service';

@Injectable()
export class DefendantDataModelService {

  numValue: number = 0;
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  constructor() { }

  setnumValue(value: number) { this.numValue = value; }

  getNumValue() { return this.numValue; }
  
  changeMessage(message: string) { this.messageSource.next(message) }

}
