import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  filterText: string = null;
  constructor() { }
  setFilterText(filterText: string) {
    this.filterText = filterText;
  }
  getFilterText() {
    return this.filterText;
  }
}
