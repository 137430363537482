import { Component, OnInit, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import _ from 'lodash';
import { isUndefined } from 'util';

@Component({
    selector: 'nh-registration',
    templateUrl: './registration.component.html',
})

export class RegistrationComponent implements OnInit {

  private registerForm: FormGroup;
  private registerLoading = false;
  private registerSubmitted = false;
  private registerSuccess = false;
  public errorMessages: any;
  public error: string;
  public signupState = false;
  public EmptyForm = false;
  public UserNameError = false;
  public EmailError = false;
  differ: KeyValueDiffer<string, any>;

  setClass() { }

  // email please enter email in the following format – abc@xyz.com
  validationmsgs = {
                      "username": {
                        "required": "Username is required",
                        "minlength": "Username should be atleast 4 characters long",
                        "maxlength": "Username cannot be more than 16 characters long",
                        "pattern": "Username cannot contain special characters",
                      },
                      "firstname": {
                        "required": "Firstname is required",
                        "minlength": "Firstname should be atleast 4 characters long",
                        "maxlength": "Firstname cannot be more than 16 characters long",
                        "pattern": "Firstname cannot contain special characters"
                      },
                      "lastname": {
                        "required": "Lastname is required",
                        "minlength": "Lastname should be atleast 4 characters long",
                        "maxlength": "Lastname cannot be more than 16 characters long",
                        "pattern": "Lastname cannot contain special characters"
                      },
                      "password": {
                        "required": "Password is required",
                        "minlength": "Minimum length 8 chars",
                        "maxlength": "Maximum 16 characters long",
                        "onenumber": " password must have atleast 1 number between 0 - 9",
                        "uppercasealphabet": " password must have atleast 1 uppercase letter ",
                        "match": "Passwords do not match"
                      },
                      "email": {
                        "required": "Email is required",                        
                        "msg": "Invalid email format",
                      },
                      "registration_successful": "Registration successful, you will be redirected to login page in 4 seconds.",
                      "empty_fields": "Some of the required fields are empty.",
                      "required": "All fields are required. Except Location",
                      "redirect": "go back to sign in page."
  };

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authenticationService: AuthenticateService,
              public differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck() {
    const change = this.differ.diff(this);

    if (change) {
      change.forEachChangedItem(item => {
        console.log(item);              
      });
    }

  }

  ngOnInit() {    

    this.registerForm = this.formBuilder.group({
                              UserName: ['',[
                                  Validators.required,
                                  Validators.minLength(4),
                                  Validators.maxLength(16),
                                  Validators.pattern('^[a-zA-Z0-9]*$')
                                ]],
                              FirstName: ['', [
                                Validators.required
                              ]],
                              LastName: ['', [
                                Validators.required
                              ]],
                              Email: ['', [
                                Validators.required,
                                Validators.pattern(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/),                                ,
                              ]],
                              RegisterPassword: ['', [
                                Validators.required,
                                Validators.minLength(8),
                                Validators.maxLength(16)
                              ]],
                              ConfirmPassword: ['', [
                                Validators.required,
                                Validators.minLength(8),
                                Validators.maxLength(16)
                              ]],
                              Location: ['']
                        },
                        {
                          updateOn: 'blur'
                        });
  }

  redirect() {
    this.signupState = true;
    var _this: any;
    _this = this;
    setTimeout(function () {
      console.log(this.router);
      _this.router.navigate(['authentication']);
    }, 3000);
  }

  passwordCheck() {
    
      // Password validation
      if (this.registerForm.value.RegisterPassword.match(/\d+/g) == null) {
        console.log('one number..');
        this.registerForm.controls.RegisterPassword.setErrors({ onenumber: true });
      } else {
      }

      if (this.registerForm.value.RegisterPassword.match(/[A-Z]/g) == null) {
        console.log('upper case..');
        this.registerForm.controls.RegisterPassword.setErrors({ uppercase: true });
      } else {
    }

  }

  passwordmatch() {

    this.passwordCheck();
    
    // confirm password numeric validation
    if (this.registerForm.value.ConfirmPassword != '') {
      if (this.registerForm.value.ConfirmPassword.match(/\d+/g) == null) {
        this.registerForm.controls.ConfirmPassword.setErrors({ onenumber: true });
      } else {
        this.registerForm.controls.ConfirmPassword.setErrors({ onenumber: false });
      }        
    }

    //  match password and conform-password validation
    if (this.registerForm.value.RegisterPassword != '' && this.registerForm.value.ConfirmPassword != '') {
      if (this.registerForm.value.RegisterPassword !== this.registerForm.value.ConfirmPassword) {
        this.registerForm.controls.ConfirmPassword.setErrors({ match: true });
      } else {
        this.registerForm.controls.ConfirmPassword.setErrors(null);
      }
    }
       
  }
  
  // short hand notation for registerform.controls
  get f() { return this.registerForm.controls }

  passwordReset() {
    console.log('password resetting');
    this.registerForm.value.ConfirmPassword = "";
  }

  registerSubmit() {

    this.passwordmatch();

    console.log('registration submit....');
    this.registerSubmitted = true;

    if (this.UserNameError) {
      this.UserNameError = false;
    }

    if (this.EmailError) {
      this.EmailError = false;
    }


    if (this.registerForm.invalid) {
      console.log('invalid:- values ', this.f.UserName.value, this.f.FirstName.value, this.f.RegisterPassword.value, this.f.Location.value, this.f.Email.value);
      this.EmptyForm = true;
      return;
    }
    
    console.log('valid form');
    this.EmptyForm = false;

    
    type UserDetails = {
      UserName: string;
      FirstName: string;
      LastName: string;
      Password: string;
      Location: string;
      Email: string;
    }

    const payload = {} as UserDetails;

    payload.UserName = this.f.UserName.value;
    payload.FirstName = this.f.FirstName.value;
    payload.LastName = this.f.LastName.value;
    payload.Password = this.f.RegisterPassword.value;
    payload.Location = this.f.Location.value;
    payload.Email = this.f.Email.value;

    this.authenticationService.registration(payload)
       .subscribe(
        result => {
            if (result.error) {

              if (result.fieldName == "UserName") {
                this.UserNameError = true;
                this.error = result.message;
              }

              if (result.fieldName == "Email") {
                this.EmailError = true;
                this.error = result.message;
              }

            } else {
              this.redirect();
              this.registerSuccess = true;
            }

          },
         err => this.error = 'Could not authenticate'
       );

    // remove this if we have register service from authentication-service.
    //this.register = false;
    this.registerSuccess = true;

  }

}

