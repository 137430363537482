import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { JobsDashboardSearch } from 'src/app/Core/Models/Admin/NHAdmin/jobs-dashboard-search';
import { JobDashboardModel, DateTypes, PriorityTypes, StatusTypes, JobServiceDetailsModel, JobInvoiceDetails } from 'src/app/Core/Models/Admin/NHAdmin/job-dashboard-model';
import { GridOptions, PopupComponent } from 'ag-grid-community';
import { isUndefined } from 'util';
import { LawFirmModel } from 'src/app/Core/Models/CreateJob/lawFrimModel';
import { PopupService } from 'src/app/Shared/Popup/popup.service';
import { PdfviewerComponent } from '../pdfviewer/pdfviewer.component';
import { CurrencyComponent } from './currency.component';
import * as _ from 'lodash';
@Component({
  selector: 'nh-jobsdashboard',
  templateUrl: './jobsdashboard.component.html',
  styleUrls: ['./jobsdashboard.component.less']
})

export class JobsdashboardComponent implements OnInit {
  firstValue: string = "";
  secondValue: string = "";
  listCount: number;
  private gridApi;
  private gridColumnApi;
  private paginationProxy;
  private invoiceGridApi;
  private invoiceGridColumnApi;
  private rowSelection;
  errorMessage: string = "";
  errorMessage1: string = "";
  overlayNoRowsTemplate: string;
  private gridOptions: GridOptions;
  private gridOptions1: GridOptions;
  private invoiceGridOptions: GridOptions;
  public rowData: any[];
  public rowData1: any[];
  public InvoiceRowData: any[];
  private columnDefs: any[];
  private columnDefs1: any[];
  public InoviceColumnDefs: any[];
  showGrid: boolean;
  selectedValue: number = 0;
  searchedResult: any;
  toggleButtonText: string;
  showSearch: boolean;
  jobsList: Array<JobDashboardModel>;
  jobsListFiltered = new Array<JobDashboardModel>();
  jobsServiceList: Array<JobServiceDetailsModel>;
  jobServiceDetailsModel: JobServiceDetailsModel;
  jobsServiceFiltered: Array<JobServiceDetailsModel>;
  gridData: Array<JobDashboardModel>;
  lawFirmsList=new Array<LawFirmModel>();
  dateTypes = new Array<DateTypes>();
  batchPriorityTypes = new Array<PriorityTypes>();
  statusList = new Array<StatusTypes>();
  jobInvoiceDetails = new Array<JobInvoiceDetails>();
  nodata: boolean;
  titleInfo: string;
  serviceDetails: boolean;
  aosDetails: boolean;
  invoiceDetails: boolean;
  jobDashBSearch = new JobsDashboardSearch();
  jobID: string;
  displayError: boolean;
  errorText: string;
  
  static thisClass: any;
  public nH_dashboard = true;
  public nH_jobserviceInvoice = false;
  public nH_jobserviceAOS = false;
  aosStatus: string;
  totalAmout = 0;
  serviceStatus: string;
  headingTitle: string;
  constructor(private dataservice: DataService, private popupService: PopupService) {
    this.gridColumnsArragment();
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
  }
  
  ngOnInit() {
    this.toggleButtonText = "Hide search options";
    this.showSearch = true;
    this.dataservice.getLawFirms().subscribe(lawFirms => {
      this.lawFirmsList.push({ lawFirm_ID: "0", lawFirm_Name: "<< Any >>", isSelected: false, lawFirm_API_KEY: "", lawFirm_Identifier: "", userID:0 });
      lawFirms.forEach((item) => {
        this.lawFirmsList.push(item);
      })
     // var _this = this;
      JobsdashboardComponent.thisClass = this;
    });

    this.dataservice.getGetBatchPriorityList().subscribe(priorityList => {
      this.batchPriorityTypes.push({ batchPriority_ID: 0, batchDescr: "<< Any>>", batchPriority:"<< Any >>" });

      priorityList.forEach((item) => {
        this.batchPriorityTypes.push(item);
      });
    });

    this.statusList.push({ statusID: 0, statusName:"<< Any >>" });
    this.statusList.push({ statusID: 1, statusName: "In Progress" });
    this.statusList.push({ statusID: 2, statusName: "Served" });
    this.statusList.push({ statusID: 3, statusName: "Not Served" });
    this.statusList.push({ statusID: 4, statusName: "Not Applicable" });
    this.statusList.push({ statusID: 5, statusName: "Completed" });
    this.statusList.push({ statusID: 6, statusName: "AOS filed" });


    this.dateTypes.push({ dateTypeID: 0, dateTypeName: "<< None >>" });
    this.dateTypes.push({ dateTypeID: 1, dateTypeName: "Received Date" });
    this.dateTypes.push({ dateTypeID: 2, dateTypeName: "Processed Date" });
    this.dateTypes.push({ dateTypeID: 3, dateTypeName: "Serve By Date" });
    this.dateTypes.push({ dateTypeID: 4, dateTypeName: "Served Date" });
    this.jobDashBSearch.lawFirm_ID = 0;
    this.jobDashBSearch.priorityID = 0;
    this.jobDashBSearch.status = "<< Any >>";
    this.jobDashBSearch.dateFilterType = 0;
    let job = new JobsDashboardSearch();
    job.isDefault = 1;

    this.dataservice.getJobsDashboardResults(job).subscribe(data => {
      //  this.lastTenJobs = true;
   //   console.log(data);
     // we are getting days remaining as string value; 
      this.jobsList = JSON.parse(JSON.stringify(data));
      this.jobsList.forEach((v) => {
       // console.log(v.daysRemaining);
        if (!isUndefined(v.daysRemaining) && v.daysRemaining != null && v.daysRemaining.toString()!="")
        v.daysRemaining = parseInt(v.daysRemaining.toString());
      });
      this.jobsList = _.orderBy(this.jobsList, i => i.daysRemaining, 'asc');
      if (!isUndefined(this.jobsList))
        this.showGrid = this.jobsList.length > 0 ? true : false;

     // console.log(this.jobsList);
      this.rowData = this.jobsList;
      
    });
    
  }
  onGridReady(params) {
    this.paginationProxy = params.api;
    
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  gridColumnsArragment() {
    //if (type == 0) {
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
    };
    this.columnDefs = [
      { headerName: 'JobID', field: 'jobID', width: 60, sortingOrder: ["asc", "desc"],cellStyle: { textAlign: "right" } },
      { headerName: 'Priority ', field: 'batchPriority',  width: 65,  sortingOrder: ["asc", "desc"] },
      {
        headerName: 'Defendant', field: 'def_name', width: 220, sortingOrder: ["asc", "desc"], cellRenderer: (params) => {
          return "<span style='text-transform: capitalize;'>" + params.value + "</span>"
        }
      },
      { headerName: 'Recv\'d Date', field: 'receivedDate',  width: 130, sortingOrder: ["asc", "desc"]},
      { headerName: 'Processed Date', field: 'processed_date',width: 130,  sortingOrder: ["asc", "desc"] },
      { headerName: 'Serve By Date', field: 'expectedDeliveryDate', width: 130,  sortingOrder: ["asc", "desc"] },
      { headerName: 'Days Remaining', field: 'daysRemaining', width: 100,sort: "asc", sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" } },
      { headerName: 'Served Date ', field: 'served_date', width: 120, sortingOrder: ["asc", "desc"] },
      //{ headerName: 'Status', field: 'status', tooltipField: 'status', width: 140, sortingOrder: ["asc", "desc"], cellRenderer: this.XMLCellRenderer },
      {
        headerName: 'Status', field: 'status', width: 90, sortingOrder: ["asc", "desc"], cellRenderer:
          (params) => {
            if (!isUndefined(params.value) && params.value != null && params.value != "") {
              if (params.value == "In Progress") {
                return "<span>" + params.value + "</span>"
              } else {
                return "<a target='_blank' style='color:blue;text-decoration:underline;cursor:pointer;font-weight:400'>" + params.value + "</a>";
                // return '<button>' + params.value + '</button>';
              }
            } 
          },
        onCellClicked: (params) => {
          if (params.value != "" && params.value!="In Progress") {
            JobsdashboardComponent.thisClass.nH_dashboard = false;
            //let test = new JobsDashboardSearch();
            JobsdashboardComponent.thisClass.jobServiceDetailsView(params);
          }
        }
      },
    ];
    this.gridOptions1 = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        //  console.log("model updated");
        this.gridOptions1.api.sizeColumnsToFit();
      },
    };
    this.columnDefs1 = [
      { headerName: 'Attempt #', field: 's_CommentSeqNum', sort: "asc",  width: 40, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
      { headerName: 'Date & Time ', field: 'currentDateTime',  width: 100, sortingOrder: ["asc", "desc"] },
      { headerName: 'Comments', field: 'comment_Text', tooltipField: 'comment_Text', width: 280, sortingOrder: ["asc", "desc"] }      
    ];
    this.invoiceGridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        this.invoiceGridOptions.api.sizeColumnsToFit();
      },
    };
    //cellRendererFramework: CurrencyComponent,
    this.InoviceColumnDefs = [
      { headerName: 'Invoice Date', field: 'recCreateDate', width: 90, sortingOrder: ["asc", "desc"] },
      { headerName: 'Line Item Description', field: 'line_Descr',  width: 250, sortingOrder: ["asc", "desc"] },
      { headerName: 'Quantity', field: 'line_Quantity',  width: 80, sortingOrder: ["asc", "desc"] },
      { headerName: 'Rate ($)', field: 'line_Rate',  width: 80, sortingOrder: ["asc", "desc"], cellRendererFramework: CurrencyComponent },
      { headerName: 'Amount ($)', field: 'line_Amount',  width: 80, sortingOrder: ["asc", "desc"], cellRendererFramework: CurrencyComponent }
    ];
    this.rowSelection = 'single';
  }
  resetColumns() {
    this.gridColumnApi.resetColumnState();
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  sizeToFit() {
    if (!isUndefined(this.gridApi))
      this.gridApi.sizeColumnsToFit();
    if (!isUndefined(this.invoiceGridApi))
      this.invoiceGridApi.sizeColumnsToFit();
  }
  onCellClicked(event: any) { }// console.log('cell', event); }
  onGridSizeChanged(params) {
    if (document.getElementById("grid-wrapper") != null) {
      var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  onServiceGridSizeChanged(params) {
    if (document.getElementById("sergrid-wrapper") != null) {
      var gridWidth = document.getElementById("sergrid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  onInvoiceGridSizeChanged(params) {
    if (document.getElementById("ingrid-wrapper") != null) {
      var gridWidth = document.getElementById("ingrid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  search() {
   // console.log(this.jobDashBSearch);
   
    //if (!isUndefined(this.jobDashBSearch.status) && !this.isEmptyOrSpaces(this.jobDashBSearch.status)) {
    //  this.jobDashBSearch.status = this.jobDashBSearch.status.replace(" ", "");
    //}
    this.jobDashBSearch.isDefault = 0;
    let search = this.searchModel();
   // console.log(search);
    if (search.isDefault == 1) {
      this.displayError = true;
   //   this.showGrid = false;
      this.errorText = "Please select atleast one search criteria to see the results";
    } else if (search.isDefault == 2) {
      this.displayError = true;
    //  this.showGrid = false;
    }
    else {
      this.displayError = false;
      if (!isUndefined(this.gridApi))
        this.gridApi.paginationGoToFirstPage();
      this.dataservice.getJobsDashboardResults(search).subscribe(data => {
        this.jobsList = JSON.parse(JSON.stringify(data));
        this.jobsList = JSON.parse(JSON.stringify(data));
        this.jobsList.forEach((v) => {
         // console.log(v.daysRemaining);
          if (!isUndefined(v.daysRemaining) && v.daysRemaining != null && v.daysRemaining.toString() != "")
          v.daysRemaining = parseInt(v.daysRemaining.toString());
        });
        this.jobsList = _.orderBy(this.jobsList, i => i.daysRemaining, 'asc');
        if (!isUndefined(this.jobsList))
        this.showGrid = this.jobsList.length > 0 ? true: false;
        this.rowData = this.jobsList;
      });
    }
  }
  searchModel(): JobsDashboardSearch {
    let job = new JobsDashboardSearch();
  //  console.log(this.jobDashBSearch);
   // let obj = JSON.parse(JSON.stringify(this.jobDashBSearch));
    //console.log(obj);
    if (this.jobDashBSearch.lawFirm_ID != 0) {
      job.isDefault = 0;
      job.lawFirm_ID = this.jobDashBSearch.lawFirm_ID;
    } else {
      job.lawFirm_ID = 0;
    }
  
    if (parseInt(this.jobDashBSearch.priorityID.toString()) != 0) {
      // console.log("asdad");
      job.isDefault = 0;
      job.priorityID = parseInt(this.jobDashBSearch.priorityID.toString());
    } else {
      job.priorityID = 0;
    }
    if (this.jobDashBSearch.status.indexOf(" ") > 0) {
      job.status = this.jobDashBSearch.status.replace(" ", "");
      job.isDefault = 0;
    } else {
      job.isDefault = 0;
      job.status = this.jobDashBSearch.status;
    }
    if (job.status == "<< Any >>" || job.status == "<<Any>>" || job.status == "<<Any >>") {
      job.isDefault = 1;
      job.status = "";
    }
    if (this.jobDashBSearch.dateFilterType != 0) {
      job.isDefault = 0;
      job.dateFilterType = this.jobDashBSearch.dateFilterType;
    } else {
      job.isDefault = 1;
      job.dateFilterType = 0;
    }
    job.attorneyname = this.jobDashBSearch.attorneyname;
    job.def_name = this.jobDashBSearch.def_name;
    job.fromDate = this.jobDashBSearch.fromDate == "" ? null : this.jobDashBSearch.fromDate;
    job.toDate = this.jobDashBSearch.toDate == "" ? null : this.jobDashBSearch.toDate;
    job.jobID = this.jobDashBSearch.jobID == null ? 0 : this.jobDashBSearch.jobID;
  //  console.log(this.jobDashBSearch.dateFilterType);
    //  console.log(job.fromDate, job.toDate);
   
  
    if (job.lawFirm_ID == 0 && job.priorityID == 0 && job.dateFilterType == 0 && this.isEmptyOrSpaces(job.status)) {
      job.isDefault = 1;
      if (isUndefined(job.attorneyname) && isUndefined(job.def_name) && job.jobID == 0) {
        job.isDefault = 1;
      } else if (this.isEmptyOrSpaces(job.attorneyname) && this.isEmptyOrSpaces(job.def_name) && job.jobID == null) {
        job.isDefault = 1;
      }
      else {
        if (job.dateFilterType != 0) {
          if (this.jobDashBSearch.fromDate != "" && this.jobDashBSearch.fromDate != null && !isUndefined(this.jobDashBSearch.fromDate)
            && this.jobDashBSearch.toDate != "" && this.jobDashBSearch.toDate != null && !isUndefined(this.jobDashBSearch.toDate)) {
            if (new Date(this.jobDashBSearch.toDate) < new Date(this.jobDashBSearch.fromDate)) {
              job.isDefault = 2;
              job.dateFilterType = 0;
              this.errorText = "To date must be greaterthan or equal to from date ";
            } else {
              job.fromDate = this.jobDashBSearch.fromDate + " 00:00:00.000"
              job.toDate = this.jobDashBSearch.toDate + " 23:59:59.999";
            }
          } else {
            // console.log("sad");
            this.errorText = "Please select valid from and to dates";
            job.isDefault = 2;
            job.dateFilterType = 0;
            job.fromDate = null;
            job.toDate = null;
          }
        } else {
          job.isDefault = 0;
        }
      }
    } else {
      if (job.dateFilterType != 0) {
        if (this.jobDashBSearch.fromDate != "" && this.jobDashBSearch.fromDate != null && !isUndefined(this.jobDashBSearch.fromDate)
          && this.jobDashBSearch.toDate != "" && this.jobDashBSearch.toDate != null && !isUndefined(this.jobDashBSearch.toDate)) {
          if (new Date(this.jobDashBSearch.toDate) < new Date(this.jobDashBSearch.fromDate)) {
            job.isDefault = 2;
            job.dateFilterType = 0;
            this.errorText = "To date must be greaterthan or equal to from date ";
          } else {
            job.fromDate = this.jobDashBSearch.fromDate + " 00:00:00.000"
            job.toDate = this.jobDashBSearch.toDate + " 23:59:59.999";
          }
        } else {
          // console.log("sad");
          this.errorText = "Please select valid from and to dates";
          job.isDefault = 2;
          job.dateFilterType = 0;
          job.fromDate = null;
          job.toDate = null;
        }
      } else {
        job.isDefault = 0;
      }
    }
  
  //  console.log(job);
    return job;
  }
  isEmptyOrSpaces(str) {
    if (isUndefined(str)) {
      return true;
    }
    return str === null || str.match(/^ *$/) !== null;
  }
  toggleSearch() {
    if (this.showSearch) {
      this.showSearch = false;
      this.toggleButtonText = "Show search options";
    } else {
      this.showSearch = true;
      this.toggleButtonText = "Hide search options";
    }
  }
  jobServiceDetailsView(rowDetailsdata)
  {
   // console.log(rowDetailsdata.data)
    this.jobID = rowDetailsdata.data.jobID;
    this.headingTitle = "Back to Dashboard";
    this.titleInfo = "Service Details for Job ID: " + this.jobID ;
    
    this.invoiceDetails = false;
    this.aosDetails = false;
    this.dataservice.getJobSeriveDetails(rowDetailsdata.data.jobID).subscribe(data => {
      this.serviceDetails = true;
      
      this.jobsServiceList = JSON.parse(JSON.stringify(data));
      this.jobServiceDetailsModel = this.jobsServiceList.find(i => i.document_Purpose == "Affidavit" || i.document_Purpose == "NonServeAffidavit") == undefined ?
        this.jobsServiceList[0] : this.jobsServiceList.find(i => i.document_Purpose == "Affidavit" || i.document_Purpose == "NonServeAffidavit") ;
      if (!isUndefined(this.jobServiceDetailsModel)) {
        this.jobServiceDetailsModel.status = rowDetailsdata.data.status;
        this.jobServiceDetailsModel.courtName = this.jobServiceDetailsModel.courtName.trim();
        this.rowData1 = this.jobsServiceList;
        if (typeof (this.jobServiceDetailsModel.contents_Filepath) != 'undefined' && this.jobServiceDetailsModel.contents_Filepath &&
          (this.jobServiceDetailsModel.document_Purpose == "Affidavit" || this.jobServiceDetailsModel.document_Purpose == "NonServeAffidavit")) {
          this.nH_jobserviceAOS = true;
          this.aosStatus = "Filed";
        } else if (typeof (this.jobServiceDetailsModel.contents_Filepath) != 'undefined' && this.jobServiceDetailsModel.contents_Filepath && this.jobServiceDetailsModel.status == "Completed") {
          this.aosStatus = "Not Available";
          this.nH_jobserviceAOS = true;
        } else {
          this.nH_jobserviceAOS = false;
        }
        if (typeof (this.jobServiceDetailsModel.wOserviceInvoice_ID) != 'undefined' && this.jobServiceDetailsModel.wOserviceInvoice_ID) {
          this.nH_jobserviceInvoice = true;
        } else {
          this.nH_jobserviceInvoice = false;
        }

         //o	In Progress - processed, but not yet served
         //o	Served - AOS not received by N & H
         //o	Completed - served and AOS received
         //o	Not Served ? Failed ? - when service is not possible
        //o	Invoiced - AOS received and invoiced
        this.serviceStatus = this.jobServiceDetailsModel.status;

      }
      
    });

  }
  viewDashBoard() {
    if (this.headingTitle == "Back to Dashboard")
      this.nH_dashboard = true;
    else if (this.headingTitle == "Back to Service Details") {
      this.headingTitle = "Back to Dashboard";
      this.invoiceDetails = false;
      this.serviceDetails = true;
      this.aosDetails = false;
    }
  }
  searchClick(event) {
   // console.log(event);
    if (event.key == "Enter") {
      this.search();
    }
  }
  downloadPdf(): void {
    window.open(this.jobServiceDetailsModel.contents_Filepath);
  }
  @ViewChild('viewDetailsPopup') viewDetail: PopupComponent;
  viewDetails(model: JobServiceDetailsModel) {
    this.popupService.open(PdfviewerComponent, { data: { message: model }, pageName: "jobdetails", vH: 55, vW: 74 });
  }
  openPDF(): void {
    try {
      this.headingTitle = "Back to Service Details";
      this.aosDetails = true;
      this.serviceDetails = false;
      this.invoiceDetails = false;
      this.titleInfo = "AOS Details for Job ID: " + this.jobID;
      this.dataservice.openPDFFile(this.jobServiceDetailsModel).subscribe(data => {
        document.getElementById('pdfData').setAttribute('type', 'application/pdf');
        document.getElementById('pdfData').setAttribute('data', 'data:application/pdf;base64,' + data.filePath);
      });
      /*
      this.viewDetails(this.jobServiceDetailsModel); Will open a popup and shows the PDF file 
   */
    }
    catch (err) {

    }
  }
  viewInvoiceDetails() {
    this.headingTitle = "Back to Service Details";
    this.titleInfo = "Invoice Details for Job ID: " + this.jobID;
    this.dataservice.getJobInvoiceDetails(this.jobServiceDetailsModel.woService_ID).subscribe(data => {
      this.invoiceDetails = true;
      this.aosDetails = false;
      this.jobInvoiceDetails = JSON.parse(JSON.stringify(data));
      if (!isUndefined(this.jobInvoiceDetails)) {
        let total = 0;
        this.jobInvoiceDetails.forEach((item) => {
         // console.log(item);
          total += item.line_Amount;
        });
        this.totalAmout = total;
      }
      this.InvoiceRowData = this.jobInvoiceDetails;
    });
  }
  onGridReady1(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }
  onInvoiceGridReady(params) {
    this.invoiceGridApi = params.api;
    this.invoiceGridColumnApi = params.columnApi;
    this.sizeToFit();
  }

}
