
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { WorkAreaView } from 'src/app/Core/Models/WorkArea/WorkAreaModel';
import { Router } from '@angular/router';
import { SignalRService } from 'src/app/Core/Services/SignalR/signal-r.service';
import { DataService } from 'src/app/Core/Services/data.service';
import { PopupService } from 'src/app/Shared/Popup/popup.service';
import { PopupComponent } from 'src/app/Shared/Popup/popup.component';
import { DefendantModel } from 'src/app/Core/Models/WorkArea/DefendantModel';
import { WorkareadetailsComponent } from '../../workareadetails/workareadetails.component';
import { isUndefined } from 'util';
import { WorkAreaBatchModel } from 'src/app/Core/Models/WorkArea/WorkAreaBatchModel';

@Component({
  selector: 'nh-view-all-completed-ts',
  templateUrl: './view-all-completed-ts.component.html',
  styleUrls: ['./view-all-completed-ts.component.less']
})

/** ViewAllCompleted.ts component*/
export class ViewAllCompletedTsComponent implements OnDestroy {

  ComponentName = "View all completed";
  workareaBatch: WorkAreaBatchModel;
  workAreaDashBoard: Array<WorkAreaView> = new Array<WorkAreaView>();
  temp: WorkAreaBatchModel;
  router: Router;
  showNoRecords: boolean = false;

  /** ViewAllCompleted.ts ctor */
  constructor(private route: Router,
              private workAreaService: DataService,
              private popupService: PopupService,
              private signalR: SignalRService) {
    try {
      var _this = this;
                  this.workAreaService.getProcessedWorkArea().subscribe(response => {
                    this.workareaBatch = response;
                    this.workAreaDashBoard = this.workareaBatch.workStatusInProgress;
                    if (!isUndefined(this.workAreaDashBoard)) {
                      if (this.workAreaDashBoard.length == 0)
                        this.showNoRecords = true;
                      this.workAreaDashBoard.forEach(function (value) {
                        if (value.defStatSucceded != undefined && value.defStatSucceded.length>0) {
                          value.defStatSucceded[0].isActive = true;
                        }
                        value.defStatSucceded.forEach(function (b) {
                          b.def_trimmedName = _this.workAreaService.wrapText(b.def_name);
                        });
                        value.showDefendants = false;
                        value.displayDefendant = "none";
                      });
                      this.temp = JSON.parse(JSON.stringify(this.workareaBatch));
                      // console.log(this.temp);
                    }
                    //this.temp = JSON.parse(JSON.stringify(this.workAreaDashBoard));
                  });
                 // document.getElementsByClassName("wrapper background")[0].setAttribute("viewallcompleted", "viewallcompleted");
                } catch (e) {
                  console.log(e);
                }
  }

  ngOnDestroy() {
    try {
    //  document.getElementsByClassName("wrapper background")[0].removeAttribute("viewallcompleted");

    } catch (e) {

    }
  }

  @ViewChild('viewDetailsPopup') viewDetail: PopupComponent;
  viewDetails(model: DefendantModel) {
    this.popupService.open(WorkareadetailsComponent, { data: { message: model }, pageName: "viewCompleted", list: this.workAreaDashBoard.find(i => i.lawFirm_ID == model.lawFirm_ID).defStatSucceded, vH: 55, vW: 86 });
  }

  makeActive(workArea: WorkAreaView, model: DefendantModel) {

    try {
      if (model.isActive) {
        model.isActive = false;
      } else {
        workArea.defStatSucceded.forEach(function (value) {
          value.isActive = false;
        });
        model.isActive = true;

      }
    } catch (e) {

    }

  }

  searchValues(data: any) {

    try {
      if (!isUndefined(data)) {
        if (data == 'reset') {
          this.showNoRecords = false;
          //console.log('return search....', data);
          this.workAreaDashBoard = this.temp.workStatusInProgress;
        } else if (data == 'expand all') {
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = true;
            value.displayDefendant = "flex";
          });
        } else if (data == 'collapse all') {
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = false;
            value.displayDefendant = "none";
          });
        } else {
          let count = this.workAreaDashBoard.length;
          // console.log('return search....', data);
          this.workAreaDashBoard = data;
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = true;
            value.displayDefendant = "flex";
            if (value.defStatSucceded.length == 0)
              count = count - 1;
          });
          if (count <= 0)
            this.showNoRecords = true
          else
            this.showNoRecords = false;
        }
      }
    } catch (e) {
      console.log(e);
    }

  }

  showDefendants(workArea: WorkAreaView) {
    try {
      if (workArea.showDefendants || workArea.displayDefendant == "flex") {
        workArea.displayDefendant = "none";
        workArea.showDefendants = false;
      } else {

        this.workareaBatch.workStatusInProgress.forEach(function (value) {
          if (value.showDefendants) {
            value.displayDefendant = "none";
            value.showDefendants = false;
          }
        });
        workArea.displayDefendant = "flex";
        workArea.showDefendants = true;

      }
    } catch (e) {
      console.log(e);
    }
  }

}

