import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PopupConfig } from '../Popup/popup-config';
import { PopupRef } from '../Popup/popup-ref';
import { DataService } from 'src/app/Core/Services/data.service';

@Component({
  selector: 'nh-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.less']
})
export class AlertComponent implements OnInit {
  data: any;
  dataService: DataService;
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Output() eventClicked = new EventEmitter<any>();
  constructor(private popupConfig: PopupConfig, private popup: PopupRef, private ds: DataService) {
    this.dataService = ds;
    this.data = this.popupConfig.data;
  }

  ngOnInit() {
    
    document.getElementById("popupBox").style.maxHeight = "30vh";
    document.getElementById("popupBox").style.minHeight = "30vh";
    document.getElementById("popupBox").style.maxWidth = "30vw";
    document.getElementById("header").style.display = "none";
  }
  //public decline() {
  //  this.activeModal.close(false);
  //}

  //public accept() {
  //  this.activeModal.close(true);
  //}

  //public dismiss() {
  //  this.activeModal.dismiss();
  //}
  onClick(event: any): void {
    console.log(event);
    this.eventClicked.emit(event);
  }
}
