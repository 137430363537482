export class LoginUser {
  Id: number;
  Username: string;
  Password: string;
  FirstName: string;
  LastName: string;
  role: string;
  token?: string;
  RememberMe: boolean;
  message: string;
}
