export class AdminModel {
  serialNumber: number;
  lawFirm_Name: string;
  outgoingxmlpath: string;
  jobID: string;
  def_Name: string;
  recUpdateDate: string;
  recCreateDate: string;
  firstName: string;
  middleName: string;
  lastName: string;
  orderNumber: string;
  inputXMLfile: string;
  receivedDate: string;
  fullName: string;
  caseNumber: string;
  processStatus: string;
}
export class AdminSelectionList {
  name: string;
  id: number;
}
export class SearchModel {
  searchString: string;
  searchType: number;
  userID: number;
}
