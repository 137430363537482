
import {  Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
//import * as XLSX from 'xlsx';
//import { WorkSheet as XLSXWorkSheet, utils as XLSXUtils, WorkBook as XLSXWorkBook, write as StyleWrite, utils as StyleUtils, CellObject as StyleCellObject } from 'xlsx-style';
//import { ExcelConfig } from "./excel.config";
import * as Excel from "exceljs/dist/exceljs.min.js";
//import * as ExcelProper from "exceljs";
//import { DOCUMENT } from '@angular/common';
import { ExcelConfig } from './excelconfig';
import * as _ from 'lodash';
import { isUndefined } from 'util';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  workBook:any;
  worksheet :any;

  constructor() {
    //console.log('lopaded');
   
  }
  
  public exportAsExcelFile(json: any, fileName: string,header:any): void {
    this.workBook = new Excel.Workbook();
    this.worksheet = this.workBook.addWorksheet("sheet", {
      views: [
        { state: 'frozen', ySplit: 1 }
      ]
    });
    
    let grid = this.getdata(json, header.length);
    let headerCell = this.worksheet.addRow(header);
    headerCell.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
        bgColor: { argb: 'FFFFFFFF' }
      }
      cell.font = { bold: true }
      cell.frozen
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    if (!isUndefined(grid)) {
      grid.forEach(d => {
        let row = this.worksheet.addRow(d);
        row.eachCell((cell, numb) => {
          cell.font = { bold: false }
        })
      });
    }
    this.autoSize(this.worksheet,0);
    this.workBook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ExcelConfig.EXCEL_TYPE });
      let currentMonth = "";
      let currentDate = "";
      if (new Date().getMonth() < 10) {
        let month = new Date().getMonth() + 1;
        currentMonth = "0" + month;
      } else {
        let month = new Date().getMonth() + 1;
        currentMonth = month.toString();
      }
      if (new Date().getDate() < 10) {
       
        currentDate = "0" + new Date().getDate();
      } else {
        currentDate = new Date().getDate().toString();
      }
      FileSaver.saveAs(blob, fileName + '_export_' + currentDate + currentMonth + new Date().getFullYear().toString().substr(-2) + ExcelConfig.EXCEL_EXTENSION);
    })
  }


  private openExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    //  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    let currentMonth = "";
    let currentDate = "";
    if (new Date().getMonth() < 10) {
      let month = new Date().getMonth() + 1;
      currentMonth = "0" + month;
    } else {
      let month = new Date().getMonth() + 1;
      currentMonth = month.toString();
    }
    if (new Date().getDate() < 10) {

      currentDate = "0" + new Date().getDate();
    } else {
      currentDate = new Date().getDate().toString();
    }
    FileSaver.saveAs(data, fileName + '_export_' + currentDate + currentMonth + new Date().getFullYear().toString().substr(-2) + EXCEL_EXTENSION);
    //IE
    /* if (window.navigator && window.navigator.msSaveOrOpenBlob) {
       window.navigator.msSaveOrOpenBlob(data, this.getFileName(fileName));
     }
     //Chrome
     else {
       const url = window.URL.createObjectURL(data);
       const a = this.document.createElement("a");
       this.document.body.appendChild(a);
       a.href = url;
       a.download = this.getFileName(fileName);
       a.click();
       window.URL.revokeObjectURL(url);
       this.document.body.removeChild(a);
     }*/
  }

  private getFileName(fileName: string): string {
    const today: Date = new Date();
    const month = today.getMonth() + 1;
    return fileName + '_' + today.getFullYear() + '_' +
      month + '_' + today.getDate() + '_' + Date.now() + ExcelConfig.EXCEL_EXTENSION;
  }

  getdata(json: any, len: number) {
    if (!isUndefined(json)) {
      let arrayList = json.map(Object.values);
      for (var i = 0; i < arrayList.length; i++) {
        //Removing values from array because we do not need to show them in excel
        arrayList[i].length = len;
      }
      return arrayList;
    } else {

    }
  }




  autoSize(sheet: Excel.Worksheet, fromRow: number) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }

    const maxColumnLengths: Array<number> = []
    this.worksheet.eachRow((row, rowNum) => {
    if (rowNum < fromRow) {
      return
    }

    row.eachCell((cell, num) => {
      if (typeof cell.value === 'string') {
        if (maxColumnLengths[num] === undefined) {
          maxColumnLengths[num] = 0
        }

        const fontSize = cell.font && cell.font.size ? cell.font.size : 11
        ctx.font = `${fontSize}pt Arial`
        const metrics = ctx.measureText(cell.value)
        const cellWidth = metrics.width + 5;

        maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth)
      }
    })
  })

    for (let i = 1; i <= this.worksheet.columnCount; i++) {
    const col = sheet.getColumn(i)
    const width = maxColumnLengths[i]
    if (width) {
      col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1
    }
  }
  return sheet;
}
}
