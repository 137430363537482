import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, concat } from 'rxjs/operators';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { DataService } from '../../Core/Services/data.service';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
import { NavigationModel, nestedNav } from 'src/app/Core/Models/Navigation/NavigationModel';
import { isUndefined } from 'util';

@Component({
  selector: 'nh-authentication',
  templateUrl: './authentication.component.html'
})

export class AuthenticationComponent implements OnInit {

  public register: boolean = false;
  public username: string = "";
  public password: string = "";
  public error: string;
  // used to store short cut keys
  public keys: Array<any> = new Array();
  public token: any;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  registerForm: FormGroup;
  registerLoading = false;
  registerSubmitted = false;
  registerSuccess = false;
  isProd: boolean = false;

  validationmsgs = {
    "username": { "required": "Username is required" },
    "password": {
      "required": "Password is required",
      "minlength": "Minimum length 8 chars",
      "maxlength": "Maximum 16 characters long",
      "onenumber": " password must have atleast atleast 1 number between 0 - 9",
      "uppercasealphabet": " password must have atleast 1 uppercase letter ",
      "match": "Passwords do not match, please re-enter password"
    },
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticateService,
    private ds: DataService,
    private cookieService: CookieService
  ) {

    // redirect to home if already logged in
    //if (this.authenticationService.IsAuthenticated) {//&& (!this.authenticationService.IsTokenExpired)
    //  this.router.navigate(['/']);
    //}

    if (this.authenticationService.getLoggedInUser()) {
      let userRoles = new Array<string>();
      (this.authenticationService.getLoggedInUser().roles.split(',').length > 0) ?
        (userRoles = this.authenticationService.getLoggedInUser().roles.split(','))
        : "";

      let aa = userRoles.find(i => i.indexOf("Admin") > 0);
      if (aa != "" && aa != undefined) {
        this.ds.userRoles = new Array<string>();
        this.ds.userRoles.push("Roles");
        // this.router.navigate(['/ksadmin'])
      }
      this.router.navigate(['']);
    }

  }

  ngOnInit() {

    if (environment.production) {
      this.isProd = true;
    }

    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
      rememberme: [false, []]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // navigate to the windows running service on short cut.
  doSomething($event) {

    if ($event.key == "Control") {
      if (this.keys.length == 0) {
        this.keys.push($event);
      }
    } else if ($event.key == "y") {
      if (this.keys.length == 1) {
        this.keys.push($event);
      }
    }

    if (this.keys.length == 2) {
      this.router.navigate(["services-status"]);
      this.keys = new Array();
    }
  }

  toggleRegister() {
    // navigate to register component
    this.router.navigate(['register']);
  }

  regMatchNumber(value) {
    return value.mathc(/\d+/g);
  }

  get f() { return this.loginForm.controls; }

  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value, this.f.rememberme.value)
      .pipe(first())
      .subscribe(
        (result) => {
          if (result.id != undefined) {
            sessionStorage.setItem("currentUser", JSON.stringify(result));
            //this.cookieService.set("currentUser", JSON.stringify(result), moment().add(4, 's').toDate());
            this.cookieService.set("currentUser", JSON.stringify(result), moment().add(4, 'hours').toDate());

            if (result.rememberMe) {
              this.cookieService.set("currentUser", JSON.stringify(result), moment().add(14, "days").toDate());
              localStorage.setItem("currentUser", JSON.stringify(result));
            }
            let userRoles = new Array<string>();
            (this.authenticationService.getLoggedInUser().roles.split(',').length > 0) ?
              (userRoles = this.authenticationService.getLoggedInUser().roles.split(','))
              : "";

            let aa = userRoles.find(i => i.indexOf("Admin") > 0);
            if (aa != "" && aa != undefined) {
              this.ds.userRoles = new Array<string>();
              this.ds.userRoles.push("Admin");
              // this.router.navigate(['/ksadmin'])
            }
            else {
              this.ds.userRoles = new Array<string>();
            }
            if (userRoles.findIndex(i => i == Roles.KSAdmin) >= 0) {
              //this.ds.IsAdminPage = true;
              this.router.navigate(['/admin']);
            } else if (userRoles.findIndex(i => i == Roles.ClientAdmin) >= 0 || userRoles.findIndex(i => i == Roles.ClientStaff) >= 0) {
              this.router.navigate(['/ridersummary']);
            } else {
              this.router.navigate(['']);
            }
            this.ds.userRoleName = userRoles;
            this.ds.createNavigationMenu(userRoles);
          } else {
            if (result.message != null && result.message != "")
              this.error = result.message;
          }
        },
        err => this.error = 'Could not authenticate'
      );

  }
  forgotPassword() {
    this.router.navigate(['forgotpasswordEmail']);
  }
}

