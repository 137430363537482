import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Modules/home/home.component';
import { AuthenticationComponent } from './Modules/authentication/authentication.component';
import { RegistrationPageComponent } from './Modules/authentication/ResetPassword/registrationpage.component';
import { RegistrationComponent } from './Modules/authentication/Registration/registration.component';
import { ForgotPasswordComponent } from './Modules/authentication/ForgotPassword/forgot-password.component';
import { ResetPasswordComponent } from './Modules/authentication/ResetPassword/ResetPassword.component';
import { DashboardComponent } from './Modules/dashboard/dashboard.component';
import { WorkareaComponent } from './Modules/workarea/workarea.component';
import { IncominglogComponent } from './Modules/incominglog/incominglog.component';
import { XmlValidationComponent } from './Modules/xmlValidation/xml-validation.component';
import { AuthGuard } from './Core/Services/AuthGurd/auth.guard';
import { OutgoinglogComponent } from './Modules/outgoinglog/outgoinglog.component';
import { ViewAllCompletedTsComponent } from './Modules/workarea/completed/view-all-completed-ts.component';
import { CreateJobsComponent } from './Modules/CreateJobs/CreateJobs.component';
import { FromExcelComponent } from './Modules/CreateJobs/FromExcel/FromExcel.component';
import { FromFormComponent } from './Modules/CreateJobs/FromForm/FromForm.component';
import { ServicesComponent } from "./Modules/WindowsServices/ServicesComponent";
import { FormDBoxExcelComponent } from './Modules/CreateJobs/form-dbox-excel/form-dbox-excel.component';
import { RoleGuardService } from './Core/Services/AuthGurd/role-guard.service';
import { NotfoundComponent } from './Modules/notfound/notfound.component';
import { Roles } from './Core/Models/Admin/NHAdmin/role';
import { AdminModule } from './Modules/admin/admin.module';
import { NhadminModule } from './Modules/nhadmin/nhadmin.module';
import { ClientsModule } from './Modules/clients/clients.module';


const routes: Routes = [
  //{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'incoming-log', component: IncominglogComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff }  },
  { path: '', component: WorkareaComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: 'validate-xml', component: XmlValidationComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: 'authentication', component: AuthenticationComponent },
  { path: 'registrationpage', component: RegistrationPageComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: ':token/resetPassword', component: ResetPasswordComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'outgoing-log', component: OutgoinglogComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff }  },
  { path: 'view-all-completed', component: ViewAllCompletedTsComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff }  },
  //{ path: 'create-jobs', component: CreateJobsComponent, canActivate: [AuthGuard] },
  { path: 'create-jobs', component: FromExcelComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: 'from-form', component: FromFormComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff }  },
  { path: 'services-status', component: ServicesComponent, canActivate: [AuthGuard], data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: 'from-excel', component: FormDBoxExcelComponent, data: { role: Roles.KSAdmin + "," + Roles.NHAdmin + "," + Roles.NHStaff } },
  { path: '404', component: NotfoundComponent },
  { path: 'admin', loadChildren:() => AdminModule },
  { path: 'nhregisteruser', loadChildren:()=> NhadminModule },
  { path: 'nhresetuser', loadChildren: () => NhadminModule },
  { path: 'nhclientlist', loadChildren: () => NhadminModule},
  { path: 'nhpendingservices', loadChildren: () => NhadminModule},
  { path: 'nhusers', loadChildren: () => NhadminModule },
  { path: 'ridersummary', loadChildren: () => ClientsModule }
];
export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);
