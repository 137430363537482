import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupConfig } from 'src/app/Shared/Popup/popup-config';
import { PopupRef } from 'src/app/Shared/Popup/popup-ref';
import { DataService } from 'src/app/Core/Services/data.service';
import { GridOptions } from 'ag-grid-community';
import { forEach } from '@angular/router/src/utils/collection';
@Component({
  selector: 'nh-dropbox-details',
  templateUrl: './dropbox-details.component.html',
  styleUrls: ['./dropbox-details.component.less']
})
export class DropboxDetailsComponent implements OnInit {
  private gridOptions: GridOptions;
  public FolderList: any[];
  public FolderNameddl: string;
  public PlaintiffModel: any[];
  public DefModel: any[];
  public rowData: any[];
  public rowData1: any[];
  private columnDefs: any[];
  private columnDefs1: any[];
  overlayNoRowsTemplate: string;
  private gridApi1;
  private SwapDatabtn: boolean = false;
  private gridColumnApi1;
  private gridApi;
  private gridColumnApi;
  private rowSelection1;
  private rowSelection;
  private isRowSelectable1;
  private context;
  private popupOverPopupAlert: boolean = false;
  private frameworkComponents;
  private FilterTypeRdx: boolean = true;
  private TtlData;
  private ptfcnt: number;
  private defcnt: number;
  private ByDef: boolean = false;
  private ByPlaintiff: boolean = false;
  constructor(private popupConfig: PopupConfig, private popup: PopupRef, private ds: DataService) {
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
  }
  ngOnInit() {
    this.FolderList = this.popupConfig.data.Result.folderlist;
    this.DefModel = this.popupConfig.data.Result.defList;
    this.PlaintiffModel = this.popupConfig.data.Result.plaintiffList;
    this.popupConfig.data;
    this.columnDefs = [
      { headerName: 'DefendantId', field: 'def_ID', tooltipField: 'DefendantId', filter: true, hide: true },
      { headerName: 'plaintiffId', field: 'plaintiffId', tooltipField: 'plaintiffId', filter: true, hide: true },
      { headerName: 'Plaintiff Name', field: 'plaintiffName', width: 110, tooltipField: 'plaintiffName', pinned: true, filter: true, headerCheckboxSelection: this.isFirstColumn, checkboxSelection: true, sortable: true, resizable: true, },
      { headerName: 'Def Name', field: 'defendantName', width: 110, tooltipField: 'defendantName', pinned: true, filter: true, sortable: true, resizable: true, },
      { headerName: 'FileName1', field: 'documentFileName1', tooltipField: 'documentFileName1', filter: true, editable: false, sortable: true, resizable: true, cellRenderer: params => { return this.createCheckBox(params); } },
      { headerName: 'pagecount1', field: 'pagecount1', width: 110, tooltipField: 'pagecount1', filter: true, editable: false, sortable: true, resizable: true, type: "numericColumn", },
      { headerName: 'FileName2', field: 'documentFileName2', tooltipField: 'documentFileName2', filter: true, editable: false, sortable: true, resizable: true, cellRenderer: params => { return this.createCheckBox(params); } },
      { headerName: 'pagecount2', field: 'pagecount2', width: 110, tooltipField: 'pagecount2', type: "numericColumn", filter: true, editable: false, sortable: true, resizable: true, },
      { headerName: 'FileName3', field: 'documentFileName3', tooltipField: 'documentFileName3', filter: true, editable: false, sortable: true, resizable: true, cellRenderer: params => { return this.createCheckBox(params); } },
      { headerName: 'pagecount3', field: 'pagecount3', width: 110, tooltipField: 'pagecount3', type: "numericColumn", filter: true, editable: false, sortable: true, resizable: true, },
      { headerName: 'FileName4', field: 'documentFileName4', tooltipField: 'documentFileName4', filter: true, editable: false, sortable: true, resizable: true, cellRenderer: params => { return this.createCheckBox(params); } },
      { headerName: 'pagecount4', field: 'pagecount4', width: 110, tooltipField: 'pagecount4', type: "numericColumn", filter: true, editable: false, sortable: true, resizable: true, },
      { headerName: 'FileName5', field: 'documentFileName5', tooltipField: 'documentFileName5', filter: true, editable: false, sortable: true, resizable: true, cellRenderer: params => { return this.createCheckBox(params); } },
      { headerName: 'pagecount5', field: 'pagecount5', width: 110, tooltipField: 'pagecount5', type: "numericColumn", filter: true, editable: false, sortable: true, resizable: true, },
    ];
    this.rowData = this.popupConfig.data.Result.lawFirmModels;
    this.rowSelection = "multiple";
    this.columnDefs1 = [
      {
        suppressRowClickSelection: true,
        checkboxSelection: this.isFirstColumn,
        headerName: 'Path', field: 'path', width: 390, sortable: true, resizable: true,
        tooltipField: 'path', headerCheckboxSelection: this.isFirstColumn,
      }
      , { headerName: 'pagecount', field: 'pagecount', width: 100, filter: true, editable: true, sortable: true, resizable: true, type: "numericColumn", valueParser: this.numberParser },
    ];
    this.context = { componentParent: this };
    this.rowSelection1 = "multiple";
    this.rowData1 = this.popupConfig.data.Result.dbxDataList;
    ;
  }
  numberParser(params) {
    var newValue = params.newValue;
    var valueAsNumber;
    if (newValue === null || newValue === undefined || newValue === '') {
      valueAsNumber = null;
    } else {
      valueAsNumber = parseFloat(params.newValue);
    }
    return isNaN(valueAsNumber) ? 0 : valueAsNumber;
  }
  createCheckBox(params) {
    var _this = this;
    try {
      var DivItem = document.createElement("div");
      var input = document.createElement('input');
      input.type = "checkbox";
      input.checked = params.value;
      var input1 = document.createElement('span');
      input1.setAttribute("ref", "eCellValue");
      input1.className = "ag-cell-value";
      input1.innerHTML = params.value;
      DivItem.appendChild(input);
      DivItem.appendChild(input1);
      input.addEventListener('click', function () {
        var Selectedlength = _this.gridApi.getSelectedNodes().length;
        if (Selectedlength > 0) {
          var selRows = _this.gridApi.getSelectedRows();
          var Available = false;
          selRows.forEach((item1, index1) => {
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID) {
              Available = true;
            }
          });
          if (!Available) {
            input.checked = input.checked == true ? false : true;
            window.alert("Please select row before editing the data.");
            _this.OnGridRefresh();
            return false;
          }
        }
        else {
          input.checked = input.checked == true ? false : true;
          window.alert("Please select atleast one row to Proceed.");
          _this.OnGridRefresh();
          return false;
        }
        _this.gridApi1.clearFocusedCell();
        if (input.checked) {
          var selectedRows = _this.gridApi.getSelectedRows();
          input1.innerHTML = params.value;
          params.value = params.value;
          selectedRows.forEach((item1, index1) => {
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName1") {
              selectedRows[index1].documentFileName1 = params.value;
              selectedRows[index1].pagecount1 = params.value == "" ? 0 : selectedRows[index1].pagecount1;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName2") {
              selectedRows[index1].documentFileName2 = params.value;
              selectedRows[index1].pagecount2 = params.value == "" ? 0 : selectedRows[index1].pagecount2;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName3") {
              selectedRows[index1].documentFileName3 = params.value;
              selectedRows[index1].pagecount3 = params.value == "" ? 0 : selectedRows[index1].pagecount3;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName4") {
              selectedRows[index1].documentFileName4 = params.value;
              selectedRows[index1].pagecount4 = params.value == "" ? 0 : selectedRows[index1].pagecount4;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName5") {
              selectedRows[index1].documentFileName5 = params.value;
              selectedRows[index1].pagecount5 = params.value == "" ? 0 : selectedRows[index1].pagecount5;
            }
          });
          var rowData = _this.rowData;
          rowData.forEach((i1, inx1) => {
            selectedRows.forEach((i, inx) => {
              if (i1.def_ID === i.def_ID && i1.plaintiffId == i.plaintiffId) {
                rowData[inx1].documentFileName1 = selectedRows[inx].documentFileName1;
                rowData[inx1].documentFileName2 = selectedRows[inx].documentFileName2;
                rowData[inx1].documentFileName3 = selectedRows[inx].documentFileName3;
                rowData[inx1].documentFileName4 = selectedRows[inx].documentFileName4;
                rowData[inx1].documentFileName5 = selectedRows[inx].documentFileName5;
              }
            });
          });
          _this.TtlData = _this.popupConfig.data.Result.lawFirmModels;
          _this.TtlData.forEach((i1, inx1) => {
            selectedRows.forEach((i, inx) => {
              if (i1.def_ID === i.def_ID && i1.plaintiffId == i.plaintiffId) {
                _this.TtlData[inx1].documentFileName1 = selectedRows[inx].documentFileName1;
                _this.TtlData[inx1].documentFileName2 = selectedRows[inx].documentFileName2;
                _this.TtlData[inx1].documentFileName3 = selectedRows[inx].documentFileName3;
                _this.TtlData[inx1].documentFileName4 = selectedRows[inx].documentFileName4;
                _this.TtlData[inx1].documentFileName5 = selectedRows[inx].documentFileName5;
              }
            });
          });
          _this.popupConfig.data.Result.lawFirmModels = _this.TtlData;
          _this.OnGridRefresh();
        }
        else {
          input1.innerHTML = "";
          params.value = "";
          var selectedRows = _this.gridApi.getSelectedRows();
          selectedRows.forEach((item1, index1) => {
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName1") {
              selectedRows[index1].documentFileName1 = params.value;
              selectedRows[index1].pagecount1 = params.value == "" ? 0 : selectedRows[index1].pagecount1;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName2") {
              selectedRows[index1].documentFileName2 = params.value;
              selectedRows[index1].pagecount2 = params.value == "" ? 0 : selectedRows[index1].pagecount2;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName3") {
              selectedRows[index1].documentFileName3 = params.value;
              selectedRows[index1].pagecount3 = params.value == "" ? 0 : selectedRows[index1].pagecount3;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName4") {
              selectedRows[index1].documentFileName4 = params.value;
              selectedRows[index1].pagecount4 = params.value == "" ? 0 : selectedRows[index1].pagecount4;
            }
            if (params.data.plaintiffId == item1.plaintiffId && params.data.def_ID == item1.def_ID && params.colDef.field == "documentFileName5") {
              selectedRows[index1].documentFileName5 = params.value;
              selectedRows[index1].pagecount5 = params.value == "" ? 0 : selectedRows[index1].pagecount5;
            }
          });
          var rowData = _this.rowData;
          rowData.forEach((i1, inx1) => {
            selectedRows.forEach((i, inx) => {
              if (i1.def_ID === i.def_ID && i1.plaintiffId == i.plaintiffId) {
                rowData[inx1].documentFileName1 = selectedRows[inx].documentFileName1;
                rowData[inx1].documentFileName2 = selectedRows[inx].documentFileName2;
                rowData[inx1].documentFileName3 = selectedRows[inx].documentFileName3;
                rowData[inx1].documentFileName4 = selectedRows[inx].documentFileName4;
                rowData[inx1].documentFileName5 = selectedRows[inx].documentFileName5;
              }
            });
          });
          _this.TtlData = _this.popupConfig.data.Result.lawFirmModels;
          _this.TtlData.forEach((i1, inx1) => {
            selectedRows.forEach((i, inx) => {
              if (i1.def_ID === i.def_ID && i1.plaintiffId == i.plaintiffId) {
                _this.TtlData[inx1].documentFileName1 = selectedRows[inx].documentFileName1;
                _this.TtlData[inx1].documentFileName2 = selectedRows[inx].documentFileName2;
                _this.TtlData[inx1].documentFileName3 = selectedRows[inx].documentFileName3;
                _this.TtlData[inx1].documentFileName4 = selectedRows[inx].documentFileName4;
                _this.TtlData[inx1].documentFileName5 = selectedRows[inx].documentFileName5;
              }
            });
          });
          _this.popupConfig.data.Result.lawFirmModels = _this.TtlData;
          //setTimeout(() => {
          _this.OnGridRefresh();
          //}, 10);
        }
      });
      return DivItem;
    }
    catch (err) {
    }
  }
  onCheckboxeventtriggered() {
    this.OnGridRefresh();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.OnGridRefresh();
  }
  onalert1(params) {
  }
  onRowSelected1(event) {
  }
  radioChange(event: number) {
    this.OnGridRefresh();
    if (this.FilterTypeRdx == false) {
      this.onPlaintiffFilter(null, 2);
    }
    if (this.FilterTypeRdx == true) {
      this.onDefFilter(null, 2);
    }
  }
  onPlaintiffFilter(event, num: number): void {
    if (this.FilterTypeRdx == false) {
      var Filterval = num == 1 ? event.target.value : num == 2 ? "0" : (<HTMLSelectElement>document.getElementById('pltiffNameddl')).value;
      this.rowData = this.popupConfig.data.Result.lawFirmModels;
      if (Filterval == "0")
        this.rowData = this.rowData;
      else
        this.rowData = this.rowData.filter((c) => c.plaintiffId.toString() === event.target.value);
    }
    this.OnGridRefresh();
  }
  onDefFilter(event, num: number): void {
    if (this.FilterTypeRdx == true) {
      var Filterval = num == 0 ? event.target.value : num == 2 ? "0" : (<HTMLSelectElement>document.getElementById('defNameddl')).value;
      this.rowData = this.popupConfig.data.Result.lawFirmModels;
      if (Filterval == "0")
        this.rowData = this.rowData;
      else
        this.rowData = this.rowData.filter((c) => c.def_ID.toString() === Filterval);
    }
    this.OnGridRefresh();
  }
  onSelectionChanged1(event) {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 5) {
      this.gridApi1.deselectAll();
      this.gridApi1.refreshCells();
      // document.getElementById('dropboxAlertPopup').style.display = 'block';
      window.alert("Please select 5 or fewer Docuemnts to Proceed.");
    }
    else {
    }
  }
  dropboxcloseAlert() {
    this.popupOverPopupAlert = true;
    document.getElementById('dropboxAlertPopup').style.display = 'none';
  }
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  onQuickFilterChanged() {
    this.gridApi1.setQuickFilter((<HTMLInputElement>document.getElementById("quickFilter")).value);
  }
  OnGridRefresh() {
    var selectedRows = this.rowData;//this.popupConfig.data.Result.lawFirmModels;
    this.ptfcnt = 0;
    this.defcnt = 0;
    selectedRows.forEach((item, index) => {
      if (selectedRows[index].documentFileName1.length == 0 &&
        selectedRows[index].documentFileName2.length == 0 &&
        selectedRows[index].documentFileName3.length == 0 &&
        selectedRows[index].documentFileName4.length == 0 &&
        selectedRows[index].documentFileName5.length == 0
      ) {
        if (this.FilterTypeRdx == false) {
          this.ptfcnt = this.ptfcnt + 1;
        }
        if (this.FilterTypeRdx == true) {
          this.defcnt = this.defcnt + 1;
        }
      }
    });
    this.gridApi.clearFocusedCell();
    this.gridApi.refreshCells();
  }
  OnGridRefresh1() {
    this.gridApi1.clearFocusedCell();
    this.gridApi1.refreshCells();
  }
  onButtonClicked() {
    this.OnGridRefresh1();
    this.OnGridRefresh();
    this.TtlData = this.popupConfig.data.Result.lawFirmModels;
    var selectedRows1 = this.gridApi1.getSelectedRows();
    var selectedRows = this.gridApi.getSelectedRows();
    selectedRows1.forEach((item1, index1) => {
      selectedRows.forEach((item, index) => {
        if (selectedRows[index].documentFileName1 === selectedRows1[index1].path ||
          selectedRows[index].documentFileName2 === selectedRows1[index1].path ||
          selectedRows[index].documentFileName3 === selectedRows1[index1].path ||
          selectedRows[index].documentFileName4 === selectedRows1[index1].path ||
          selectedRows[index].documentFileName5 === selectedRows1[index1].path
        ) {
        }
        else {
          if (selectedRows[index].documentFileName1.length == 0) {
            selectedRows[index].documentFileName1 = selectedRows1[index1].path;
            selectedRows[index].pagecount1 = selectedRows1[index1].pagecount;
          }
          else
            if (selectedRows[index].documentFileName2.length == 0) {
              selectedRows[index].documentFileName2 = selectedRows1[index1].path;
              selectedRows[index].pagecount2 = selectedRows1[index1].pagecount;
            }
            else
              if (selectedRows[index].documentFileName3.length == 0) {
                selectedRows[index].documentFileName3 = selectedRows1[index1].path;
                selectedRows[index].pagecount3 = selectedRows1[index1].pagecount;
              }
              else
                if (selectedRows[index].documentFileName4.length == 0) {
                  selectedRows[index].documentFileName4 = selectedRows1[index1].path;
                  selectedRows[index].pagecount4 = selectedRows1[index1].pagecount;
                }
                else
                  if (selectedRows[index].documentFileName5.length == 0) {
                    selectedRows[index].documentFileName5 = selectedRows1[index1].path;
                    selectedRows[index].pagecount5 = selectedRows1[index1].pagecount;
                  }
        }
      });
    });
    this.TtlData.forEach((i1, inx1) => {
      selectedRows.forEach((i, inx) => {
        if (i1.def_ID === i.def_ID && i1.plaintiffId == i.plaintiffId) {
          this.TtlData[inx1].documentFileName1 = selectedRows[inx].documentFileName1;
          this.TtlData[inx1].documentFileName2 = selectedRows[inx].documentFileName2;
          this.TtlData[inx1].documentFileName3 = selectedRows[inx].documentFileName3;
          this.TtlData[inx1].documentFileName4 = selectedRows[inx].documentFileName4;
          this.TtlData[inx1].documentFileName5 = selectedRows[inx].documentFileName5;
          this.TtlData[inx1].pagecount1 = selectedRows[inx].pagecount1;
          this.TtlData[inx1].pagecount2 = selectedRows[inx].pagecount2;
          this.TtlData[inx1].pagecount3 = selectedRows[inx].pagecount3;
          this.TtlData[inx1].pagecount4 = selectedRows[inx].pagecount4;
          this.TtlData[inx1].pagecount5 = selectedRows[inx].pagecount5;
        }
      });
    });
    //  this.rowData = this.TtlData;
    this.popupConfig.data.Result.lawFirmModels = this.TtlData;
    this.OnGridRefresh();
    //if (this.FilterTypeRdx == false) {
    //  this.onPlaintiffFilter(null, 1);
    //}
    //if (this.FilterTypeRdx == true) {
    //  this.onDefFilter(null, 1);
    //}
  }
  public onFolderFilter(event): void {
    this.FolderNameddl = event.target.value;
    this.rowData1 = this.popupConfig.data.Result.dbxDataList;
    if (this.FolderNameddl == "ALL")
      this.rowData1 = this.rowData1;
    else
      this.rowData1 = this.rowData1.filter((c) => c.directory === this.FolderNameddl);
  }
  public isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  submit(event: Event) {
    event.preventDefault();
    this.ds.FormDefendantModels = this.popupConfig.data.Result.lawFirmModels;
    this.popup.close();
    document.querySelector('nh-root').removeAttribute('style');
  }
}
