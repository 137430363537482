import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
//import 'rxjs/add/operator/do';
import { map, tap, concat } from 'rxjs/operators';
import { LoginUser } from '../Models/Authentication/LoginUser';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Router, NavigationStart } from '@angular/router';
import { RequestCache } from '../Services/RequestCache';
import { of } from 'rxjs';
import { LoaderService } from './Loader/loader.service';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';
@Injectable()

export class MyInterceptor implements HttpInterceptor {

  failedIndex: number = 0;
  UrlLoader: any = [];

  constructor(private cache: RequestCache, private loaderService: LoaderService, private cookieService: CookieService, private route: Router) {
    this.UrlLoader = [];
  }

  _this: this;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
           
    this.showLoader();

    var request = null;
    this.UrlLoader.push({ url: req.url, showLoader: true });
    request = req.clone({
                headers: new HttpHeaders().append("ErgonToken", "ergontesting")
              });
    if (req.method == 'POST' || req.method == 'PUT') {
      this.cache.cache.clear();
      return this.sendRequest(request, next, this.cache, this);
    }
   // const cachedResponse = this.cache.get(request);
    return this.sendRequest(request, next, this.cache, this);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
    , _this: MyInterceptor): Observable<HttpEvent<any>> {
  //  this.showLoader();
    return next.handle(req).pipe(
      tap(event => {

        if (event instanceof HttpResponse) {

          var _this = this;

          // loader logic START.
          _this.UrlLoader = _.filter(_this.UrlLoader, function (data) {
            return data.url != req.url;
          });

          if (!_this.UrlLoader.length) {
            this.onEnd();
          } else {
            this.showLoader();
          }
          
          // on browser refresh f5 empty all url from the array.
          window.onbeforeunload = function (event) {
            _this.UrlLoader = [];
          }

          // CLEAR it in case if it is loogedout.
          if (req.url == "api/Authentication/Signout") {              
            _this.UrlLoader = [];
          }

          // loader logic END.            
          cache.put(req, event);
          var cookies = document.cookie.split(';');
          
          let loggedInObject;
          if (_this.cookieService.check("currentUser")) {
            loggedInObject = JSON.parse(_this.cookieService.get("currentUser"));
          }
          
          for (var i = 0; i < cookies.length; i++) {
            if (loggedInObject != null) {
              if (cookies[i].split('=')[0].trim() == loggedInObject.userName) {
                let expirydate = decodeURIComponent(cookies[i].split('=')[1]);

                //console.log('expire.... cookies ..::==',expirydate);

                var momentDate = moment([expirydate]);
                
                if (!loggedInObject["rememberMe"]) {
                  _this.cookieService.set("cookieExpiration", momentDate.toDate().toString());
                  _this.cookieService.set("currentUser", JSON.stringify(loggedInObject), new Date().setHours(4));
                }
              }
            }
          }
        
          try {
            let message = event.body.message as string;
            this.failedIndex = message.indexOf("Failed");
          } catch (e) {
          }                   

          // hide loader logic
          //if ((req.url != "api/Authentication/IsAuthenticated" && req.url != "api/authentication/authenticate" && req.url !="api/getAppSettingsEnvironment") || this.failedIndex > 0) {     //Loader is closing for this response
          //  //console.log(req.url);
          //  //this.onEnd();
          //}

          }
        },
          (error: any) => {
            if (error instanceof HttpErrorResponse) {
              this.onEnd();
              if (error.status == 401) {
                sessionStorage.removeItem('currentUser');
                localStorage.removeItem("currentUser");
                _this.cookieService.delete("currentUser");
                _this.route.navigate(['authentication']);
              }
              if (error.status == 501) {
                console.log(error.message);
              }
            }
      }));
  }

  //intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //  var _this = this;
  //  let request = req.clone({
  //    headers: new HttpHeaders().append("auth", "abcd")
  //  });
  //  return next.handle(request).pipe(tap(
  //    (event: any) => {
  //      if (event instanceof HttpResponse) {
  //        //console.log('event--->>>', event);
  //        var cookies = document.cookie.split(';');
  //        let loggedInObject = (JSON.parse(sessionStorage.getItem('currentUser')));

  //        for (var i = 0; i < cookies.length; i++) {
  //          if (loggedInObject != null) {
  //            if (cookies[i].split('=')[0].trim() == loggedInObject.userName) {
  //              let expirydate = decodeURIComponent(cookies[i].split('=')[1]);
  //              var momentDate = moment(expirydate);
  //              sessionStorage.setItem("cookieExpiration", momentDate.toDate().toString());
  //            }
  //          }

  //        }
  //      }
  //      //return event;
  //    },
  //    (error: any) => {
  //      if (error instanceof HttpErrorResponse) {
  //        if (error.status == 401) {
  //          sessionStorage.removeItem('currentUser');
  //          localStorage.removeItem("currentUser");
  //          _this.route.navigate(['authentication']);
  //        }
  //        if (error.status == 501) {
  //          console.log(error.message);
  //        }
  //      }
  //    }));
  //}

  private onEnd(): void {
    setTimeout(() => {
      this.loaderService.hide();
    }, 0);
  }

  private showLoader(): void {
    setTimeout(() => {
      this.loaderService.show();
    }, 0);
  }

  private hideLoader(): void {
    setTimeout(() => {
      this.loaderService.hide();
    }, 0);
  }

  getCurrentUser(): LoginUser {
    try {
      //console.log('browser cookie current user');
      if (sessionStorage.getItem("currentUser") != null && sessionStorage.getItem("currentUser") != undefined) {
        return JSON.parse(sessionStorage.getItem("currentUser"));
      }
      if (localStorage.getItem("currentUser") != null && localStorage.getItem("currentUser") != undefined) {
        return JSON.parse(localStorage.getItem("currentUser"));
      }
      return new LoginUser();
    }
    catch (err) {

    }
  }

  getUTCDateTime(date:Date): any {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var _offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours + _offset);

    return newDate; 
  }

}
