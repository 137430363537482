import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { LawFirmModel } from '../../../Core/Models/CreateJob/lawFrimModel';
import { Roles } from '../../../Core/Models/Admin/NHAdmin/role';
import { isUndefined } from 'util';
import { DataService } from '../../../Core/Services/data.service';
import _ from 'lodash';
@Component({
  selector: 'nh-dropdown',
  template: `


 <div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
           
<div  *ngFor="let firm of lawFirms" style="display: flex;padding: 0.25em;flex-flow: row;justify-content: flex-start;align-items: center;">
<input type="checkbox" (change)="changed(firm);"  [(checked)]="firm.isSelected"/><span>{{firm.lawFirm_Name}}</span>
</div>

<button type="button"  class="btnGreen" (click)="updateUserLF()" *ngIf="lawFirms">Update</button>
        </div>
    `,
  styles: [`
        .mood {
          flex-basis:100%;
        flex-flow:row wrap;

        }

        .default {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid transparent;
            padding: 4px;
        }

        .selected {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid lightgreen;
            padding: 4px;
        }
    `]
})
//<img src="https://www.ag-grid.com/images/smiley.png" (click)="onClick(true)" [ngClass]="{'selected' : happy, 'default' : !happy}">
//<img src="https://www.ag-grid.com/images/smiley-sad.png" (click)="onClick(false)"
//     [ngClass]="{'selected' : !happy, 'default' : happy}">
export class DropdownComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  //selectedItems = [];
  //dropdownSettings = {};
 
  lawFirms: Array<LawFirmModel>;
  @ViewChild('container', { read: ViewContainerRef }) public container;
  public happy: boolean = false;
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    })
  }
  constructor(private dataService: DataService) {

  }
  agInit(params: any): void {
    this.params = params;
    console.log(this.params.data.role)
    if (this.params.data.role.indexOf(',') > 0) {

      let roles = this.params.data.role.split(',');
      roles.forEach((v) => {
        if (v == Roles.ClientAdmin || v == Roles.ClientStaff) {
          this.lawFirms = this.params.data.lawFirms;
        } else {
        }
      })
    } else {
      if (this.params.data.role == Roles.ClientAdmin || this.params.data.role == Roles.ClientStaff) {
        this.lawFirms = this.params.data.lawFirms;
     //  console.log("adada")
      } 

    }
   
    if (this.params.value == "") {

    } else {
      var _this = this;
      if (this.params.value.split(',').length > 0) {
        let user = this.params.data.lawFirm_ID.split(',');
        user.forEach(function (v) {
          _this.updateCheckboxes(v);
        })
      } else {
        this.updateCheckboxes(this.params.data.lawFirm_ID);
      }
    }
    this.setHappy(params.value === "Happy");
  }

  updateCheckboxes(lfID: string) {
    this.lawFirms.forEach((v) => {
      if (v.lawFirm_ID == lfID) {
        v.isSelected = true;
        v.userID = this.params.data.id;
      }
    });
  }
  changed(lawfirm: LawFirmModel) {
    if (lawfirm.isSelected) {
      lawfirm.isSelected = false
    }
    else {
      lawfirm.isSelected = true;
    }
    this.lawFirms.forEach((v) => {
      if (v.lawFirm_ID == lawfirm.lawFirm_ID) {
        v.userID = this.params.data.id;
      }
    });
  }
  getValue(): any {
    let buffer: string = "";
    var _this = this;

    if (!isUndefined(this.lawFirms) && this.lawFirms!=null) {
      this.lawFirms.forEach(function (v, index) {
        if (v.isSelected) {
          if (index == 0 || index == _this.lawFirms.length) {
            buffer = v.lawFirm_Name;
          } else {
            buffer = (buffer == "") ? v.lawFirm_Name : buffer + "," + v.lawFirm_Name;
          }
        }
      });
    }
    return buffer;
  }
  isPopup(): boolean {
    return true;
  }
  setHappy(happy: boolean): void {
    this.happy = happy;
  }
  toggleMood(): void {
    this.setHappy(!this.happy);
  }

  onClick(happy: boolean) {
    this.setHappy(happy);
    this.params.api.stopEditing();
  }
  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (key == 37 ||  // left
      key == 39) {  // right
      this.toggleMood();
      event.stopPropagation();
    }
  }

  updateUserLF() {
    var selectedLFs = _.filter(this.lawFirms, function (data) {
      return data.isSelected;
    });
    this.dataService
      .updateUserLawFirms(selectedLFs)
      .subscribe(data => {
        //    console.log("response ... ", data);
        let buffer: string = "";
        if (data) {
          selectedLFs.forEach((v,index) => {
           
            if (index == 0 || index == selectedLFs.length) {
                buffer = v.lawFirm_Name;
              } else {
                buffer = (buffer == "") ? v.lawFirm_Name : buffer + "," + v.lawFirm_Name;
            }

          });
        }
        this.params.data.lawFirm_ID = buffer;
        this.params.stopEditing();
      });
    
  }
}
