import { Component, AfterViewInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { DataService } from '../../../Core/Services/data.service';
import _ from 'lodash';

@Component({
  selector: 'child-cell',
  template: `<button  (click)="invokeParentMethod()" class="btnGreen" type="button"> Submit </button>`,
  styles: [
  ]
})

export class SaveUserButtonRenderer implements ICellRendererAngularComp, AfterViewInit {

  constructor(private dataservice: DataService) { }

    ngAfterViewInit(): void {
        //throw new Error("Method not implemented.");
    }

    afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
          //throw new Error("Method not implemented.");
    }

  public params: any;

  agInit(params: any): void {

    // console.log("params:-- ", params);
    
    this.params = params;
  }

  public invokeParentMethod() {

    var selectedRoles = _.filter(this.params.data.userRoles, function (data) {
                  return data.isSelected;
                });

    console.log(selectedRoles);
    

    this.dataservice
      .updatedUserRoles(selectedRoles)
      .subscribe(data => {
        console.log("response ... ",data);
      });

    // updatedUserRoles

    // this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`)
  }

  refresh(): boolean {
    return false;
  }
}
