import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Batch, DefendantSub, DefendantDetails, BatchFile } from 'src/app/Core/Models/Batch/BatchModel';
import { concat } from 'rxjs/operators';
import { DataService } from 'src/app/Core/Services/data.service';
import { PopupComponent } from 'src/app/Shared/Popup/popup.component';
import { PopupService } from 'src/app/Shared/Popup/popup.service';
import { DefendantDetailsComponent } from './DefendantDetailsForm/DefendantDetails.component';
import { DefendantDataModelService } from './DefendantDetailsForm/DefendantDataModelService';
import { LawFirmModel } from 'src/app/Core/Models/CreateJob/lawFrimModel';
import _, { isUndefined } from 'lodash';
import moment from 'moment';
import { CommonDocumentUploadComponent } from './CommonDocumentUpload/CommonDocumentUpload.Component';
import Swal from 'sweetalert2';
import { AuthenticateService } from '../../../Core/Services/authenticate.service';

@Component({
  selector: 'nh-fromform',
  providers: [DefendantDataModelService],
  templateUrl: './FromForm.component.html',
  styleUrls: ['./FromForm.component.less']
})

export class FromFormComponent {

  ds: DataService;
  batch: Batch = new Batch();
  nestedDefendantDetails: string = null;

  showSuccess: boolean = false;
  showFailed: boolean = false;
  //defendantDetails: DefendantDetails;
  batchFromGroup: FormGroup;
  isChecked: any;
  ComponentName = "Manual";
  priorityList = [
    { 'id': 1, 'name': 'Rush' },
    { 'id': 3, 'name': 'Standard' },
  ];
  formLabelData = [
    {},
  ]
  date = new Date().toISOString().slice(0, 16);
  form: FormData = new FormData();
  attachmentsError: boolean = false;
  EmptyForm: boolean = false;
  defDetailsForm: string;
  defDoc: boolean = false;
  DefDetailsError = "Some form fields in Defended Details are missing";
  lawFirmsList: Array<LawFirmModel>;
  submitted: boolean = false;
  attachementFiles: any;
  today: any = moment(new Date()).format('YYYY-MM-DD');
  selectedFile: string = null;
  message: string = null;
  loginUser: string;
  validationErrors = {
    "lawFirmName": {
      "required": "lawFirmName is required",
    },
    "priority": {
      "required": "priority is required",
    },
    "contactName": {
      "required": "contactName is required",
      "maxlength": "contactName cannot be more than 100 characters long",
    },
    "contactEmail": {
      "required": "contactEmail is required",
      "maxlength": "contactEmail cannot be more than 100 characters long",
      "msg": "Invalid email format",
    },
    "firstName": {
      "required": "firstName is required",
      "maxlength": "firstName cannot be more than 50 characters long",
    },
    "lastName": {
      "required": "lastName is required",
      "maxlength": "lastName cannot be more than 50 characters long",
    },
    "courtName": {
      "required": "courtName is required",
      "maxlength": "courtName cannot be more than 100 characters long",
    },
    "CourtCountyName": {
      "required": "court county name is required",
      "maxlength": "court county name cannot be more than 50 characters long",
    },
    "leadDefendantName": {
      "required": "leadDefendantName is required",
      "maxlength": "leadDefendantName cannot be more than 250 characters long",
    },
    "deliveryByDate": {
      "min": "Invalid Date",
    },
    "IndexNo": {
      "required": "IndexNo is required",
      "maxlength": "IndexNo cannot be more than 50 characters long",
    },
    "defDoc": {
      "required": "defDoc is required",
      "maxlength": "Only 5 attachments are allowed",
    },
    "batchNotes": {
      "required": "rider notes is required",
      "maxlength": "rider notes cannot be more than 500 characters long",
    },
    "courtstate": {
      "required": "rider notes is required",
      "maxlength": "",
    },
    "emptyFields": "Some of the required fields are empty.",
    "caseFiledDate": {
      "min": "Invalid Date",
    },
    "attorney": { "required": "Attorney name is required" }
  };

  constructor(private formBuilder: FormBuilder,
    private _ds: DataService,
    private popupService: PopupService,
    private defendantDataService: DefendantDataModelService,
    private authentication: AuthenticateService) {
    this.ds = _ds;
  }

  ngOnInit() {
    var data: any = this.authentication.getLoggedInUser();
    this.loginUser = data.firstName + " " + data.lastName;
    // console.log(' manual component from form')
    console.log(this.loginUser);
    // set min value for the date picker
    document.querySelector("#deliveryByDate").setAttribute('min', this.today);

    document.querySelector("#caseFiledDate").setAttribute('min', moment((new Date()).setFullYear(1900)).format('YYYY-MM-DD'));

    try {

      this.ds.getLawFirms().subscribe(lawFirms => {
        this.lawFirmsList = lawFirms;
      });

      // data service.... example
      this.defendantDataService
        .currentMessage
        .subscribe(
          (message) => {
            // console.log('from service message', message);
            // this.message = message
          }
        );

    } catch (ex) {
      console.log(ex);
    }

    // form builder
    this.batchFromGroup = this.formBuilder.group({

      //   Law Firm Details
      lawFirmName: ["", [Validators.required, Validators.maxLength(100)]],
      priority: ["", [Validators.required]],
      contactName: ["", [Validators.required, Validators.maxLength(100)]],
      contactEmail: ["", [Validators.required, Validators.pattern(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/), Validators.maxLength(100)]],

      // Plaintiff Details.
      firstName: ["", [Validators.required, Validators.maxLength(50)]],
      lastName: ["", [Validators.required, Validators.maxLength(50)]],
      courtName: ["", [Validators.required, Validators.maxLength(200)]],
      CourtCountyName: ["", [Validators.required, Validators.maxLength(50)]],
      leadDefendantName: ["", [Validators.required, Validators.maxLength(250)]],
      deliveryByDate: ["", [Validators.required]],
      IndexNo: ["", [Validators.required, Validators.maxLength(50)]],
      defDoc: [""],
      batchNotes: ["", [Validators.required, , Validators.maxLength(500)]],
      identifier: ["",],
      documentType: [""],
      caseFiledDate: [""],
      courtstate: ["", [Validators.required]],
      attorney: ["", Validators.required],
      courtType: ["", Validators.required],
    }, { updateOn: 'change' });

  }

  get f() { return this.batchFromGroup.controls }

  focusOutFunction() {
    this.ds.LawFirmId = this.f.lawFirmName.value;
  }
  checkValue(e: any) {
    var _this = this;
    let check = e as string;

    if (this.isChecked) {
      this.batchFromGroup.controls['attorney'].patchValue(this.loginUser);
      this.batchFromGroup.controls['attorney'].disable();
      //this.uploadForm.controls['attorney'].setValue(this.loginUser, { emitEvent: false });
    } else {
      this.batchFromGroup.controls['attorney'].patchValue('');
      this.batchFromGroup.controls['attorney'].enable();
    }
    // console.log(e);
    //if (e.target.value)
  }
  copyToFormData() { }

  @ViewChild('DefendantDetails') DefendantDetail: PopupComponent;
  DefendantDetails(batch: Batch) {
    this.popupService.open(
      DefendantDetailsComponent, {
        data: { message: null },
        pageName: "DefendantDetails",
        vW: 46,
        vH: 50,
      });
  }

  @ViewChild('UploadDefendantDocument') UploadCommonDocument: PopupComponent;
  UploadCommonDocuments() {
    this.popupService.open(
      CommonDocumentUploadComponent, {
        data: { message: null },
        pageName: "UploadDefendantDocument",
        vW: 40,
        vH: 50,
      });
  }

  @ViewChild('DefendantDetails') EditDefendantDetail: PopupComponent;
  viewDetails(def: DefendantDetails, index: number) {
    this.popupService.open(
      DefendantDetailsComponent, {
        data: { message: { def, index } },
        pageName: "DefendantDetails",
        vW: 46,
        vH: 50,
      });
  }

  changeColor(event: Event) {
    event.srcElement.className = event.srcElement.className.replace("error", "");
    // console.log();
  }

  validateDefData() {
    if (this.ds.batch.DefendantDetails.length < 1) {
      return true;
    }
  }

  deliveryByDateValidation() {
    var deliveryByDate = moment(this.batchFromGroup.controls.deliveryByDate.value, 'YYYY-MM-DD').toDate();
    //console.log(moment(deliveryByDate).isBefore(moment.now()), deliveryByDate);
    if (moment(deliveryByDate).isBefore(moment().subtract(1, 'days'))) {
      this.batchFromGroup.controls.deliveryByDate.setErrors({ min: true });
    }
  }

  caseFiledDateValidation() {

    var caseFiledDate = moment(this.batchFromGroup.controls.caseFiledDate.value, 'YYYY-MM-DD').toDate();

    if (moment(caseFiledDate).isBefore(moment((new Date()).setFullYear(1900)))) {
      // console.log(moment(caseFiledDate).isBefore(moment((new Date()).setFullYear(1900))), moment(this.batchFromGroup.controls.caseFiledDate.value, 'YYYY-MM-DD').toDate());
      this.batchFromGroup.controls.caseFiledDate.setErrors({ min: true });
    }
  }

  submit() {
    try {

      //console.log('document data....', this.ds.commonDoc);

      //console.log(this.f);
      //console.log(this.submitted);
      this.submitted = true;

      // ------- reset the form, clear the old data of form-data. ------- //
      let resetFormDataKeys = {
        'lawFirmName': true,
        'priority': true,
        'contactName': true,
        'contactEmail': true,

        'firstName': true,
        'lastName': true,
        'courtName': true,
        'CourtCountyName': true,
        'leadDefendantName': true,
        'deliveryByDate': true,
        'IndexNo': true,
        'nestedDefendantDetails': true,
        'batchNotes': true,
        'identifier': true,
        "documentType": true,
        "caseFiledDate": true,
        "courtstate": true,
        "attorney": true,
        "courtType": true,
      };

      let _this = this;
      Object.keys(resetFormDataKeys).forEach(key => {
        //console.log(_this.ds.BatchForm.has(key));
        if (_this.ds.BatchForm.has(key)) {
          _this.ds.BatchForm.delete(key);
        }
      });

      this.ds.BatchForm.delete("lawFirm_Name");
      this.ds.BatchForm.delete("lawFirm_API_KEY");
      this.ds.BatchForm.delete("LawFirm_Identifier");

      //  ------- validate the form  -------//
      if (this.batchFromGroup.invalid) {
        // console.log('form invalid', this.f);
        this.EmptyForm = true;
        //console.log('nested deffs doc', this.ds.batch.DefendantDetails.length);
        return;
      }

      // validating nested defendant.
      if (this.validateDefData()) {
        this.EmptyForm = true;

        Swal.fire({
          type: 'error',
          title: 'REQUIRED',
          text: 'Defendant details are missing.',
        });

        return;
      }

      this.EmptyForm = false;

      // ------- building the form data ------- //

      _this = this;
      _.forEach(resetFormDataKeys, function (value, key) {
        if (key === "nestedDefendantDetails") {
          _this.ds.BatchForm.append(key, JSON.stringify(_this.ds.batch.DefendantDetails));
        } else if (key == "lawFirmName") {
          _this.ds.BatchForm.append('lawFirmName', _this.lawFirmsList.find(i => i.lawFirm_ID == _this.f.lawFirmName.value).lawFirm_Name);
        } else {
          _this.ds.BatchForm.append(key, _this.f[key].value);
        }
      });


      var data: any = this.authentication.getLoggedInUser();
      this.ds.BatchForm.append('LoggedInUser', data.firstName + " " + data.lastName);
      this.ds.BatchForm.append('lawFirmId', this.f.lawFirmName.value);
      this.ds.BatchForm.append('lawFirm_API_KEY', this.lawFirmsList.find(i => i.lawFirm_ID == this.f.lawFirmName.value).lawFirm_API_KEY);
      this.ds.BatchForm.append('LawFirm_Identifier', this.lawFirmsList.find(i => i.lawFirm_ID == this.f.lawFirmName.value).lawFirm_Identifier);
      this.ds.BatchForm.append('batchNotes', this.f.batchNotes.value);
      this.ds.BatchForm.append('identifier', this.f.identifier.value);
      //console.log('common document', this.ds.commonDocument);
      this.ds.BatchForm.append('commondocument', JSON.stringify(this.ds.commonDocument));
      this.ds.BatchForm.append('attorney', this.f.attorney.value);
      this.ds.BatchForm.append('courtType', this.f.courtType.value);
      //console.log(this.ds.commonDoc);


      // ------- post the form ------- //
      this
        .ds
        .postBatch(this.ds.BatchForm)
        .subscribe(data => {
          if (!isUndefined(data)) {
            // console.log(data);
            _this.showSuccess = data.isSuccess;
            _this.message = data.message;

            if (_this.showSuccess) {

              Swal.fire(
                'success',
                _this.message,
                'success'
              );

              setTimeout(function () {

                //Object.keys(_this.f.controls).forEach(key => {
                //  _this.f.controls[key].setErrors(null)
                //});

                if (_this.showSuccess) {
                  //_this.showSuccess = false;
                  _this.ds.BatchForm = new FormData();
                  _this.ds.batch = new Batch();
                  _this.batchFromGroup.markAsUntouched();
                  _this.submitted = false;
                  _this.message = "";
                  _this.batchFromGroup.setErrors({ required: true });
                  _this.ds.commonDocument = new Array<BatchFile>();
                  _this.ds.commonDoc = 0;
                  _this.batchFromGroup.controls["attorney"].setValue("");
                  _this.batchFromGroup.controls["lawFirmName"].setValue("");
                  _this.batchFromGroup.controls["priority"].setValue("");
                  _this.batchFromGroup.controls["contactName"].setValue("");
                  _this.batchFromGroup.controls["contactEmail"].setValue("");
                  _this.batchFromGroup.controls["firstName"].setValue("");
                  _this.batchFromGroup.controls["lastName"].setValue("");
                  _this.batchFromGroup.controls["courtName"].setValue("");
                  _this.batchFromGroup.controls["CourtCountyName"].setValue("");
                  _this.batchFromGroup.controls["leadDefendantName"].setValue("");
                  _this.batchFromGroup.controls["deliveryByDate"].setValue("");
                  _this.batchFromGroup.controls["IndexNo"].setValue("");
                  _this.batchFromGroup.controls["batchNotes"].setValue("");
                  _this.batchFromGroup.controls["identifier"].setValue("");
                  _this.batchFromGroup.controls["documentType"].setValue("");
                  _this.batchFromGroup.controls["caseFiledDate"].setValue("");
                  _this.batchFromGroup.controls["courtstate"].setValue("");
                  _this.selectedFile = "";
                  _this.batchFromGroup.controls["courtType"].setValue("");
                  _this.batchFromGroup.controls['attorney'].enable();
                }

              }, 1000);

              // console.log(this.disableButton);
              //this.disableButton = false;
              //this.showFailed = true;
            } else {
              this.showFailed = false;
            }
          }
        });

    } catch (e) {
      console.log(e);
    }
  }

}

