import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { JobDashboardModel } from 'src/app/Core/Models/Admin/NHAdmin/job-dashboard-model';
import { PopupConfig } from 'src/app/Shared/Popup/popup-config';

@Component({
  selector: 'nh-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.less']
})
export class PdfviewerComponent implements OnInit {
  heightValue: string;
  constructor(private dataService: DataService, private popupConfig: PopupConfig, ) { }
  jobServiceDetailsModel: JobDashboardModel;
  ngOnInit() {
    console.log(this.jobServiceDetailsModel);
    this.jobServiceDetailsModel = this.popupConfig.data.message;
    this.dataService.openPDFFile(this.jobServiceDetailsModel).subscribe(data => {
      this.heightValue = this.popupConfig.vH + 20 + 'vh';
      document.getElementById('pdfData').setAttribute('type', 'application/pdf');
      document.getElementById('pdfData').setAttribute('data', 'data:application/pdf;base64,' + data.filePath);
      // document.getElementById("popupAlertMessage").innerText ="Document removed from Development Environment";
    });
  }

}
