import { Component, OnInit, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/Core/Services/data.service';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { isUndefined } from 'util';

@Component({
  selector: 'nh-resetuser',
  templateUrl: './resetuser.component.html',
  styleUrls: ['./resetuser.component.less']
})
export class ResetuserComponent implements OnInit {
  private registerLoading = false;
  private resetSubmitted = false;
  private registerSuccess = false;
  private resetFormGroup: FormGroup;
  public errorMessages: any;
  public error: string;
  public resetState = false;
  public EmptyForm = false;
  public UserNameError = false;
  public PasswordError = false;
  public LoginUser = false;
  password: string;
  public passwordError = false;
  differ: KeyValueDiffer<string, any>;
  validationmsgs = {
    "username": {
      "required": "Username is required",
      "minlength": "Username should be atleast 4 characters long",
      "maxlength": "Username cannot be more than 16 characters long",
      "pattern": "Username cannot contain special characters",
    },
    "password": {
      "required": "Password is required",
      "minlength": "Minimum length 8 chars",
      "maxlength": "Maximum 16 characters long",
      "onenumber": " password must have atleast 1 number between 0 - 9",
      "uppercasealphabet": " password must have atleast 1 uppercase letter ",
      "match": "Passwords do not match"
    },
    "reset_successful": "User updated successfully.",
    "empty_fields": "Some of the required fields are empty.",
    "required": "All fields are required.",
    "redirect": "go back to sign in page.",
    "loginuser": "could not reset your password from here."
  };
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authservice: AuthenticateService,
    private dataService: DataService,
    public differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
  }
  ngDoCheck() {
    const change = this.differ.diff(this);

    if (change) {
      change.forEachChangedItem(item => {
      //  console.log(item);
      });
    }

  }
  ngOnInit() {
    this.resetFormGroup = this.formBuilder.group({
      UserName: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(16),
        Validators.pattern('^[a-zA-Z0-9]*$')
      ]]
    },
      {
        updateOn: 'blur'
      });
  }
  passwordmatch() {

    // this.passwordCheck();

    // confirm password numeric validation
    if (this.resetFormGroup.value.ConfirmPassword != '') {
      if (this.resetFormGroup.value.ConfirmPassword.match(/\d+/g) == null) {
        this.resetFormGroup.controls.ConfirmPassword.setErrors({ onenumber: true });
      } else {
        this.resetFormGroup.controls.ConfirmPassword.setErrors({ onenumber: false });
      }
    }

    //  match password and conform-password validation
    if (this.resetFormGroup.value.RegisterPassword != '' && this.resetFormGroup.value.ConfirmPassword != '') {
      if (this.resetFormGroup.value.RegisterPassword !== this.resetFormGroup.value.ConfirmPassword) {
        this.resetFormGroup.controls.ConfirmPassword.setErrors({ match: true });
      } else {
        this.resetFormGroup.controls.ConfirmPassword.setErrors(null);
      }
    }

  }
  get formControls() { return this.resetFormGroup.controls }
  isEmptyOrSpaces(str) {
    if (!isUndefined(str))
      return str === null || str.match(/^ *$/) !== null;
  }
  resetSubmit() {
    if (isUndefined(this.password) || this.isEmptyOrSpaces(this.password)) {
      this.passwordError = true;
      return;
    }
    if (this.authservice.getLoggedInUser().userName == this.formControls.UserName.value) {
      this.EmptyForm = false;
      this.LoginUser = true;
      //this.resetFormGroup.reset(); 
      return;
    } else {
      this.LoginUser = false;
    }

    // this.passwordmatch();

    // console.log('registration submit....');
    this.resetSubmitted = true;

    if (this.UserNameError) {
      this.UserNameError = false;
    }

    if (this.PasswordError) {
      this.PasswordError = false;
    }


    if (this.resetFormGroup.invalid) {
      // console.log('invalid:- values ', this.formControls.UserName.value, this.formControls.RegisterPassword.value);
      this.EmptyForm = true;
      return;
    }

    // console.log('valid form');
    this.EmptyForm = false;


    type UserDetails = {
      UserName: string;
      Password: string;
    }

    const payload = {} as UserDetails;

    payload.UserName = this.formControls.UserName.value;
    payload.Password = this.password;

    this.dataService.resetNHUserPassword(payload)
      .subscribe(
        result => {
          if (result.ErrorCode || result.id == 0) {         
            if (result.id == 0) {
              this.error = "please provide valid username.";
              this.UserNameError = true;
            }
          } else {
            //this.selectedValue = 3;
            // this.redirect();
            this.resetFormGroup.reset();
            this.password = "";
            this.resetState = true;
            this.resetSubmitted = true;
           // console.log('sssss')
            setTimeout(() => {
             // console.log('noooo')
              this.resetState = false;
            },3000)
          }

        },
        err => this.error = 'Could not rest'
      );

    // remove this if we have register service from authentication-service.
    //this.register = false;
    this.resetSubmitted = true;

  }
  generatePassword() {
    let min = Math.ceil(8);
    let max = Math.floor(12);
    let len = Math.floor(Math.random() * (max - min)) + min;
    //console.log(len);
    this.password = this.createPassword(len);
    this.passwordError = false;
    //console.log(len, this.password);
  }
  createPassword(length) {
    var alpha = "abcdefghijklmnopqrstuvwxyz";
    var caps = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var numeric = "0123456789";
    // var special = "!$^&*-=+_?";

    var options = [alpha, caps, numeric];//special

    var password = "";
    var passwordArray = Array(length);

    for (let i = 0; i < length; i++) {
      var currentOption = options[Math.floor(Math.random() * options.length)];
      var randomChar = currentOption.charAt(Math.floor(Math.random() * currentOption.length));
      password += randomChar;
      passwordArray.push(randomChar);
      // console.log(password);
    }

    //return password;
    //var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
    //var pass = "";
    //for (var x = 0; x < length; x++) {
    //  var i = Math.floor(Math.random() * chars.length);
    //  pass += chars.charAt(i);
    //}
    if (!isUndefined(password) && password != undefined && password != "" && this.passwordCheck(password)) {
      return password;
    }
    else {

      setTimeout(() => {
        this.generatePassword();
      }, 1000 / 60);

    }
  }
  passwordCheck(password) {
    if (!isUndefined(password)) {
      // Password validation
      if (password.match(/\d+/g) == null) {
        return false;
      } else if (password.match(/[A-Z]/g) == null) {
        return false;
      } else if (password.match(/[A-Z]/g) == null) {
        return false;
      } else {
        return true;
      }
    }
  }

}
