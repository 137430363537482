import {
  Component, Type, OnDestroy, AfterViewInit, ComponentRef, ChangeDetectorRef,
  ComponentFactoryResolver, ViewChild, Output, EventEmitter, ElementRef, HostListener
} from '@angular/core';
import { InsertionDirective } from './insertion.directive'
import { WorkareadetailsComponent } from 'src/app/Modules/workareadetails/workareadetails.component';
import { PopupRef } from './popup-ref';
import { Subject } from 'rxjs';
import { PopupConfig } from './popup-config';
import { AlertComponent } from '../alert/alert.component';
import { isUndefined } from 'util';
import { SignalRService } from 'src/app/Core/Services/SignalR/signal-r.service';
import { DefendantDetailsComponent } from 'src/app/Modules/CreateJobs/FromForm/DefendantDetailsForm/DefendantDetails.component';
import { KEY_CODE } from 'src/app/Core/Models/WorkArea/PlainTiffModel';
import { CommonDocumentUploadComponent } from 'src/app/Modules/CreateJobs/FromForm/CommonDocumentUpload/CommonDocumentUpload.Component';
import { DataService } from 'src/app/Core/Services/data.service';

@Component({
  selector: 'nh-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.less'],
  entryComponents: [WorkareadetailsComponent, AlertComponent, DefendantDetailsComponent, CommonDocumentUploadComponent]
})

export class PopupComponent implements AfterViewInit, OnDestroy {

  body: string;
  @Output() optionValue: EventEmitter<any> = new EventEmitter<any>();
  componentRef: ComponentRef<any>;
  childComponentType: Type<any>;
  @ViewChild(InsertionDirective) insertionPoint: InsertionDirective;
  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  popupOverPopup: boolean;
  popupOverPopupAlert: boolean;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private popupRef: PopupRef,
    private signalR: SignalRService,
    private popupConfig: PopupConfig, private ds: DataService) { }

  loadChildComponent(componentType: Type<any>) {
    let _this = this;
    //let rootElementStyle = 'filter:blur(2px) grayscale(0.5)';
    //document.querySelector('nh-root').setAttribute('style', rootElementStyle);
    if (window.matchMedia('(max-width:1366px)').matches) {
      if (_this.popupConfig.pageName == 'workareadetails' || _this.popupConfig.pageName == 'viewCompleted') {
        _this.popupConfig.vW = 84;
        var style = 'max-width: ' + _this.popupConfig.vW + 'vw;min-height:' + _this.popupConfig.vH + 'vH';
        document.querySelector('#popupBox').setAttribute('style', style);
      } else {
        let updatedVW = _this.popupConfig.vW + 6;
        var style = 'max-width: ' + updatedVW + 'vw;min-height:' + _this.popupConfig.vH + 'vH';

        document.querySelector('#popupBox').setAttribute('style', style);
      }
    } else {

      var style = 'max-width: ' + _this.popupConfig.vW + 'vw;min-height:' + _this.popupConfig.vH + 'vH';
      document.querySelector('#popupBox').setAttribute('style', style);
    }
    window.onresize = function (event) {
      let popuBox = document.querySelector('#popupBox');
      if (popuBox != undefined) {
        if (window.matchMedia('(max-width: 1366px)').matches) {

          var updateVW = _this.popupConfig.vW + (_this.popupConfig.vW / 6),
            updateVH = _this.popupConfig.vH + (_this.popupConfig.vH / 6);
          var style = 'max-width: ' + updateVW + 'vw;min-height:' + updateVH + 'vH';
          popuBox.setAttribute('style', style);
        }
        if (window.matchMedia('(min-width: 600px) and (max-width:1024px)').matches) {
          var updateVW = _this.popupConfig.vW + (_this.popupConfig.vW / 0.75),
            updateVH = _this.popupConfig.vH + (_this.popupConfig.vH / 2);
          var style = 'max-width: ' + updateVW + 'vw;min-height:' + updateVH + 'vH';
          popuBox.setAttribute('style', style);
        }
        if (window.matchMedia('(max-width:599px)').matches) {
          var updateVW = _this.popupConfig.vW + (_this.popupConfig.vW / 0.79),
            updateVH = _this.popupConfig.vH + (_this.popupConfig.vH / 2.125);
          var style = 'max-width: ' + updateVW + 'vw;min-height:' + updateVH + 'vH';
          popuBox.setAttribute('style', style);
        }
        else if (window.outerWidth > 1368) {
          var _style = 'max-width: ' + _this.popupConfig.vW + 'vw;min-height:' + _this.popupConfig.vH + 'vH';

          popuBox.setAttribute('style', _style);
        }
      }
      // else {
      //   var style = "max-width: " + _this.popupConfig.vW + "vw;min-height:" + _this.popupConfig.vH + "vH";
      //   document.querySelector('#popupBox').setAttribute("style", style);
      // }


    };



    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    let viewContainerRef = this.insertionPoint.viewContainerRef;
    //alert(viewContainerRef);
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    //alert(componentType);
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode == KEY_CODE.ESCAPE_KEYCODE) {
      if (this.ds.getPageName() == 'workareadetails' || this.ds.getPageName() == 'viewCompleted') {
        if (this.popupOverPopupAlert) {
          this.popupOverPopupAlert = false;
          return;
        }
        if (this.popupOverPopup) {
          this.popupOverPopup = false;
          return;
        }
        else
          this.popupOverPopup = false;
        // console.log(document.getElementById("pdfPopup").style.display)
        if (document.getElementById('pdfPopup').style.display == 'flex') {
          document.getElementById('pdfPopup').style.display = 'none';
        } else {
          this.close();
          //this.popupRef.close();
        }
      }
    }
  }//*///Code for closing popup on escape.
  @ViewChild('header') _el: ElementRef;
  ngAfterViewInit() {
    try {
      this.loadChildComponent(this.childComponentType);
      this.cd.detectChanges();
      this._el.nativeElement.focus();
    } catch (ex) {
      console.log(ex);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  ngOnInit() {
    if (!this.signalR.connectionIsEstablished) {
      this.signalR.connectionEstablished.subscribe(() => {
        //this.openWorkarea();
        //Open connection and Start straming workarea!!
      });
    }
  }

  onOverlayClicked(evt: MouseEvent) {
    console.log(this.ds.getPageName());
    if (this.ds.getPageName() == 'workareadetails' || this.ds.getPageName() == 'viewCompleted') {
      if (this.popupOverPopupAlert) {
        this.popupOverPopupAlert = false;
        return;
      }
      if (this.popupOverPopup) {
        this.popupOverPopup = false;
        return;
      }
      else
        this.popupOverPopup = false;
      // console.log(document.getElementById("pdfPopup").style.display)
      if (document.getElementById('pdfPopup').style.display == 'flex') {
        document.getElementById('pdfPopup').style.display = 'none';
      } else {
        this.close();
        //this.popupRef.close();
      }
    }
  }

  onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  close() {
    //document.querySelector('nh-root').removeAttribute('style');
    //console.log(document.getElementById("pdfPopup").style.display)
    if (!isUndefined(this.popupConfig) && !isUndefined(this.popupConfig.data) && !isUndefined(this.popupConfig.data.message)) {
      if (this.popupConfig.data.message != null && this.popupConfig.data.message.hasOwnProperty('isUpdate')) {
        // this.signalR.getAllWorkArea();
        window.location.reload();
        // console.log(this.popupConfig.data.message.isUpdated);
      }
    }
    //
    this._onClose.next();
  }
  closePopupOverPopup() {
    this.popupOverPopup = true;
    document.getElementById('pdfPopup').style.display = 'none';
  }
  closePopupOverPopupAlert() {
    this.popupOverPopupAlert = true;
    document.getElementById('pdfAlertPopup').style.display = 'none';
  }
  searchValues(data: any) {
    console.log('ccaasd', data);
  }

}
