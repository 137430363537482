import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DataService } from 'src/app/Core/Services/data.service';
import { LawFirmModel } from 'src/app/Core/Models/CreateJob/lawFrimModel';
import { DateTypes, PriorityTypes, StatusTypes } from 'src/app/Core/Models/Admin/NHAdmin/job-dashboard-model';
import { isUndefined } from 'util';
import { ReportsSearch } from 'src/app/Core/Models/Admin/NHAdmin/reports-search';
import { ReportsModel } from 'src/app/Core/Models/Admin/NHAdmin/reports-model';
import { ExcelService } from 'src/app/Core/Services/ExcelService/excel.service';
import { DefendantaddressComponent } from './defendantaddress/defendantaddress.component';

@Component({
  selector: 'nh-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {
  public gridApi;
  public gridApiSDP;
  private gridColumnApi;
  private gridColumnApiSDP;
  private rowSelection;
  errorMessage: string = "";
  errorMessage1: string = "";
  overlayNoRowsTemplate: string;
  private gridOptions: GridOptions;
  private gridOptionsSDP: GridOptions;
  public rowData: any[];
  public rowDataSDP: any[];
  private columnDefs: any[];
  private columnDefsSDP: any[];
  selectedValue: number = 0;
  searchedResult: any;
  toggleButtonText: string;
  showSearch: boolean;
  lawFirmsList = new Array<LawFirmModel>();
  dateTypes = new Array<DateTypes>();
  batchPriorityTypes = new Array<PriorityTypes>();
  statusList = new Array<StatusTypes>();
  reportSearch = new ReportsSearch();
  reportsList: Array<ReportsModel>;
  selectedModel = new ReportsModel();
  exportList = new Array<ReportsModel>();
  displayError: boolean;
  errorText: string;
  showGrid: boolean;
  reportName: string;
  caseReport: boolean;
  dateStatus: boolean;
  clientReport: boolean;
  searchClicked: boolean;
  showStatus: boolean;
  showPriority: boolean;
  showDateType: boolean;
  showStatusInfo: string;
  showPriorityInfo: string;
  showDateTypeInfo: string;
  showDateInfo: string;
  private rowHeight;
  private getRowHeight;
  constructor(private dataservice: DataService, private excelService: ExcelService) {
    this.gridColumnsArragment();
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
  }

  ngOnInit() {
    
    this.showSearch = true;
    this.toggleButtonText = "Hide select criteria";
    this.dataservice.getLawFirms().subscribe(lawFirms => {
      this.lawFirmsList.push({ lawFirm_ID: "0", lawFirm_Name: "<< Any >>", isSelected: false, lawFirm_API_KEY: "", lawFirm_Identifier: "", userID: 0 });
      lawFirms.forEach((item) => {
        this.lawFirmsList.push(item);
      });      
    });

    this.dataservice.getGetBatchPriorityList().subscribe(priorityList => {
      this.batchPriorityTypes.push({ batchPriority_ID: 0, batchDescr: "<< Any >>", batchPriority: "<< Any >>" });
      priorityList.forEach((item) => {
        this.batchPriorityTypes.push(item);
      });
    });
   
    this.statusList.push({ statusID: 0, statusName: "<< Any >>" });
    this.statusList.push({ statusID: 1, statusName: "In Progress" });
    this.statusList.push({ statusID: 2, statusName: "Served" });
    this.statusList.push({ statusID: 3, statusName: "Not Served" });
    this.statusList.push({ statusID: 4, statusName: "Not Applicable" });
    this.statusList.push({ statusID: 5, statusName: "Completed" });
    this.statusList.push({ statusID: 6, statusName: "AOS filed" });


    this.dateTypes.push({ dateTypeID: 0, dateTypeName: "<< None >>" });
    this.dateTypes.push({ dateTypeID: 1, dateTypeName: "Received Date" });
    this.dateTypes.push({ dateTypeID: 2, dateTypeName: "Processed Date" });
    this.dateTypes.push({ dateTypeID: 3, dateTypeName: "Serve By Date" });
    this.dateTypes.push({ dateTypeID: 4, dateTypeName: "Served Date" });
    this.reportSearch.lawFirm_ID = 0;
    this.reportSearch.priorityID = 0;
    this.reportSearch.status = "<< Any >>";
    this.reportSearch.dateFilterType = 0;

    let search = new ReportsSearch();
    search.isDefault = 1;
    //this.dataservice.getReportResults(search).subscribe(data => {
    //  this.reportsList = JSON.parse(JSON.stringify(data));
    //  this.rowData = this.reportsList;
    //});

  }
  toggleSearch() {
    if (this.showSearch) {
      this.showSearch = false;
      this.toggleButtonText = "Show select criteria";
    } else {
      this.showSearch = true;
      this.toggleButtonText = "Hide select criteria";
    }
  }
  onGridReady(params) {
   
 //  console.log("loaded");
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
    // params.api.sizeColumnsToFit();
    //this.resetColumns();
    //
    //this.onGridSizeChanged(params);
  }
  onGridReadySDP(params) {

  //  console.log("loaded");
    this.gridApiSDP = params.api;
    this.gridColumnApiSDP = params.columnApi;
    this.sizeToFit();
    // params.api.sizeColumnsToFit();
    //this.resetColumns();
    //
    //this.onGridSizeChanged(params);
  }
  onFirstDataRendered(params) {
    if (!isUndefined(this.reportsList) && this.reportsList.length > 0) {
      params.api.forEachNode((node, index)=> {
        if (index == 0) {
          // console.log(node);
          this.selectedModel = node;
          node.setSelected(true);
        }
      });
    }
    params.api.sizeColumnsToFit();
  }
  onFirstDataRenderedSDP(params) {
    if (!isUndefined(this.reportsList) && this.reportsList.length > 0) {
      params.api.forEachNode((node, index) => {
        if (index == 0) {
          // console.log(node);
          this.selectedModel = node;
          node.setSelected(true);
        }
      });
    }

    params.api.sizeColumnsToFit();
  }
  gridColumnsArragment() {
    //if (type == 0) {
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        //  console.log("model updated");
        this.gridOptions.api.sizeColumnsToFit();
      },
    };
    this.gridOptionsSDP = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        //  console.log("model updated");
        this.gridOptionsSDP.api.sizeColumnsToFit();
      },
    };
    // this.gridOptions.rowStyle = { "font-family": "IBM Plex Sans, sans-serif"}
    this.columnDefs = [
      { headerName: 'Case #', field: 'caseId', width: 100, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" }, autoHeight: true },
      { headerName: 'Priority', field: 'batchPriority', width: 80, sortingOrder: ["asc", "desc"], autoHeight: true},
      { headerName: 'Plaintiff Ref. #', field: 'plaintiffIdentifierBilling', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true },
      {
        headerName: 'Defendant', field: 'defFullAddress', autoHeight: true, width: 310, cellClass: "cell-wrap-text", cellStyle: { "white-space": "normal" }, sortingOrder: ["asc", "desc"],cellRendererFramework: DefendantaddressComponent
      },
      { headerName: 'Received Date', field: 'receivedDate', width: 130, sortingOrder: ["asc", "desc"], autoHeight: true},
      { headerName: 'Status', field: 'status', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true},
      { headerName: 'Served Date', field: 'served_date', width: 140, sortingOrder: ["asc", "desc"], autoHeight: true} ,    
      { headerName: 'Server Attempts', field: 's_CommentSeqNum', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true }     
    ];
    this.columnDefsSDP = [
      { headerName: 'Client', field: 'lawFirm_Name', width: 120, sortingOrder: ["asc", "desc"], autoHeight: true},
      { headerName: 'Case #', field: 'caseId', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true, cellStyle: { textAlign: "right" } },
      { headerName: 'Priority', field: 'batchPriority', width: 80, sortingOrder: ["asc", "desc"], autoHeight: true },
      { headerName: 'Plaintiff Ref. #', field: 'plaintiffIdentifierBilling', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true },
      {
        headerName: 'Defendant', field: 'defFullAddress', autoHeight: true, width: 310, cellClass: "cell-wrap-text", cellStyle: { "white-space": "normal" }, sortingOrder: ["asc", "desc"], cellRendererFramework: DefendantaddressComponent      },
      { headerName: 'Received Date', field: 'receivedDate', width: 130, sortingOrder: ["asc", "desc"], autoHeight: true },
      { headerName: 'Status', field: 'status', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true },
      { headerName: 'Served Date', field: 'served_date', width: 140, sortingOrder: ["asc", "desc"], autoHeight: true },
      { headerName: 'Server Attempts', field: 's_CommentSeqNum', width: 100, sortingOrder: ["asc", "desc"], autoHeight: true }
    ];
    this.rowSelection = 'single';
    this.getRowHeight = function (params) {
      //  console.log(30 * (Math.floor(params.data.defFullAddress.length / 30) + 1))
      if (params.data.defFullAddress.length > 130) {
        return 50 * (Math.floor(params.data.defFullAddress.length / 76) + 1);
      } else
      return 50 * (Math.floor(params.data.defFullAddress.length / 50) + 1);
    };
  }

  resetColumns() {
    this.gridColumnApi.resetColumnState();
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  sizeToFit() {
    if (!isUndefined(this.gridApi))
      this.gridApi.sizeColumnsToFit();
    if (!isUndefined(this.gridApiSDP))
      this.gridApiSDP.sizeColumnsToFit();
  }
  onCellClicked(event: any) { }// console.log('cell', event); }
  onGridSizeChanged(params) {
    if (document.getElementById("grid-wrapper") != null) {
      var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  onGridSizeChangedSDP(params) {
    if (document.getElementById("grid-wrapperSDP") != null) {
      var gridWidth = document.getElementById("grid-wrapperSDP").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  printToScreen() {
    //console.log(this.reportSearch);
    //if (this.reportSearch.fromDate != "" && this.reportSearch.fromDate != null && !isUndefined(this.reportSearch.fromDate)) {
    //  this.reportSearch.fromDate + " 00:00:00.000" + "," + this.reportSearch.toDate + " 23:59:59.999";
    //}
    let model = this.searchModel();
    this.reportSearch.isDefault = 0;
    if (model.isDefault == 1) {
      this.displayError = true;
      this.errorText = "Please select atleast one search criterion to see the results";
      this.showGrid = false;
    } else if (model.isDefault == 2) {
      this.displayError = true;
      this.showGrid = false;
      //if (this.errorText == "" || this.errorText == null || isUndefined(this.errorText))
     // this.errorText = "Please select valid from and to dates";
    }
    else {
      if (!isUndefined(this.gridApi))
        this.gridApi.paginationGoToFirstPage();
      if (!isUndefined(this.gridApiSDP))
        this.gridApiSDP.paginationGoToFirstPage();
      this.displayError = false;
      this.errorText = "";
      this.dataservice.getReportResults(model).subscribe(data => {
        this.reportsList = JSON.parse(JSON.stringify(data));
        this.searchClicked = true;
        if (!this.dateStatus) {
          //  console.log("lasldaslkd");
          this.rowData = this.reportsList;
          this.showGrid = this.rowData.length > 0 ? true : false;

        } else {
          this.rowDataSDP = this.reportsList;
          this.showGrid = this.rowDataSDP.length > 0 ? true : false;
        }
        /*if (!isUndefined(this.reportsList) && this.reportsList.length > 0 && !isUndefined(this.gridOptions.api)) {
       //   console.log(this.gridOptions.api);
         // this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true))
          this.gridOptions.api.forEachNode((node, index) =>{
            if (index == 0) {
             // this.selectedModel = node;
              node.setSelected(true);
           //   console.log(node);
            }
          });
        }*/
      });
    }
  }
  searchClick(event) {
    // console.log(event);
    if (event.key == "Enter") {
      this.printToScreen();
    }
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = "";
    selectedRows.forEach((selectedRow, index)=> {
      this.selectedModel =selectedRow;
      //console.log(selectedRow);
      //if (index !== 0) {
      //  selectedRowsString += ", ";
      //}
      //selectedRowsString += selectedRow.athlete;
    });
   // document.querySelector("#selectedRows").innerHTML = selectedRowsString;
  }
  public onRowDataChanged(): void {
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
  }
  searchModel(): ReportsSearch {
    let job = new ReportsSearch();
   
    job.attorneyname = this.reportSearch.attorneyname;
    job.caseId = this.reportSearch.caseId === "" ? undefined : this.reportSearch.caseId;
    if (this.reportSearch.lawFirm_ID != 0) {
      job.lawFirm_ID = this.reportSearch.lawFirm_ID;
    } else {
      job.lawFirm_ID = 0;
    }
    if (this.reportSearch.priorityID != 0) {
      job.priorityID = parseInt(this.reportSearch.priorityID.toString());
    }
    else {
      job.priorityID = 0;
    }
    if (this.reportSearch.status.indexOf(" ") > 0) {
      job.status = this.reportSearch.status.replace(" ", "");
    } else {
      job.status = this.reportSearch.status;
    }
    if (job.status == "Select one" || job.status == "Selectone" || job.status == "<< Any >>" || job.status == "<<Any >>") {
      job.status = "";
    }
    if (this.reportSearch.dateFilterType != 0) {
      job.dateFilterType = parseInt(this.reportSearch.dateFilterType.toString());
    } else {
      job.dateFilterType = 0;
    }
    if (!isUndefined(job.caseId)) {
      this.dateStatus = false;
      this.caseReport = true;
      this.clientReport = false;
      this.reportSearch.reportName = this.reportName = "Case Report";
    } else if (isUndefined(job.caseId) && job.lawFirm_ID!=0) {
      this.dateStatus = false;
      this.caseReport = false;
      this.clientReport = true;
      this.reportSearch.reportName = this.reportName = "Client Report";
    } else {
      this.dateStatus = true;
      this.caseReport = false;
      this.clientReport = false;
      this.reportSearch.reportName = this.reportName = "Date or Priority or Status Filter";
    }
    job.fromDate = this.reportSearch.fromDate;
    job.toDate = this.reportSearch.toDate;
    job.isDefault = 0;
    if (job.dateFilterType != 0) {
      if (this.reportSearch.fromDate != "" && this.reportSearch.fromDate != null && !isUndefined(this.reportSearch.fromDate)
        && this.reportSearch.toDate != null && !isUndefined(this.reportSearch.toDate)) {
        if (new Date(this.reportSearch.toDate) < new Date(this.reportSearch.fromDate)) {
          job.isDefault = 2;
          job.dateFilterType = 0;
          this.errorText = "To date must be greaterthan or equal to from date ";
        } else {
          job.fromDate = this.reportSearch.fromDate + " 00:00:00.000"
          job.toDate = this.reportSearch.toDate + " 23:59:59.999";
        }
      }   else {
        this.errorText = "Please select valid from and to dates";
        job.isDefault = 2;
        job.dateFilterType = 0;
        job.fromDate = null;
        job.toDate = null;
      }
    }
    if (this.reportSearch.lawFirm_ID == 0 && this.reportSearch.priorityID == 0 && job.status == "" && this.reportSearch.dateFilterType==0) {
      if (isUndefined(job.attorneyname) && isUndefined(job.caseId)) {
        job.isDefault = 1;
      } else if (this.isEmptyOrSpaces(job.attorneyname) && this.isEmptyOrSpaces(job.caseId)) {
        job.isDefault = 1;
      }
      else {
        job.isDefault = 0;
      }
    }

    if (this.dateStatus) {
      this.reportSearch.reportName = this.reportName = this.getReportName(job);
    //console.log(job);
    }
    console.log(job);
    return job;
  }
  getReportName(report: ReportsSearch): string {
    let name = "";
    if (!isUndefined(report)) {
      if ((!isUndefined(report.status) && !this.isEmptyOrSpaces(report.status))  && report.dateFilterType != 0 && report.priorityID != 0) {

        let pName = !isUndefined(this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString()))) ? this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString())).batchPriority : "" ;
        let dname = !isUndefined(this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString()))) ? this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString())).dateTypeName:"" ;//+ " b/w " + report.fromDate + " and " + report.toDate : "";
        name = "Status, Priority and "+ dname +" Report";
        //this.showStatus = true;
        //this.showPriority = true;
        //this.showDateType = true;
        //this.showStatusInfo = report.status;
        //this.showPriorityInfo = pName;
        //this.showDateTypeInfo = dname.split('b/w')[0];
        //this.showDateInfo = dname.split('b/w')[1];
      } else if ((!isUndefined(report.status) && !this.isEmptyOrSpaces(report.status)) && report.priorityID != 0) {
        let rName = !isUndefined(this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString()))) ? this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString())).batchPriority : "";
        name = "Status and Priority Report";
        //this.showStatus = true;
        //this.showPriority = true;
        //this.showDateType = false;
        //this.showStatusInfo = report.status;
        //this.showPriorityInfo = rName;
      } else if ((!isUndefined(report.status) && !this.isEmptyOrSpaces(report.status)) &&report.dateFilterType != 0) {
        let dName = !isUndefined(this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString()))) ?this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString())).dateTypeName :"";
        name = "Status and "+ dName + " Report";
        //this.showStatus = true;
        //this.showPriority = false;
        //this.showDateType = true;
        //this.showStatusInfo = report.status;
        //this.showDateTypeInfo = dName.split('b/w')[0];
        //this.showDateInfo = dName.split('b/w')[1];
      } else if (!isUndefined(report.status) && !this.isEmptyOrSpaces(report.status)) {
        name = "Status Report";
        //this.showStatus = true;
        //this.showPriority = false;
        //this.showDateType = false;
        //this.showStatusInfo = report.status;
       
      } else if (report.dateFilterType != 0 && report.priorityID != 0) {
        let pName = !isUndefined(this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString()))) ? this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString())).batchPriority : "";
        let dname = !isUndefined(this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString()))) ? this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString())).dateTypeName : "";

        name = "Priority and " + dname + " Report";
        //this.showStatus = false;
        //this.showPriority = true;
        //this.showDateType = true;
        //this.showPriorityInfo = pName;
        //this.showDateTypeInfo = dname.split('b/w')[0];
        //this.showDateInfo = dname.split('b/w')[1];
      } else if (report.dateFilterType != 0) {
        let dname = !isUndefined(this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString()))) ? this.dateTypes.find(i => i.dateTypeID == parseInt(report.dateFilterType.toString())).dateTypeName: "";
        name = dname + " Report";
        //this.showStatus = false;
        //this.showPriority = false;
        //this.showDateType = true;
        //this.showDateTypeInfo = dname.split('b/w')[0];
        //this.showDateInfo = dname.split('b/w')[1];
      } else if (report.priorityID != 0) {
        let pname = !isUndefined(this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString()))) ? this.batchPriorityTypes.find(i => i.batchPriority_ID == parseInt(report.priorityID.toString())).batchPriority : "";
        name = "Priority Report";
        //this.showStatus = false;
        //this.showPriority = true;
        //this.showDateType = false;
        //this.showPriorityInfo = pname;

      }
    }
    return name;
  }
  isEmptyOrSpaces(str) {
    console.log(str);
    if (isUndefined(str)) {
      return true;
    }
    return str === null || str.match(/^ *$/) !== null;
  }
  exportAsXLSX(): void {
    let header: any;
      header = ["#", "Client", "Case #", "Priority", "Plaintiff Ref. #",  "Defendant", "Received Date",
        "Status", "Served Date", "Server Attempts"];
   
      let job = this.searchModel();
    if (job.isDefault == 0) {
      this.displayError = false;
      this.dataservice.getReportResults(this.searchModel()).subscribe(data => {
        var list = JSON.parse(JSON.stringify(data));
        var exportList = new Array<ReportsModel>();
        let i = 0;
        list.forEach((value, index) => {

          let model = new ReportsModel();
          model.serialNo = i == 0 ? (1 + index) : (i + index);
          //  if (this.dateStatus) {
          model.lawFirm_Name = value.lawFirm_Name;
          model.caseId = value.caseId;
          model.batchPriority = value.batchPriority;
          model.plaintiffIdentifierBilling = value.plaintiffIdentifier;
          model.defFullAddress = this.titleCaseWord(value.defFullAddress.replace("#", " ").replace("#", ""));
          model.receivedDate = value.receivedDate;
          model.status = value.status;
          model.served_date = value.served_date;
          model.s_CommentSeqNum = value.s_CommentSeqNum;
          exportList.push(model);

        });
        this.excelService.exportAsExcelFile(exportList, this.reportName.replace(" ", "_").replace(" ", "_"), header);
      });
    } else {
      this.displayError = true;
      this.errorText = "Please select at lease one criterion to download";
    }
  }
  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  printAllDisplayedRows() {
  //  console.log("ffff", this.gridApi, this.gridApiSDP);
    let count = 0;
    if (this.dateStatus)
      count = isUndefined(this.gridApiSDP)?0: this.gridApiSDP.getDisplayedRowCount();
    else
      count = isUndefined(this.gridApi) ? 0 : this.gridApi.getDisplayedRowCount();
    this.exportList = new Array<ReportsModel>();
    //console.log("## printAllDisplayedRows");
    for (var i = 0; i < count; i++) {
      var rowNode = this.dateStatus ? this.gridApiSDP.getDisplayedRowAtIndex(i) : this.gridApi.getDisplayedRowAtIndex(i);
    //  console.log(rowNode);
      let model = new ReportsModel();
      model.serialNo = i + 1;
    //  if (this.dateStatus) {
        model.lawFirm_Name = rowNode.data.lawFirm_Name;
        model.caseId = rowNode.data.caseId;
        model.batchPriority = rowNode.data.batchPriority;
        model.plaintiffIdentifierBilling = rowNode.data.plaintiffIdentifier;
        model.name = rowNode.data.name;
        model.def_name = rowNode.data.def_name;
        model.receivedDate = rowNode.data.receivedDate;
        model.status = rowNode.data.status;
        model.served_date = rowNode.data.served_date;
        model.s_CommentSeqNum = rowNode.data.s_CommentSeqNum;
      this.exportList.push(model);
   //   console.log("");
    }
  }
  dowloadSerach() {

  }
}
