import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/Core/Services/data.service';
import { HttpClient } from '@angular/common/http';
import { LawFirmModel } from 'src/app/Core/Models/CreateJob/lawFrimModel';
import { isUndefined } from 'util';
import Swal from 'sweetalert2';

@Component({
  selector: 'nh-form-dbox-excel',
  templateUrl: './form-dbox-excel.component.html',
  styleUrls: ['./form-dbox-excel.component.less']
})
export class FormDBoxExcelComponent implements OnInit {

  ComponentName: string = "Import From Excel";
  uploadForm: FormGroup;
  submitted: boolean = false;
  disableButton: boolean = false;
  lawFirmsList: Array<LawFirmModel>;
  messageToDisplay: string;
  // used for option drop down.
  validationmsgs = {
    "company": { "required": "company name is required" },
    "file": { "required": "file is required" },
    "attachments": { "maxLength": "attachment(s) is required" },
    "dropboxLink": {"required":"attachment link is required"},
    "email": { "required": "Contact Email is required", "msg": "Invalid email format" },
    "name": { "required": "Contact Name is required" },
    "batchNotes": { "required": "Rider notes is required" }
  };

  ds: DataService;
  form: FormData = new FormData();
  attachmentsError: boolean = false;
  excelFiles: any;
  attachmentFile: any;
  showSuccess: boolean = false;
  showFailed: boolean = false;
  fileError: boolean = false;
  selectedFile: string;
  selectedDocs: Array<string> = new Array<string>();
  /** UploadDoc ctor */
  constructor(private http: HttpClient, private formBuilder: FormBuilder, private _ds: DataService) {
    this.ds = this._ds;
  }

  ngOnInit() {
    this.ds.getLawFirms().subscribe(lawFirms => {
      this.lawFirmsList = lawFirms;
    });
    this.uploadForm = this.formBuilder.group({
      company: ["", [Validators.required]],
      file: [null],
      dropboxLink: ["", [Validators.required]],
      email: ["", [Validators.required,
      Validators.pattern(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/)]],
      name: ["", Validators.required],
      batchNotes: ["", Validators.required]
    },
      {
        updateOn: 'blur'
      });
  }

  onFileChangeExcel(files) {
    // this.submitted = false;
    this.form.delete("excelFile");
    if (files.target.files.length === 0) {
      this.excelFiles = undefined;
      this.selectedFile = "";
      return;

    }

    for (let file of files.target.files) {
      this.form.append('excelFile', file);
      this.excelFiles = file;
      // console.log(file.name)
      this.selectedFile = file.name;
    }

    //console.log(files.target.files)

  }

  onFileChangeAttachements(files) {
    let j = 0;
    let _this = this;
    this.selectedDocs = new Array<string>();
    //  console.log(_this.attachmentFile);
    if (!isUndefined(_this.attachmentFile) && _this.attachmentFile != null) {
      for (let file of _this.attachmentFile) {
        //  console.log("cleared");
        _this.form.delete('attachments ' + j++);
      }
    }
    if (files.target.files.length === 0) {
      this.validationmsgs.attachments.maxLength = "attachment(s) is required";
      this.selectedDocs = new Array<string>();
      this.attachmentFile = undefined;
      return;
    }

    if (files.target.files.length > 5) {

      this.validationmsgs.attachments.maxLength = "Only 5 attachments are allowed";
      this.attachmentsError = true;
      return;
    } else {
      this.attachmentsError = false;
    }


    // console.log(this.selectedDocs)
    if (isUndefined(this.attachmentFile) || this.attachmentFile == null) {
      // console.log("Testet")
      this.attachmentFile = Array.from(files.target.files);
      //var i = 0
      for (let file of files.target.files) {
        //this.form.append('attachments ' + i++, file);
        this.selectedDocs.push(file.name);
      }
    }
    else {
      //var i = 0
      for (let file of files.target.files) {
        if (this.attachmentFile.findIndex(f => f.name == file.name) < 0)
          this.attachmentFile.push(file);
      }

      for (let file of this.attachmentFile) {
        //this.form.append('attachments ' + i++, file);
        this.selectedDocs.push(file.name);
      }

    }
    //console.log(this.attachmentFile);
  }

  get formControls() { return this.uploadForm.controls; }
  changeColor(event: Event) {
    event.srcElement.className = event.srcElement.className.replace("error", "");
    // console.log();
  }
  submit(event: Event) {
    event.preventDefault();
    this.submitted = true;

    if (this.uploadForm.invalid) {
      return false;
    }
    if (isUndefined(this.excelFiles) || this.excelFiles == null) {
      this.fileError = true;
    } else {
      this.fileError = false;
    }
   /* if (isUndefined(this.attachmentFile) || this.attachmentFile == null || this.attachmentFile.length == 0) {
      this.attachmentsError = true;
    } else {
      this.attachmentsError = false;
    }

    if (this.attachmentsError || this.fileError) { return false; }

    if (!isUndefined(this.attachmentFile) && this.attachmentFile != null) {
      var i = 0
      for (let file of this.attachmentFile) {
        this.form.append('attachments ' + i++, file);
      }
    }*/

    this.form.append('lawFirm_ID', this.formControls.company.value);
    this.form.append('lawFirm_Name', this.lawFirmsList.find(i => i.lawFirm_ID == this.formControls.company.value).lawFirm_Name);
    this.form.append('lawFirm_API_KEY', this.lawFirmsList.find(i => i.lawFirm_ID == this.formControls.company.value).lawFirm_API_KEY);
    this.form.append('LawFirm_Identifier', this.lawFirmsList.find(i => i.lawFirm_ID == this.formControls.company.value).lawFirm_Identifier);
    this.form.append('email', this.formControls.email.value);
    this.form.append('name', this.formControls.name.value);
    this.form.append('batchNotes', this.formControls.batchNotes.value);
    this.form.append('dropboxLink', this.formControls.dropboxLink.value);
    // this.form.append('pageCount', this.formControls.pageCount.value);
    this.disableButton = true;
    this.ds.postExcelFile(this.form)
      .subscribe(data => {

        // alert(data);
        if (!isUndefined(data)) {
          this.messageToDisplay = data.message;
          this.showSuccess = data.isSuccess;
          if (!this.showSuccess) {
            // console.log(this.disableButton);
            this.disableButton = false;
            this.showFailed = true;
          } else {
            Swal.fire(
              'success',
              this.messageToDisplay,
              'success'
            );
            this.showFailed = false;
          }
        }
        var _this = this;

        setTimeout(function () {
          Object.keys(_this.uploadForm.controls).forEach(key => {
            _this.uploadForm.controls[key].setErrors(null)
          });
          if (_this.showSuccess) {
            _this.uploadForm.reset(); _this.uploadForm.markAsUntouched();
            _this.submitted = false;
            // _this.myForm.resetForm();
            _this.form.set('email', '');
            _this.form.set('name', '');
            _this.form.set('batchNotes', '');

            _this.attachmentsError = false;
            _this.showSuccess = false;
            _this.form = new FormData();
            (<HTMLInputElement>document.querySelector('#excel')).value = null;
            (<HTMLInputElement>document.querySelector('#name')).value = "";
            (<HTMLInputElement>document.querySelector('#batchNotes')).value = "";
            (<HTMLInputElement>document.querySelector('#contactEmail')).value = "";
            (<HTMLInputElement>document.querySelector('#company')).value = "";
            (<HTMLInputElement>document.querySelector('#dropboxLink')).value = "";

            _this.showFailed = false;
            _this.disableButton = false;
            _this.messageToDisplay = "";
            _this.excelFiles = null;
            _this.attachmentFile = null;
            _this.selectedDocs = null;
            _this.selectedFile = "";
            //console.log(this.disableButton);
          } else {
            if (data.message == "Attachments are mismatched please verify and resubmit.") {
              let i = 0;
              for (let file of _this.attachmentFile) {
                _this.form.delete('attachments ' + i++);
              }
              (<HTMLInputElement>document.querySelector('#attachment')).value = null;
            } else if (data.message == "Not a Valid EXCEl file please check the sample EXCEL") {
              _this.form.delete("excelFile");
              (<HTMLInputElement>document.querySelector('#excel')).value = null;
            }
          }
        }, 1000);

      });
    //console.log('uploading');
  }
  removeDocument(docuName: string) {
    if (!isUndefined(this.selectedDocs) && this.selectedDocs != null) {
      let index = this.selectedDocs.findIndex(i => i == docuName);
      this.selectedDocs.splice(index, 1);
      this.attachmentFile.splice(this.attachmentFile.findIndex(i => i.name == docuName), 1);
    }
  }
  triggerFileUpload(elementId: string) {
    document.getElementById(elementId).click();
  }

}
