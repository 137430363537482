import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { Userdetails } from 'src/app/Core/Models/UserManagment/userdetails';
import { GridOptions } from 'ag-grid-community';
import { isUndefined } from 'util';
import { MutlidropdownComponent } from './mutlidropdown.component';
import { AgGridCellRenderer } from './multidropdownrenderer';
import { SaveUserButtonRenderer } from './SaveUserButtonRenderer';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { DropdownComponent } from './dropdowncomponent';
import { DropDownCellRenderer } from './dropdownrenderer';

@Component({
  selector: 'nh-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.less']
})
export class UsermanagementComponent implements OnInit {
  private gridApi;
  private gridColumnApi;

  private columnDefs;
  private rowData;
  private frameworkComponents;
 
  private rowSelection;
  errorMessage: string = "";
  overlayNoRowsTemplate: string;
  private gridOptions: GridOptions;
  constructor(private dataService: DataService, private auth: AuthenticateService) { this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>'; }
  usersList: Array<Userdetails>;

  ngOnInit() {

    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }
    };
    this.columnDefs = [
      {
        headerName: 'Serial', field: 'serial', width: 30
      },
      { headerName: 'Name', field: 'firstName', tooltipField: 'firstName', filter: true, width: 100},
      { headerName: 'Email', field: 'email', tooltipField: 'email', filter: true, width: 100 },
      { headerName: 'User Name', field: 'userName', tooltipField: 'userName', filter: true, width: 100 },
      { headerName: 'Roles', editable: true, field: 'role', cellRenderer: "cellRenderer", cellEditor: "cellEditor", width: 100 },
      { headerName: 'Clients', editable: true, field: 'lawFirmNames', cellRenderer: "dropDownCellRenderer", cellEditor: "dropDwonComponent", width: 100 },
    ];

    this.frameworkComponents = {
      cellRenderer: AgGridCellRenderer,
      cellEditor: MutlidropdownComponent,
      SaveUserButtonRenderer: SaveUserButtonRenderer,
      dropDwonComponent: DropdownComponent,
      dropDownCellRenderer: DropDownCellRenderer
      //numericEditor: NumericEditor
    };

    this.dataService.getallusers().subscribe(data => {
      this.usersList = JSON.parse(JSON.stringify(data));
      let ind = this.usersList.findIndex(i => i.id == this.auth.getLoggedInUser().id);
      this.usersList.splice(ind, 1);
      let i = 0;
      this.usersList.forEach((v) => {
        v.serial = i + 1;
        i++;
      })
      this.rowData = this.usersList;
    });

   // this.rowData = this.popupConfig.list;
    this.rowSelection = 'multiple';
  }

  onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }

  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  XMLCellRenderer(params) {
    // console.log(params);
    if (!isUndefined(params.value) && params.value != null && params.value != "") {
      var flag = "";
      for (var i = 0; i < params.value.length; i++) {
        flag = flag + " <input type='checkbox' (change)='checkedBox()' [(ngModel)]='" + params.value[i].isSelected+"' style='padding:0.025em'/>" + params.value[i].roleType ;
      }
      return flag;
    } else {
      return "<span>N/A</span>"
    }
  }

  checkedBox() {
    console.log("dsfsd");
  }

}
