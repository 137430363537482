export class JobsDashboardSearch {
  lawFirm_ID: number;
  attorneyname: string;
  def_name: string
  dateFilterType: number;
  fromDate: string;
  toDate: string;
  status: string;
  priorityID: number;
  isDefault: number;
  caseId: string;
  jobID: number;
}
