import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'nh-resetpassword',
  templateUrl: `./ResetPassword.template.html`,
})

export class ResetPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  Loading = false;
  Submitted = false;
  Success = false;
  data: any;

  validationmsgs = {
    "password": {
      "required": "Password is required",
      "minlength": "Minimum length 8 chars",
      "maxlength": "Maximum 16 characters long",
      "onenumber": "your password must have atleast one number between 0 - 9",
      "uppercasealphabet": "your password must have atleast one upper case alphabet",
      "match": "password did not matched"
    }
  };
  
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private auth: AuthenticateService
             ) {
  }

  get f() { return this.passwordForm.controls }

  ngOnInit() {

    this.passwordForm = this.formBuilder.group({
                          newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
                          confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
                        });

  }

  passwordmatch() {
    //  match password and conform-password validation
    if (this.passwordForm.value.newPassword != '' && this.passwordForm.value.confirmPassword != '') {
      if (this.passwordForm.value.newPassword !== this.passwordForm.value.confirmPassword) {
        this.passwordForm.controls.confirmPassword.setErrors({ match: true });
      } else {
        this.passwordForm.controls.confirmPassword.setErrors({ match: false });
      }
    }
  }

  loginPage() {
    console.log('login-page');
  }

  newPassword() {

    console.log(" submitting the new password ");

    var token = this.route.snapshot.url[0].path;

    this.auth
      .changePassword(this.f.newPassword.value, token)
      .subscribe(
        (response) => {

          try {
            this.data = response;
          } catch (e) {
            console.log(e)
          }

        },
        (err: HttpErrorResponse) => {
          console.log('error: ', err.error);
          console.log('error-Name: ', err.name);
          console.log('error-Message: ', err.message);
          console.log('error-Status: ', err.status);
        });

  }

}

