import { Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UserRoles } from 'src/app/Core/Models/UserManagment/userdetails';
import { DataService } from 'src/app/Core/Services/data.service';
import _ from 'lodash';
@Component({
  selector: 'nh-mutlidropdown',
  template: `
        <div #container class="mood" tabindex="0" (keydown)="onKeyDown($event)">
           
<div  *ngFor="let role of UserRoles" style="display: flex;padding: 0.25em;flex-flow: row;justify-content: flex-start;align-items: center;">
<input type="checkbox" (change)="changed(role);"  [(checked)]="role.isSelected"/><span>{{role.roleType}}</span>
</div>

<button type="button"  class="btnGreen" (click)="updateUserRoles()">Update</button>
        </div>
    `,
  styles: [`
        .mood {
          flex-basis:100%;
        flex-flow:row wrap;

        }

        .default {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid transparent;
            padding: 4px;
        }

        .selected {
            padding-left: 10px;
            padding-right: 10px;
            border: 1px solid lightgreen;
            padding: 4px;
        }
    `]
})
   //<img src="https://www.ag-grid.com/images/smiley.png" (click)="onClick(true)" [ngClass]="{'selected' : happy, 'default' : !happy}">
            //<img src="https://www.ag-grid.com/images/smiley-sad.png" (click)="onClick(false)"
            //     [ngClass]="{'selected' : !happy, 'default' : happy}">
export class MutlidropdownComponent implements ICellEditorAngularComp, AfterViewInit  {
  private params: any;
  UserRoles: Array<UserRoles>;
  @ViewChild('container', { read: ViewContainerRef }) public container;
  public happy: boolean = false;
  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    })
  }
  constructor(private dataService: DataService) {}
  agInit(params: any): void {
    this.params = params;
   // console.log(this.params.data);
    this.UserRoles = this.params.data.userRoles;
   
    if (this.params.value == "") {

    } else {
      var _this = this;
      if (this.params.value.split(',').length > 0) {
         console.log(this.params.value)
        let user = this.params.value.split(',');
        console.log(user);
        user.forEach(function (v) {
          console.log(v);
          _this.updateCheckboxes(v);
        })
      } else {
        this.updateCheckboxes(this.params.value);
      }
    }
    
    //console.log(this.UserRoles);
    this.setHappy(params.value === "Happy");
  }

  updateCheckboxes(name: string) {
        this.UserRoles[this.UserRoles.findIndex(i => i.roleType == name)].isSelected = true;
        this.UserRoles[this.UserRoles.findIndex(i => i.roleType == name)].user_id = this.params.data.id;
  }
  changed(userRole: UserRoles) {
    if (userRole.isSelected)
      userRole.isSelected = false
    else
      userRole.isSelected = true;
    let index = this.UserRoles.findIndex(i => i.role_ID == userRole.role_ID);
   // console.log(userRole.isSelected);
    this.UserRoles[index].isSelected = userRole.isSelected;
    this.UserRoles[index].user_id = this.params.data.id;
    //console.log(this.UserRoles);
  }
  getValue(): any {
    let buffer: string="";
    var _this = this;
   
    this.UserRoles.forEach(function (v, index) {
      if (v.isSelected) {
        if (index == 0 || index == _this.UserRoles.length) {
          buffer = v.roleType;
        } else {
          buffer = (buffer == "") ? v.roleType : buffer + "," + v.roleType;
        }
      }
    });

    return buffer;
  }

  isPopup(): boolean {
    return true;
  }

  setHappy(happy: boolean): void {
    this.happy = happy;
  }

  toggleMood(): void {
    this.setHappy(!this.happy);
  }

  onClick(happy: boolean) {
    this.setHappy(happy);
    this.params.api.stopEditing();
  }

  onKeyDown(event): void {
    let key = event.which || event.keyCode;
    if (key == 37 ||  // left
      key == 39) {  // right
      this.toggleMood();
      event.stopPropagation();
    }
  }
  updateUserRoles() {
    var selectedRoles = _.filter(this.UserRoles, function (data) {
      return data.isSelected;
    });
    this.dataService
      .updatedUserRoles(selectedRoles)
      .subscribe(data => {
        //    console.log("response ... ", data);
        let buffer: string = "";
        if (data) {
          selectedRoles.forEach((v, index) => {

            if (index == 0 || index == selectedRoles.length) {
              buffer = v.roleType;
            } else {
              buffer = (buffer == "") ? v.roleType : buffer + "," + v.roleType;
            }

          });
        }
        this.params.data.role = buffer;
        this.params.stopEditing();
      });
  }

}
