import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { nhadminrouting } from './nhadminrouter';
import { ClientlistComponent } from './clientlist/clientlist.component';
import { PendingservicesComponent } from './pendingservices/pendingservices.component';
import { ResetuserComponent } from './resetuser/resetuser.component';
import { AgGridModule } from "ag-grid-angular";
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { ClientdetailsComponent } from './clientlist/clientdetails/clientdetails.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MutlidropdownComponent } from './usermanagement/mutlidropdown.component';
import { AgGridCellRenderer } from './usermanagement/multidropdownrenderer';
import { SaveUserButtonRenderer } from './usermanagement/SaveUserButtonRenderer';
import { DropdownComponent } from './usermanagement/dropdowncomponent';
import { DropDownCellRenderer } from './usermanagement/dropdownrenderer';
import { JobsdashboardComponent } from './jobsdashboard/jobsdashboard.component';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { ReportsComponent } from './reports/reports.component';
import { DefendantaddressComponent } from './reports/defendantaddress/defendantaddress.component';

@NgModule({
  declarations: [RegisterComponent, ClientlistComponent, PendingservicesComponent, ResetuserComponent, UsermanagementComponent,
    ClientdetailsComponent, MutlidropdownComponent, AgGridCellRenderer, SaveUserButtonRenderer, DropdownComponent, DropDownCellRenderer, JobsdashboardComponent, PdfviewerComponent, ReportsComponent, DefendantaddressComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([ClientlistComponent, ClientdetailsComponent, MutlidropdownComponent, AgGridCellRenderer, SaveUserButtonRenderer, DropdownComponent, DropDownCellRenderer]),
    nhadminrouting,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  entryComponents: [PdfviewerComponent,MutlidropdownComponent, AgGridCellRenderer, DropDownCellRenderer]

})

export class NhadminModule { }
