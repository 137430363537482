import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'registration-page',
  template: `<div  class="alert alert-info">
              <p> registration  successful  <a href="javascript:void(0);" (click)=backToLoginPage()> click here </a> to login to your newly created account </p>
             </div>`
})

export class RegistrationPageComponent {

  constructor(private router: Router) {
    this.router.navigate(['authentication']);
  }

  backToLoginPage() {
     console.log("rougint to login page");
  }

}

