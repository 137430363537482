import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';

@Component({
  selector: 'nh-createjobs',
  templateUrl: './CreateJobs.component.html',
  styleUrls: ['./CreateJobs.component.less']
})

/** batch component*/
export class CreateJobsComponent implements OnInit {
 
  components = ['FromExcel', 'FromForm'];
  selected = { item: 'FromForm' };
  ComponentName = "Create-Jobs";
  showExcel: boolean = true;
  showForm: boolean;

  /** batch ctor */
  constructor(private dataservice: DataService) {}

  ngOnInit(): void {
    this.dataservice
      .getLawFirms()
      .subscribe(lawFirmsList => {
     // console.log(lawFirmsList);
    });
    //throw new Error("Method not implemented.");
  }

  searchValues(data: any) {
    try {
      if (data == "fromExcel") {
        this.showExcel = true; this.showForm = false;
      } else {
        this.showForm = true; this.showExcel = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

}

