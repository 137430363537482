export class ReportsSearch {
  lawFirm_ID: number;
  attorneyname: string;
  caseId: string;
  dateFilterType: number;
  fromDate: string;
  toDate: string;
  status: string;
  priorityID: number;
  isDefault: number;
  reportName: string;
}
