import { Component, OnInit, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import _ from 'lodash';
import { isUndefined } from 'util';
import { Role } from 'src/app/Core/Models/Admin/NHAdmin/role';
import { DataService } from 'src/app/Core/Services/data.service';
import { isEmpty } from 'rxjs/operators';
import { LawFirmModel } from 'src/app/Core/Models/CreateJob/lawFrimModel';

@Component({
  selector: 'nh-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent implements OnInit {

  private registerForm: FormGroup;
  private registerLoading = false;
  private registerSubmitted = false;
  private registerSuccess = false;
  public errorMessages: any;
  public error: string;
  public signupState = false;
  public EmptyForm = false;
  public UserNameError = false;
  public EmailError = false;
  public LawFirmError = false;
  password: string;
  public passwordError = false;
  differ: KeyValueDiffer<string, any>;
  listRoles: Array<Role>;
  lawFirmsList: Array<LawFirmModel>;
  selectedValue: number = 0;
  setClass() { }
  lawFirmSelectedValue: number=0;
  showClientsDropdown: boolean;
   selectedItems = [];
  dropdownSettings = {};
  // email please enter email in the following format – abc@xyz.com
  validationmsgs = {
    "username": {
      "required": "Username is required",
      "minlength": "Username should be atleast 4 characters long",
      "maxlength": "Username cannot be more than 16 characters long",
      "pattern": "Username cannot contain special characters",
    },
    "firstname": {
      "required": "Firstname is required",
      "minlength": "Firstname should be atleast 4 characters long",
      "maxlength": "Firstname cannot be more than 16 characters long",
      "pattern": "Firstname cannot contain special characters"
    },
    "lastname": {
      "required": "Lastname is required",
      "minlength": "Lastname should be atleast 4 characters long",
      "maxlength": "Lastname cannot be more than 16 characters long",
      "pattern": "Lastname cannot contain special characters"
    },
    "password": {
      "required": "Password is required",     
    },
    "email": {
      "required": "Email is required",
      "msg": "Invalid email format",
    },
    "registration_successful": "User added successfully.",
    "empty_fields": "Some of the required fields are empty.",
    "required": "All fields are required. Except Location",
    "redirect": "go back to sign in page.",
    "lawFirm": {
      "required": "please select lawfirm"
    }
  };

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    public differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
  }

  //ngDoCheck() {
  //  const change = this.differ.diff(this);

  //  if (change) {
  //    change.forEachChangedItem(item => {
  //      console.log(item);
  //    });
  //  }

  //}

  ngOnInit() {
    this.listRoles = new Array<Role>();
    if (this.dataService.userRoleName != null && !isUndefined(this.dataService.userRoleName) && this.dataService.userRoleName.indexOf("KSAdmin") >= 0) {
      this.listRoles.push({ roleid: 4, roleName: "Client Admin" });
      this.listRoles.push({ roleid: 5, roleName: "Client Staff" });
      this.listRoles.push({ roleid: 3, roleName: "NH Staff" });
      this.listRoles.push({ roleid: 2, roleName: "NH Admin" });

    } else {
      this.listRoles.push({ roleid: 3, roleName: "NH Staff" });
      this.listRoles.push({ roleid: 2, roleName: "NH Admin" });
    }
    this.selectedValue = 3;
    this.registerForm = this.formBuilder.group({
      UserName: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(16),
        Validators.pattern('^[a-zA-Z0-9]*$')
      ]],
      FirstName: ['', [
        Validators.required
      ]],
      LastName: ['', [
        Validators.required
      ]],
      Email: ['', [
        Validators.required,
        Validators.pattern(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/), ,
      ]],
      //RegisterPassword: ['', [
      //  Validators.required,
      //]],
      //ConfirmPassword: ['', [
      //  Validators.required,
      //  Validators.minLength(8),
      //  Validators.maxLength(16)
      //]],
      Location: ['']
    },
      {
        updateOn: 'blur'
      });
  }

  redirect() {
    this.signupState = true;
    var _this: any;
    _this = this;
    setTimeout(function () {
      console.log(this.router);
      _this.router.navigate(['authentication']);
    }, 3000);
  }

  passwordCheck(password) {
    if (!isUndefined(password)) {
      // Password validation
      if (password.match(/\d+/g) == null) {
        return false;
      } else if (password.match(/[A-Z]/g) == null) {
        return false;
      } else if (password.match(/[A-Z]/g) == null) {
        return false;
      } else {
        return true;
      }
    }
  }

  passwordmatch() {

   // this.passwordCheck();

    // confirm password numeric validation
    if (this.registerForm.value.ConfirmPassword != '') {
      if (this.registerForm.value.ConfirmPassword.match(/\d+/g) == null) {
        this.registerForm.controls.ConfirmPassword.setErrors({ onenumber: true });
      } else {
        this.registerForm.controls.ConfirmPassword.setErrors({ onenumber: false });
      }
    }

    //  match password and conform-password validation
    if (this.registerForm.value.RegisterPassword != '' && this.registerForm.value.ConfirmPassword != '') {
      if (this.registerForm.value.RegisterPassword !== this.registerForm.value.ConfirmPassword) {
        this.registerForm.controls.ConfirmPassword.setErrors({ match: true });
      } else {
        this.registerForm.controls.ConfirmPassword.setErrors(null);
      }
    }

  }

  // short hand notation for registerform.controls
  get f() { return this.registerForm.controls }
  isEmptyOrSpaces(str) {
    if (!isUndefined(str))
    return str === null || str.match(/^ *$/) !== null;
  }
  passwordReset() {
    console.log('password resetting');
    this.registerForm.value.ConfirmPassword = "";
  }
  selectionChanged() {

    if (this.selectedValue == 4 || this.selectedValue == 5) {
      this.selectedItems = [];
      this.showClientsDropdown = true;
      this.dataService.getLawFirms().subscribe(lawFirms => {
        this.lawFirmsList = lawFirms;
        this.dropdownSettings = {
         singleSelection: false,
          idField: 'lawFirm_ID',
          textField: 'lawFirm_Name',
         selectAllText: 'Select All',
         unSelectAllText: 'UnSelect All',
          itemsShowLimit:3,
         allowSearchFilter: true,
          limitSelection: this.selectedValue == 4 ? 5 : 1
       };
      });
      this.lawFirmSelectedValue = 9;
    } else
      this.showClientsDropdown = false;
  }
  lawFirmSelectionChanged() {
    //console.log(this.lawFirmSelectedValue);
  }
  registerSubmit() {


    
   
   // return;
    //console.log(this.password);
    if (isUndefined(this.password) || this.isEmptyOrSpaces(this.password)) {
      this.passwordError = true;
      return;
    }
    //this.passwordmatch();
    this.registerSubmitted = true;
   
    if (this.EmailError) {
      this.EmailError = false;
    }
    if (this.passwordError) {
      this.passwordError = false;
    }
    if (this.LawFirmError)
      this.LawFirmError = false;
    if (this.selectedValue == 4 || this.selectedValue == 5) {
      if (this.selectedItems == null || isUndefined(this.selectedItems) || this.selectedItems.length == 0) {
        this.LawFirmError = true;
        return;
      }
    //  return;
    }
    //return;
    if (this.registerForm.invalid) {
     // console.log('invalid:- values ', this.f.UserName.value, this.f.FirstName.value, this.f.RegisterPassword.value, this.f.Location.value, this.f.Email.value);
      this.EmptyForm = true;
      return;
    }

   // console.log('valid form');
    this.EmptyForm = false;
   
    type UserDetails = {
      UserName: string;
      FirstName: string;
      LastName: string;
      Password: string;
      Location: string;
      Email: string;
      RoleID: number;
      LawFirm_ID: string;
    }
    

    const payload = {} as UserDetails;
    if (this.selectedValue == 4 || this.selectedValue == 5) {
      payload.LawFirm_ID = "";
      this.selectedItems.forEach((v, index) => {
        if (index == 0 || index == this.selectedItems.length) {
          payload.LawFirm_ID = payload.LawFirm_ID + v.lawFirm_ID;
        } else {
          payload.LawFirm_ID = payload.LawFirm_ID + "," + v.lawFirm_ID;
        }
      });
    } else {
      payload.LawFirm_ID = "0";
    }
    

    payload.UserName = this.f.UserName.value;
    payload.FirstName = this.f.FirstName.value;
    payload.LastName = this.f.LastName.value;
    payload.Password = this.password;
    payload.Location = this.f.Location.value;
    payload.Email = this.f.Email.value;
    payload.RoleID = this.selectedValue;
    //payload.LawFirm_ID = this.selectedItems.map;


    this.dataService.addNewUser(payload)
      .subscribe(
      result => {
       // console.log(result);
          if (result.error) {

            if (result.fieldName == "UserName") {
              this.UserNameError = true;
              this.error = result.message;
            }

            if (result.fieldName == "Email") {
              this.EmailError = true;
              this.error = result.message;
            }

          } else {

            if (result.errorCode == -1) {
              this.EmailError = true;
              this.error = "Email already exists.";
            } else if (result.errorCode == -2) {
              this.UserNameError = true;
              this.error = "Username already exists.";
            }

            else {
              this.signupState = true;
              this.registerSuccess = true;
              this.registerForm.reset();
              this.showClientsDropdown = false;
              setTimeout(() =>{
                this.signupState = false;
                this.selectedValue = 3;
              }, 3000);
              this.password = "";
            }
          }

        },
        err => this.error = 'Could not authenticate'
      );

    // remove this if we have register service from authentication-service.
    //this.register = false;
    this.registerSuccess = true;

  }
  generatePassword() {
   let min = Math.ceil(8);
    let max = Math.floor(12);
    let len = Math.floor(Math.random() * (max - min)) + min;
    //console.log(len);
    this.password = this.createPassword(len);
    //console.log(len, this.password);
  }
  createPassword(length) {
    var alpha = "abcdefghijklmnopqrstuvwxyz";
    var caps = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var numeric = "0123456789";
   // var special = "!$^&*-=+_?";

    var options = [alpha, caps, numeric];//special

    var password = "";
    var passwordArray = Array(length);

    for (let i = 0; i < length; i++) {
      var currentOption = options[Math.floor(Math.random() * options.length)];
      var randomChar = currentOption.charAt(Math.floor(Math.random() * currentOption.length));
      password += randomChar;
      passwordArray.push(randomChar);
     // console.log(password);
    }

    //return password;
    //var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890";
    //var pass = "";
    //for (var x = 0; x < length; x++) {
    //  var i = Math.floor(Math.random() * chars.length);
    //  pass += chars.charAt(i);
    //}
    if (!isUndefined(password) && password != undefined && password != "" && this.passwordCheck(password)) {
      return password;
    }
    else {
      
      setTimeout(() => {
        this.generatePassword();
      }, 1000 / 60);
     
    }
  }
}
