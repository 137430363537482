import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IStreamResult } from '@aspnet/signalr'

@Injectable({
  providedIn: 'root'
})

export class SignalRService {

  connectionEstablished = new EventEmitter<Boolean>();
  wokrAreaStarted = new EventEmitter<Boolean>();
  wokrAreaClosed = new EventEmitter<Boolean>();
  public connectionIsEstablished = false;
  private _stockHubConnection: HubConnection;

  constructor() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    
    this._stockHubConnection = new HubConnectionBuilder()
      .withUrl('/workarea')
      .build();
  }

  private startConnection(): any {
    this._stockHubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('stock connection started');
        this.connectionEstablished.emit(true);
      }).catch(err => {
        setTimeout(this.startConnection(), 5000);
      });
    this._stockHubConnection.serverTimeoutInMilliseconds = 60000;
  }

  private registerOnServerEvents(): void {
    this._stockHubConnection.on("wokrAreaStarted", () => {
      console.log("wokrAreaStarted");
      this.wokrAreaStarted.emit(true);
    });

    this._stockHubConnection.on("wokrAreaClosed", () => {
      console.log("wokrAreaClosed");
      this.wokrAreaClosed.emit(true);
    });
  }

  public startStreaming(): IStreamResult<any> {
    return this._stockHubConnection.stream("StreamWorkArea");
  }

  public getAllWorkArea(): Promise<any> {
    return this._stockHubConnection.invoke("GetAllWorkArea");
  }

  public openWorkArea() {
    this._stockHubConnection.invoke("StartWorkArea");
  }

  public CloseWorkArea() {
    this._stockHubConnection.invoke("CloseWorkArea");
  }

  public ResetMarket() {
    this._stockHubConnection.invoke("Reset");
  }

}
