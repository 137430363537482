import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DataService } from 'src/app/Core/Services/data.service';
import { isUndefined } from 'util';
import { AdminSelectionList, SearchModel } from 'src/app/Core/Models/Admin/admin-model';
import { PopupService } from './../../../Shared/Popup/popup.service';
import { PopupComponent } from '../../../Shared/Popup/popup.component';
import { ClientdetailsComponent } from './clientdetails/clientdetails.component';
import * as _ from 'lodash';
import { Clientmodel } from 'src/app/Core/Models/Clients/clientmodel';

import { ExcelService } from 'src/app/Core/Services/ExcelService/excel.service';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import moment from 'moment';
@Component({
  selector: 'nh-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.less']
})
export class ClientlistComponent implements OnInit {
  firstValue: string="";
  secondValue: string="";
  listCount: number;
  private gridApi;
  private gridColumnApi;
  private rowSelection;
  errorMessage: string = "";
  errorMessage1: string = "";
  overlayNoRowsTemplate: string;
  private gridOptions: GridOptions;
  public rowData: any[];
  private columnDefs: any[];
  selectedValue: number = 0;
  showTextBox: boolean;
  turnPopupTrigger = false;
  searchedResult: any;
  clientsList: Array<Clientmodel>;
  clientsListFiltered = new Array<Clientmodel>();
  gridData: Array<Clientmodel>;
  nodata: boolean;

  clientAdminStaff: boolean;
  listArray: Array<AdminSelectionList> = new Array<AdminSelectionList>();
  constructor(private dataService: DataService, private popupService: PopupService, private excelService: ExcelService, private auth: AuthenticateService) {
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';

  }

  ngOnInit() {

    this.listArray.push({ name: "Case Number", id: 1 });
    this.listArray.push({ name: "JobID", id: 2 });
    this.listArray.push({ name: "Plaintiff Name", id: 3 });
    this.listArray.push({ name: "Ordernumber", id: 4 });
    this.listArray.push({ name: "7 days (Default)", id: 5 });
    this.listArray.push({ name: "30 days", id: 6 });
    this.listArray.push({ name: "Date Range", id: 7 });
    this.selectedValue = 5;
    this.gridColumnsArragment(0)

    //       select Batch_ID, index_no, FirstName, LastName, Notes, CourtName, CountyName, LeadDefendantName, recCreated_by, PlaintiffIdentifier,

    //DocumentType, CaseFiledDate from tblplaintiffs  order by 1 desc
    //LawFirm_Name	Name	CourtName	CourtCountyName	LeadDefendantName	PlaintiffIdentifier	PlaintiffIdentifierBilling	LawFirm_ID	def_name	status	JobID	OrderNumber	ReceivedDate	Processed_date	case_num	plaintiff_ID	def_id	PlaintiffDef_ID
    // LawFirm_Name Name    CourtName CourtCountyName LeadDefendantName PlaintiffIdentifier
    //   PlaintiffIdentifierBilling LawFirm_ID  def_name status  JobID OrderNumber ReceivedDate
    // Processed_date  case_num plaintiff_ID    def_id PlaintiffDef_ID

    this.rowData = [];
    this.Search();
  }
  onGridReady(params) {
    //console.log("loaded");
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
   // params.api.sizeColumnsToFit();
    //this.resetColumns();
    //
    //this.onGridSizeChanged(params);
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = "";
    selectedRows.forEach(function (selectedRow, index) {
      if (index !== 0) {
        selectedRowsString += ", ";
      }
      selectedRowsString += selectedRow.serialNo;

    });
    //document.querySelector("#selectedRows").innerHTML = selectedRowsString;
    if (this.turnPopupTrigger)
      this.popupService.open(ClientdetailsComponent, { data: { message: selectedRows }, pageName: "clientdetails", list: JSON.parse(JSON.stringify(selectedRows[0].clients)), vH: 70, vW: 76 });
  }
  @ViewChild('viewDetailsPopup') viewDetail: PopupComponent;

  gridColumnsArragment(type: number) {
    //if (type == 0) {
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
      //  console.log("model updated");
        this.gridOptions.api.sizeColumnsToFit();
      },
    };
   // this.gridOptions.rowStyle = { "font-family": "IBM Plex Sans, sans-serif"}
    this.columnDefs = [
      {
        headerName: '#', field: 'serialNo', width: 45, sortingOrder: ["asc", "desc"]
      },
      { headerName: 'Client ', field: 'lawFirm_Name', width: 150, filter: true, sortingOrder: ["asc", "desc"]},
      { headerName: 'Plaintiff ', field: 'name', tooltipField: 'name', width: 130, filter: true, sortingOrder: ["asc", "desc"]},
      { headerName: 'Lead Defendant', field: 'leadDefendantName', tooltipField: 'leadDefendantName', filter: true, width: 180, sortingOrder: ["asc", "desc"]},
      { headerName: 'Plaintiff Ref. #', field: 'plaintiffIdentifier', tooltipField: 'plaintiffIdentifier', width: 130, filter: true, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
      { headerName: 'Plaintiff Billing Ref. #', field: 'plaintiffIdentifierBilling', tooltipField: 'plaintiffIdentifier', width: 150, filter: true, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
      { headerName: 'Received Date', field: 'receivedDate', tooltipField: 'receivedDate', width: 140, filter: true, sort: "desc", sortingOrder: ["asc", "desc"]},
      { headerName: 'Processed Date', field: 'processed_date', tooltipField: 'processed_date', width: 140, filter: true, sortingOrder: ["asc", "desc"]},
      { headerName: 'Served Status', field: 'service_Status', tooltipField: 'service_Status', width: 140, filter: true, sortingOrder: ["asc", "desc"]},
      { headerName: 'Served Date ', field: 'served_date', tooltipField: 'served_date', width: 140, filter: true, sortingOrder: ["asc", "desc"]},
    ];
    this.rowSelection = 'single';
  }
  resetColumns() {
    this.gridColumnApi.resetColumnState();
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  sizeToFit() {
    // console.log("loaded")
    if (!isUndefined(this.gridApi))
    this.gridApi.sizeColumnsToFit();
  }
  onGridSizeChanged(params) {
    if (document.getElementById("grid-wrapper") != null) {
      var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }
  exportAsXLSX(): void {
    let header = ["#", "Client", "Plaintiff", "Lead Defendant", "Plaintiff Ref. #", "Plaintiff Billing Ref. #", "ReceivedDate", "Processed Date",
      "Served Status","Served Date"]
    this.printAllDisplayedRows()
   // console.log(this.clientsListFiltered.map(Object.values));
    this.excelService.exportAsExcelFile(this.clientsListFiltered, 'clientlist', header);
  }
  XMLCellRenderer(params) {
    // console.log(params);
    if (!isUndefined(params.value) && params.value != null && params.value != "") {
      var flag = "<a target='_blank' style='color:blue;text-decoration:underline' href=" + params.value + " >" + "XML" + "</a>";
      return flag;
    } else {
      return "<span>N/A</span>"
    }
  }
  //DateValidation() {
  //  if (this.selectedValue==7) {
  //    var caseFiledDate = moment(this.firstValue, 'YYYY-MM-DD').toDate();

  //    if (moment(caseFiledDate).isBefore(moment((new Date()).setFullYear(1900)))) {
  //      // console.log(moment(caseFiledDate).isBefore(moment((new Date()).setFullYear(1900))), moment(this.batchFromGroup.controls.caseFiledDate.value, 'YYYY-MM-DD').toDate());
  //      // this.batchFromGroup.controls.caseFiledDate.setErrors({ min: true });
  //    } else if (moment(caseFiledDate).isAfter(moment((new Date()).setFullYear(9999)))) {
  //      console.log('after');
  //      this.firstValue = new Date().setFullYear(9999).toString();
  //    }
  //  }
  //}
  localeText = {
    // for filter panel
    notEqual: 'Does not equal',
    // for number filter
    notContains: 'Does not contain',
  }
  printAllDisplayedRows() {
    var count = this.gridApi.getDisplayedRowCount();
    this.clientsListFiltered = new Array<Clientmodel>();
    //console.log("## printAllDisplayedRows");
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      let model = new Clientmodel();
      model.serialNo = i + 1;
      model.lawFirm_Name = rowNode.data.lawFirm_Name;
      model.name = rowNode.data.name;
      model.leadDefendantName = rowNode.data.leadDefendantName;
      model.plaintiffIdentifier = rowNode.data.plaintiffIdentifier;
      model.plaintiffIdentifierBilling = rowNode.data.plaintiffIdentifierBilling;
      model.receivedDate = rowNode.data.receivedDate;
      model.processed_date = rowNode.data.processed_date;
      model.service_Status = rowNode.data.service_Status;
      model.served_date = rowNode.data.served_date;
      this.clientsListFiltered.push(model);
    }
  }
  values = null;
  Search() { // without type info

    
    if (this.selectedValue == 7) {
      //2019 - 06 - 14 09: 42: 02.753
      // to get the last record of the selected date. Records are searched with time in SQL
     //  console.log(this.firstValue);
      if (!this.isEmptyOrSpaces(this.firstValue) && !this.isEmptyOrSpaces(this.secondValue)) {
        this.values = this.firstValue + " 00:00:00.000" + "," + this.secondValue + " 23:59:59.999";
        //console.log(this.values)
        if (this.selectedValue == 7) {
          if (new Date(this.firstValue).getFullYear() < 1900) {
            this.errorMessage = "Please provide valid from date";
            this.errorMessage1 = "";
            return;
          } else if (new Date(this.secondValue).getFullYear() < 1900) {
            this.errorMessage1 = "Please provide valid to date";
            this.errorMessage = "";
            return;
          }
          else if (new Date(this.secondValue) < new Date(this.firstValue))
          {
            this.errorMessage1 = "To date must be greaterthan or equal to from date ";
            this.errorMessage = "";
            return;
          }
        }
      }
      else if (this.isEmptyOrSpaces(this.firstValue) && this.isEmptyOrSpaces(this.secondValue)) {
        this.errorMessage = "Please provide valid from date";
        this.errorMessage1 = "Please provide valid to date";
        return;
      }
      else if (this.isEmptyOrSpaces(this.firstValue)) {
        this.errorMessage = "Please provide valid from date";
        return;
      } else if (this.isEmptyOrSpaces(this.secondValue)) {
        this.errorMessage1 = "please provide valid to date";
         return;
      }
    }
    else
      this.values = this.firstValue;
    if (this.selectedValue == 5 || this.selectedValue == 6) {
      this.values = "";
      if (this.getUserRole()) {
        let model = new SearchModel();
        model.searchString = this.values;
        model.searchType = this.selectedValue;
        model.userID = this.auth.getLoggedInUser().id;
        this.dataService.getUserClientSearchResult(model).subscribe(data => {
          //this.gridColumnsArragment(1);
          this.turnPopupTrigger = true;
          this.clientsList = JSON.parse(JSON.stringify(data));
          var result = this.groupData();
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            result = _.orderBy(result, i => i.receivedDate, 'desc');
            //this.sortByconditions("receivedDate", "desc")
          } else {
            result = _.orderBy(result, i => i.name, 'asc');
          }
          let i = 0;
          result.forEach(function (value) {
            value.serialNo = i + 1;
            i++;
          });
          this.gridData = result;
          this.rowData = result;
          this.searchedResult = data;
          this.listCount = this.rowData.length;
          // console.log(result);
        });
      } else {
        let model = new SearchModel();
        model.searchString = this.values;
        model.searchType = this.selectedValue;
        this.dataService.getNHClientSearchResult(model).subscribe(data => {
          //this.gridColumnsArragment(1);
          this.turnPopupTrigger = true;
          this.clientsList = JSON.parse(JSON.stringify(data));
          var result = this.groupData();
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            result=  _.orderBy(result, i => i.receivedDate, 'desc');
            //this.sortByconditions("receivedDate", "desc")
          } else {
            result = _.orderBy(result, i => i.name, 'asc');
          }
          let i = 0;
          result.forEach(function (value) {
            value.serialNo = i + 1;
            i++;
          });
          this.gridData = result;
          this.rowData = result;
          this.searchedResult = data;
          this.listCount = this.rowData.length;
          this.errorMessage = "";
          this.errorMessage1 = "";
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            this.sortByconditions("receivedDate", "desc")
          } else {
            this.sortByconditions("name", "asc")
          }
          if (this.listCount == 0)
            this.nodata = true;
          else
            this.nodata = false;
          // console.log(result);
        });
      }

    }
    else if (!this.isEmptyOrSpaces(this.values) && this.selectedValue > 0) {
      this.errorMessage = "";
      if (this.getUserRole()) {
        let model = new SearchModel();
        model.searchString = this.values;
        model.searchType = this.selectedValue;
        model.userID = this.auth.getLoggedInUser().id;
        this.dataService.getUserClientSearchResult(model).subscribe(data => {
          this.turnPopupTrigger = true;
          this.clientsList = JSON.parse(JSON.stringify(data));
          var result = this.groupData();
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            result = _.orderBy(result, i => i.receivedDate, 'desc');
            //this.sortByconditions("receivedDate", "desc")
          } else {
            result = _.orderBy(result, i => i.name, 'asc');
          }
          let i = 0;
          result.forEach(function (value) {
            value.serialNo = i + 1;
            i++;
          });
          this.gridData = result;
          this.rowData = result;
          this.searchedResult = data;
          this.listCount = this.rowData.length;
          this.errorMessage = "";
          this.errorMessage1 = "";
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            this.sortByconditions("receivedDate", "desc")
          } else {
            this.sortByconditions("name", "asc")
          }
          if (this.listCount == 0)
            this.nodata = true;
          else
            this.nodata = false;
        });
      } else {
        let model = new SearchModel();
        model.searchString = this.values;
        model.searchType = this.selectedValue;

        this.dataService.getNHClientSearchResult(model).subscribe(data => {
          this.turnPopupTrigger = true;
          this.clientsList = JSON.parse(JSON.stringify(data));
          var result = this.groupData();
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            result = _.orderBy(result, i => i.receivedDate, 'desc');
            //this.sortByconditions("receivedDate", "desc")
          } else {
            result = _.orderBy(result, i => i.name, 'asc');
          }
          let i = 0;
          result.forEach(function (value) {
            value.serialNo = i + 1;
            i++;
          });
          this.gridData = result;
          this.rowData = result;
          this.searchedResult = data;
          this.listCount = this.rowData.length;
          this.errorMessage = "";
          this.errorMessage1 = "";
          if (this.selectedValue == 5 || this.selectedValue == 6 || this.selectedValue == 7) {
            this.sortByconditions("receivedDate", "desc")
          } else {
            this.sortByconditions("name", "asc")
          }
          if (this.listCount == 0)
            this.nodata = true;
          else
            this.nodata = false;
        });
      }

    } else {
      switch (this.selectedValue) {
        case 1:
          this.errorMessage = "Please provide a case number";
          break;
        case 2:
          this.errorMessage = "Please provide a JobID";
          break;
        case 3:
          this.errorMessage = "Please provide a plaintiff name";
          break;
        case 4:
          this.errorMessage = "Please provide an ordernumber";
          break;
        case 7:
          this.errorMessage ="Please provide Date Range"
        default:
          this.errorMessage = "";
          this.errorMessage1 = "";
          break;
      }
    }
   


    
    //this.sizeToFit();
  }
  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }
  searchClick(event) {
    if (event.key == "Enter") {
      this.Search();
    }
  }
  selectionChanged() {
    if (this.selectedValue == 5 || this.selectedValue == 6) {
      this.Search()
      this.showTextBox = false;
    }
    else {
      this.showTextBox = true;
    }
    this.firstValue = ''; this.secondValue = '';
    this.errorMessage = "";
    this.errorMessage1 = "";
  }

  groupData(): any {
    // console.log(this.clientsList);
    var result = _.chain(this.clientsList).groupBy("name").map(function (v, i) {
      v = _.orderBy(v, i => i.receivedDate, 'desc');
      return {
        serialNo: _.get(_.find(v, 'serialNo'), 'serialNo'),
        lawFirm_Name: _.get(_.find(v, 'lawFirm_Name'), 'lawFirm_Name'),
        name: i,
        leadDefendantName: _.get(_.find(v, 'leadDefendantName'), 'leadDefendantName'),
        plaintiffIdentifier: _.get(_.find(v, 'plaintiffIdentifier'), 'plaintiffIdentifier'),
        plaintiffIdentifierBilling: _.get(_.find(v, 'plaintiffIdentifierBilling'), 'plaintiffIdentifierBilling'),
        receivedDate: _.get(_.find(v, 'receivedDate'), 'receivedDate'),
        processed_date: _.get(_.find(v, 'processed_date'), 'processed_date'),
        service_Status: _.get(_.find(v, 'service_Status'), 'service_Status'),
        served_date: _.get(_.find(v, 'served_date'), 'served_date'),
        courtName: _.get(_.find(v, 'courtName'), 'courtName'),
        clients: _.map(v)
      }
    }).value();

    //var result1 = _.chain(result).groupBy("name").map(function (v, i) {
    //  v = _.orderBy(v, i => i.receivedDate, 'desc');
    //  return {
    //    serialNo: _.get(_.find(v, 'serialNo'), 'serialNo'),
    //    lawFirm_Name: _.get(_.find(v, 'lawFirm_Name'), 'lawFirm_Name'),
    //    name: i,
    //    leadDefendantName: _.get(_.find(v, 'leadDefendantName'), 'leadDefendantName'),
    //    plaintiffIdentifier: _.get(_.find(v, 'plaintiffIdentifier'), 'plaintiffIdentifier'),
    //    plaintiffIdentifierBilling: _.get(_.find(v, 'plaintiffIdentifierBilling'), 'plaintiffIdentifierBilling'),
    //    receivedDate: _.get(_.find(v, 'receivedDate'), 'receivedDate'),
    //    processed_date: _.get(_.find(v, 'processed_date'), 'processed_date'),
    //    service_Status: _.get(_.find(v, 'service_Status'), 'service_Status'),
    //    served_date: _.get(_.find(v, 'served_date'), 'served_date'),
    //    courtName: _.get(_.find(v, 'courtName'), 'courtName'),
    //    clients: _.map(v)
    //  }
    //}).value();
   // console.log(result1);
   // this.sizeToFit();
    return result;
  }

  sortByconditions(colname: string, sortvalue: string) {
   // console.log("sorted")
    var sort = [
      {
        colId: colname,
        sort: sortvalue
      }
    ];
    if (!isUndefined(this.gridApi)) {
      this.gridApi.setSortModel(sort);
    }
  }

  getUserRole(): boolean {
    this.dataService.userRoleName.forEach((v) => {
      if (v == Roles.ClientAdmin || v == Roles.ClientStaff) {
        this.clientAdminStaff = true;
      } else {
        this.clientAdminStaff = false;
      }
    })
    return this.clientAdminStaff;
  }

}
