import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LoginUser } from '../Models/Authentication/LoginUser';
import { WebApiHandler } from './webApiHandler';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { Userdetails } from '../Models/UserManagment/userdetails';

@Injectable({ providedIn: 'root' })
export class AuthenticateService {

  //public currentUserSubject: BehaviorSubject<LoginUser>;
  //public currentUser: Observable<LoginUser>;

  private webApiHandler: WebApiHandler;
  requestHeaders: HttpHeaders = new HttpHeaders();
  private router: Router;  
  public isAuthenticated: Boolean;
  public isAuth;

  // Used for get Logged-In User.
  public checkLoggedIn: boolean = false;

  constructor(private http: HttpClient, route: Router, private cookieService: CookieService) {

    //if (CookieService.get("AuthToken") != null)

    // this.currentUserSubject = new BehaviorSubject<LoginUser>(JSON.parse(sessionStorage.getItem('currentUser')));// new BehaviorSubject<LoginUser>(JSON.parse(localStorage.getItem('currentUser')));

    this.router = route;
    //moment.tz.setDefault("America/New_York");
    this.webApiHandler = new WebApiHandler(http, this.router);
  }

  //public get currentUserValue(): LoginUser {
  //  return this.currentUserSubject.value;
  //}
 
  public async IsAuthenticated(): Promise<boolean> {
    //let loggedInObject = new BehaviorSubject<LoginUser>(JSON.parse(sessionStorage.getItem('currentUser')));
    //if (loggedInObject.value != null) {
    //  return true;
    //}
    //return false;

    // we might don't need this... maybe?
    var _this = this;

    let loggedinUser: Object = this.getCurentCookie();

    if (loggedinUser != null) {
      let ExpireDate = this.getExpireDate();

      if (moment(ExpireDate).utc() > moment(new Date()).utc()) {
        this.isAuthenticated = true;
        return true;
      } else {
        this.isAuthenticated = false;
      }

    } else {
      this.isAuthenticated = false;
    }

    ///
    if (!this.isAuthenticated) {
     await this.webApiHandler
        .GetJsonResponse("api/Authentication/IsAuthenticated", this.requestHeaders)
        .subscribe(response => {

          if (response.isAuthenticated) {

            this.checkLoggedIn = response.isAuthenticated;

            //_this.router.navigate(["/"]);
            
            // sessionStorage.setItem("currentUser", JSON.stringify(response.userObject));
              //if (result.rememberMe) {
              //  localStorage.setItem("currentUser", JSON.stringify(result));
            //}

            return true;
          } else {
            _this.cookieService.delete("currentUser");
            _this.router.navigate(["authentication"]);
            return false;
          }

        }
        );
    }
    
    //if (localStorage.getItem("currentUser") == null && sessionStorage.getItem("currentUser") == null) {
    //  if (sessionStorage.getItem("currentUser") == null) {
    //    //_this.router.navigate(["authentication"]);
    //    return true;
    //  }
    //}
    //else if (localStorage.getItem("currentUser") != null || sessionStorage.getItem("currentUser") != null) {
    //  this.webApiHandler
    //    .GetJsonResponse("api/Authentication/IsAuthenticated", this.requestHeaders)
    //    .subscribe(response => {

    //      if (response.isAuthenticated) {
    //        _this.router.navigate(["/"]);
    //        return true;
    //      } else {
    //        _this.router.navigate(["authentication"]);
    //        return false;
    //      }

    //    }
    //    );

    //}
    //return true;

  }

  public get IsTokenExpired(): boolean {
    let token = this.AuthenticateObject.token;
    var claims = JSON.parse(atob(token.split('.')[1]));
    var expirationDate = new Date(claims.exp * 1000);
    if (expirationDate < new Date()) {
      sessionStorage.removeItem('currentUser');
      return true;
    }
    return false;
  }

  public get AuthenticateObject(): LoginUser {
    return <LoginUser>(JSON.parse(sessionStorage.getItem('currentUser')));
  }

  login(username: string, password: string, rememberme: boolean) {
    var self = this;
    return this.webApiHandler.PostJsonResponse('api/authentication/authenticate', { UserName: username, Password: password, RememberMe: rememberme }, this.requestHeaders)
      //return this.http.post<any>('api/authentication/authenticate', { UserName: username, Password: password, RememberMe: rememberme })
      .pipe(map((user) => {
        // login successful if there's a jwt token in the response
        if (user) {

          // storing into cookies or session or in local storage is stored in Auth-component.

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //sessionStorage.setItem('currentUser', JSON.stringify(user));
          //this.CookieService.set("AuthToken", JSON.stringify(user));
          //self.currentUserSubject.next(user);
          //if (rememberme) {
          //  localStorage.setItem('currentUser', JSON.stringify(user));
          //}
          //self.currentUserSubject = user;
        }
        return user;
      }));
  }

  storeLoggedInUser(user: LoginUser) {
    try {

    }
    catch (err) {
      console.log(err);
    }
  }
  
  getExpireDate(): Date {
    let date: Date = null;
    if (this.cookieService.check("cookieExpiration") != null) {
      date = new Date(this.cookieService.get("cookieExpiration").split('"')[1]);
      //console.log('expire', date);
    }
    return date;
  }

  getLoggedInUser(): Userdetails {
   
    try {      
      let obj: Userdetails = null;

      // check will return boolean;
      if (this.cookieService.check('currentUser')) {
        obj = JSON.parse(this.cookieService.get('currentUser'));
      } else {
        
        if (this.cookieService.check('cookieExpiration')) {
          if (moment(this.getExpireDate()).utc() > moment(new Date()).utc()) {
            // console.log('expired cookie');
            this.cookieService.delete('cookieExpiration');
            this.router.navigate(["authentication"]);
          }
        }

      }
      
      // check the current user
      if (!this.cookieService.check('currentUser')) {

        // we are not using "checkLoggedIn" before navigating to the login page.
        // We might use it based on feature request.
       if (!(this.router.url === '/authentication')) {          
         this.router.navigate(["authentication"]);
        // console.log('navigate to the login');
       }

      }
      //console.log(obj);
      //obj.role = obj.roles[0];
      return obj;
    } catch (err) {
      console.log(err);
    }

  }

  getCurentCookie(): Object {
    try {
      let obj: Object = null;
      if (this.cookieService.check("currentUser")) {
        obj = JSON.parse(this.cookieService.get("currentUser"));
      }
      return obj;
    } catch (err) {
      console.log(err);
    }
  }

  logout() {
  // remove user from local storage to log user out
  //localStorage.removeItem('currentUser');
  //this.CookieService.delete("AuthToken");
  this.cookieService.delete('currentUser');
  var _this = this;
  return this.webApiHandler.GetJsonResponse("api/Authentication/Signout", this.requestHeaders)
    .subscribe(resp => {
      if (resp.status == "SUCCESS") {
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem("currentUser");
        //this.currentUserSubject.next(null);
        _this.router.navigate(["authentication"]);
      }
    });
  }

  registration(payload) {

    return this.webApiHandler
      .PostJsonResponse('api/Authentication/Registration', payload, this.requestHeaders)
      .pipe(map(response => {
        return response;
      }));

  }

  forgotpasswordApi(payload) {
    return this.http.post<string>('api/Authentication/generate-token', payload);
    //return this.http.post<any>('api/forgotpassword', { email: Email} );
  }

  changePassword(newPassword: string, token: string) {
    var payload = { Password: newPassword, PasswordToken: token };
    return this.http.post<any>('api/Authentication/reset-password', payload);
  }

}
