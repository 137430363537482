import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Core/Services/data.service';
import { PopupService } from 'src/app/Shared/Popup/popup.service';
import { Ridersummarysearch, Ridersummarydetails } from 'src/app/Core/Models/Clients/ridersummarysearch';
import { isUndefined } from 'util';
import { DateTypes, PriorityTypes } from 'src/app/Core/Models/Admin/NHAdmin/job-dashboard-model';
import { GridOptions } from 'ag-grid-community';
import { ExcelService } from 'src/app/Core/Services/ExcelService/excel.service';

@Component({
  selector: 'nh-rider-summary',
  templateUrl: './rider-summary.component.html',
  styleUrls: ['./rider-summary.component.less']
})
export class RiderSummaryComponent implements OnInit {
  overlayNoRowsTemplate: string;
  private gridApi;
  private paginationProxy;
  private gridColumnApi;
  private rowSelection;

  showSearch: boolean;
  toggleButtonText: string;
  displayError: boolean;
  errorText: string;
  private gridOptions: GridOptions;
  private columnDefs: any[];
  public rowData: any[];
  dateTypes = new Array<DateTypes>();
  batchPriorityTypes = new Array<PriorityTypes>();
  ridersummarysearch = new Ridersummarysearch();
  ridersummarylist = new Array<Ridersummarydetails>();
  static thisClass: any;

  constructor(private dataservice: DataService, private popupService: PopupService, private excelService: ExcelService) {
    this.gridColumnsArragment();
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
  }

  ngOnInit() {
    this.toggleButtonText = "Hide search options";
    this.showSearch = true;
    this.ridersummarysearch.caseId = "";
    this.ridersummarysearch.priorityID = 0;
   // this.ridersummarysearch.status = "<< Any >>";
    this.ridersummarysearch.dateFilterType = 0;
    this.dataservice.getGetBatchPriorityList().subscribe(priorityList => {
      this.batchPriorityTypes.push({ batchPriority_ID: -1, batchDescr: "<< Any>>", batchPriority: "<< Any >>" });
      priorityList.forEach((item) => {
        this.batchPriorityTypes.push(item);
      });
      this.batchPriorityTypes.push({ batchPriority_ID: 0, batchDescr: " All", batchPriority: " All " });
    });
    this.dateTypes.push({ dateTypeID: 0, dateTypeName: "<< Select One >>" });
    this.dateTypes.push({ dateTypeID: 1, dateTypeName: "Submit Date" });
    this.dateTypes.push({ dateTypeID: 2, dateTypeName: "Serve By Date" });


    this.ridersummarylist.push({ caseId: "1-op-0783", plaintiffRefId: "229991", plaintiffName: "John Edwards", priority: "Standard", submitDate: "05/03/2019", serveByDate: "05/10/2019", ServedDetails: "3/5", eFiledDetails:"3/5",  invoicedDetails: "1/5" });
    this.ridersummarylist.push({ caseId: "1-op-0783", plaintiffRefId: "229890", plaintiffName: "John Edwards", priority: "Standard", submitDate: "05/03/2019", serveByDate: "05/10/2019", ServedDetails: "3/5", eFiledDetails:"3/5",  invoicedDetails: "1/5" });
    this.ridersummarylist.push({ caseId: "1-op-0783", plaintiffRefId: "229991", plaintiffName: "John Edwards", priority: "Standard", submitDate: "05/03/2019", serveByDate: "05/10/2019", ServedDetails: "3/5", eFiledDetails:"3/5",  invoicedDetails: "1/5" });
    this.ridersummarylist.push({ caseId: "1-op-0783", plaintiffRefId: "229991", plaintiffName: "John Edwards", priority: "Standard", submitDate: "05/03/2019", serveByDate: "05/10/2019", ServedDetails: "3/5", eFiledDetails:"3/5",  invoicedDetails: "1/5" });
    this.ridersummarylist.push({ caseId: "1-op-0783", plaintiffRefId: "229991", plaintiffName: "John Edwards", priority: "Standard", submitDate: "05/03/2019", serveByDate: "05/10/2019", ServedDetails: "3/5", eFiledDetails: "3/5", invoicedDetails: "1/5" });
    this.rowData = this.ridersummarylist;

    let rider = new Ridersummarysearch();
    rider.isDefault = 1;

    this.dataservice.getRiderSummaryResults(rider).subscribe(data => { 
      this.ridersummarylist = JSON.parse(JSON.stringify(data));
      this.rowData = this.ridersummarylist;
    });

  }
  toggleSearch() {
    if (this.showSearch) {
      this.showSearch = false;
      this.toggleButtonText = "Show search options";
    } else {
      this.showSearch = true;
      this.toggleButtonText = "Hide search options";
    }
  }
  riderSummarySearch() {
    
    this.ridersummarysearch.isDefault = 0;
    let search = this.ridersearchModel();
    // console.log(search);
    if (search.isDefault == 1) {
      this.displayError = true;
      this.errorText = "Please select atleast one search criteria to see the results";
    } else if (search.isDefault == 2) {
      this.displayError = true;
    }
    else {
      this.displayError = false;
      if (!isUndefined(this.gridApi))
        this.gridApi.paginationGoToFirstPage();
      this.dataservice.getRiderSummaryResults(search).subscribe(data => {
        this.ridersummarylist = JSON.parse(JSON.stringify(data));
        this.rowData = this.ridersummarylist;
      });
    }
  }
  ridersearchModel(): Ridersummarysearch {
    let search = new Ridersummarysearch();
    search.isDefault = 0;
    search.plaintiffRefId = this.ridersummarysearch.plaintiffRefId == "" ? null : this.ridersummarysearch.plaintiffRefId;
    search.plaintiffName = this.ridersummarysearch.plaintiffName == "" ? null : this.ridersummarysearch.plaintiffName;
    search.caseId = this.ridersummarysearch.caseId == "" ? null : this.ridersummarysearch.caseId;

    if (this.ridersummarysearch.dateFilterType != 0) {   
      search.dateFilterType = this.ridersummarysearch.dateFilterType;
    } else {
      search.dateFilterType = 0;
    }
    search.fromDate = this.ridersummarysearch.fromDate == "" ? null : this.ridersummarysearch.fromDate;
    search.toDate = this.ridersummarysearch.toDate == "" ? null : this.ridersummarysearch.toDate;

    if (parseInt(this.ridersummarysearch.priorityID.toString()) != -1) {      
      search.priorityID = parseInt(this.ridersummarysearch.priorityID.toString());
    } else {
      search.priorityID = -1;
    }

    if (search.plaintiffRefId == null && search.plaintiffName == null && search.caseId == null && search.dateFilterType == 0 && search.fromDate == null && search.toDate == null && search.priorityID == -1) {
      search.isDefault = 1;
    }
    if (search.priorityID == -1) {
      search.priorityID = 0;
    }

    return search;
  }
  searchClick(event) {
    // console.log(event);
    if (event.key == "Enter") {
      this.riderSummarySearch();
    }
  }
  onGridReady(params) {
    this.paginationProxy = params.api;

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  gridColumnsArragment() {
    //if (type == 0) {
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
    };
    this.columnDefs = [
      { headerName: 'Case #', field: 'caseId', width: 60, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
      { headerName: 'Plaintiff Ref. #', field: 'plaintiffRefId', width: 65, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" }  },
      { headerName: 'Plaintiff Name ', field: 'plaintiffName', width: 120, sortingOrder: ["asc", "desc"] },
      { headerName: 'Priority', field: 'priority', width: 80, sortingOrder: ["asc", "desc"] },
      { headerName: 'Submit Date', field: 'submitDate', width: 100, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" }},
      { headerName: 'Serve By Date', field: 'serveByDate', width: 100, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" } },
      { headerName: 'Served (#/Total)', field: 'ServedDetails', width: 80, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" } },
      { headerName: 'e-Filed (#/Total)', field: 'eFiledDetails', width: 80,  sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" } },
      { headerName: 'Invoiced (#/Total)', field: 'invoicedDetails', width: 80, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "center" } },      
    ];    
    this.rowSelection = 'single';
  }
  resetColumns() {
    this.gridColumnApi.resetColumnState();
  }
  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  sizeToFit() {
    if (!isUndefined(this.gridApi))
      this.gridApi.sizeColumnsToFit();   
  }
  onCellClicked(event: any) { }// console.log('cell', event); }
  onGridSizeChanged(params) {
    if (document.getElementById("grid-wrapper") != null) {
      var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    }
  }

  exportRidersAsXLSX(): void {
    let header: any;
    header = ["Case #", "Plaintiff Ref. #", "Plaintiff Name", "Priority",
      "Submit Date", "Serve By Date", "Served (#/Total)", "e-Filed (#/Total)", "Invoiced (#/Total)" ];

    var exportList = this.ridersummarylist;
    this.excelService.exportAsExcelFile(exportList, "RiderExcel", header);

    //let job = this.searchModel();
    //if (job.isDefault == 0) {
    //  this.displayError = false;
    //  this.dataservice.getReportResults(this.searchModel()).subscribe(data => {
    //    var list = JSON.parse(JSON.stringify(data));
    //    var exportList = new Array<ReportsModel>();
    //    let i = 0;
    //    list.forEach((value, index) => {

    //      let model = new ReportsModel();
    //      model.serialNo = i == 0 ? (1 + index) : (i + index);
    //      //  if (this.dateStatus) {
    //      model.lawFirm_Name = value.lawFirm_Name;
    //      model.caseId = value.caseId;
    //      model.batchPriority = value.batchPriority;
    //      model.plaintiffIdentifierBilling = value.plaintiffIdentifier;
    //      model.defFullAddress = this.titleCaseWord(value.defFullAddress.replace("#", " ").replace("#", ""));
    //      model.receivedDate = value.receivedDate;
    //      model.status = value.status;
    //      model.served_date = value.served_date;
    //      model.s_CommentSeqNum = value.s_CommentSeqNum;
    //      exportList.push(model);

    //    });
    //    this.excelService.exportAsExcelFile(exportList, this.reportName.replace(" ", "_").replace(" ", "_"), header);
    //  });
    //} else {
    //  this.displayError = true;
    //  this.errorText = "Please select at lease one criterion to download";
    //}
  }
}
