import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'ng-forgot-password',
  templateUrl: `./ForgotPassword.html`,
  //host: {['class']: "wrapperbox"},
})

export class ForgotPasswordComponent implements OnInit {

  public emailForm: FormGroup;
  public submitted = false;

  private data: any;

  validationmsgs =  {
                      "required": "Email is required",
                      "email": "Invalid email format",
                    };

    constructor(private router: Router,
                private formBuilder: FormBuilder,
                private authenticationService: AuthenticateService
    ) {
    }

    get g() { return this.emailForm.controls }

    ngOnInit() {

      this.emailForm = this.formBuilder.group({
                        email: ['', [Validators.required,
                                     Validators.pattern(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/)
            ]]});

    }

  getGeneratedToken() {

    this.submitted = true;

    type Email = {
      Email: any;
    }

    const payload = {} as Email;

    payload.Email = this.g.email.value;

    if (this.emailForm.invalid) {
      return;
    }

    var token = this.authenticationService
                    .forgotpasswordApi(payload)
                    .subscribe(
                          (response) => {

                          try {
                            this.data = response;
                          } catch (e) {
                            console.log(e)
                          }

                    },
                    (err: HttpErrorResponse) => {
                            console.log('error: ',err.error);
                            console.log('error-Name: ',err.name);
                            console.log('error-Message: ', err.message);
                            console.log('error-Status: ',err.status);
                    });
  }


  loginPage() {
    this.router.navigate(['/authentication']);
  }

}

