import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { PopupConfig } from '../../../../Shared/Popup/popup-config';
import { PopupRef } from '../../../../Shared/Popup/popup-ref';
import { Clientmodel } from 'src/app/Core/Models/Clients/clientmodel';
import { ExcelService } from 'src/app/Core/Services/ExcelService/excel.service';
import { DataService } from 'src/app/Core/Services/data.service';
import { isUndefined } from 'util';
@Component({
  selector: 'nh-clientdetails',
  templateUrl: './clientdetails.component.html',
  styleUrls: ['./clientdetails.component.less']
})
export class ClientdetailsComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  private rowSelection;
  errorMessage: string = "";
  overlayNoRowsTemplate: string;
  private gridOptions: GridOptions;
  public rowData: any[];
  private columnDefs: any[];
  clientsList = new Array<Clientmodel>();
  clientsListFiltered = new Array<Clientmodel>();
  model: Clientmodel;
  localeText = {
    // for filter panel
    notEqual: 'Does not equal',
    // for number filter
    notContains: 'Does not contain',
  }
  constructor(private popupConfig: PopupConfig, private popup: PopupRef, private excelService: ExcelService, private ds: DataService) {
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data</span>';
    this.model = this.popupConfig.data.message[0];
    this.model.wrappedleadDefendant = this.ds.wrapText(this.model.leadDefendantName);
    this.gridOptions = <GridOptions>{
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMovable: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
      }, onModelUpdated: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
    };
    if (this.popupConfig.pageName == "pendingServices") {
      this.columnDefs = [
        {
          headerName: '#', field: 'serialNo', width: 45, sortingOrder: ["asc", "desc"]
        },
        { headerName: 'JobID', field: 'jobID', tooltipField: 'jobID', filter: true, width: 90, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
        { headerName: 'Case #', field: 'case_num', tooltipField: 'case_num', filter: true, width: 124, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
        { headerName: 'Order #', field: 'ordernumber', tooltipField: 'ordernumber', filter: true, width: 125, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
        { headerName: 'Received', field: 'receivedDate', tooltipField: 'receivedDate', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Defendant', field: 'def_name', tooltipField: 'def_name', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Processed', field: 'processed_date', tooltipField: 'processed_date', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Sent Status', field: 'status', tooltipField: 'status', filter: true, width: 120, sortingOrder: ["asc", "desc"] },
        { headerName: 'Served Status', field: 'service_Status', tooltipField: 'service_Status', filter: true, width: 125, sortingOrder: ["asc", "desc"] },
      ];
    } else {
      this.columnDefs = [
        {
          headerName: '#', field: 'serialNo', width: 45, sortingOrder: ["asc", "desc"]
        },
        { headerName: 'JobID', field: 'jobID', tooltipField: 'jobID', filter: true, width: 90, sortingOrder: ["asc", "desc"] , cellStyle: { textAlign: "right" } },
        { headerName: 'Case #', field: 'case_num', tooltipField: 'case_num', filter: true, width: 124, sortingOrder: ["asc", "desc"], cellStyle: { textAlign: "right" } },
        { headerName: 'Order #', field: 'ordernumber', tooltipField: 'ordernumber', filter: true, width: 125, sortingOrder: ["asc", "desc"] ,cellStyle: { textAlign: "right" } },
        { headerName: 'Received', field: 'receivedDate', tooltipField: 'receivedDate', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Defendant', field: 'def_name', tooltipField: 'def_name', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Processed', field: 'processed_date', tooltipField: 'processed_date', filter: true, width: 130, sortingOrder: ["asc", "desc"] },
        { headerName: 'Sent Status', field: 'status', tooltipField: 'status', filter: true, width: 120, sortingOrder: ["asc", "desc"] },
        { headerName: 'Served Status', field: 'service_Status', tooltipField: 'service_Status', filter: true, width: 125, sortingOrder: ["asc", "desc"] },
        { headerName: 'Served Date', field: 'served_date', tooltipField: 'served_date', filter: true, width: 125, sortingOrder: ["asc", "desc"] },
      ];
    }
    let i = 0;
    this.popupConfig.list.forEach((value)=> {
      value.serialNo = i + 1;
      value.wrappedleadDefendant = this.ds.wrapText(value.leadDefendantName)
      i++;
    });
    this.rowData = this.popupConfig.list;
    this.clientsList = JSON.parse(JSON.stringify(this.popupConfig.list));
    this.rowSelection = 'single';
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.resetColumnState();
    this.sortByconditions("receivedDate", "desc");

    //this.sizeToFit();
  }
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }
  sizeToFit() {
    this.gridApi.sizeColumnsToFit();
  }
  onGridSizeChanged(params) {
    if (document.getElementById("grid-wrapper") != null) {
      var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        let column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
    }
    params.api.sizeColumnsToFit();
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = "";
    selectedRows.forEach(function (selectedRow, index) {
      if (index !== 0) {
        selectedRowsString += ", ";
      }
      selectedRowsString += selectedRow.serialNo;
    });
   // console.log(selectedRows[0]);
    this.model = selectedRows[0];
   // this.model = selectedRows[0];
   // this.model
  }
  ngOnInit() {
  }
  exportToExcel() {
    var _this = this;
    let header = [];
    if (this.popupConfig.pageName == "pendingServices") {
      header = ["Serial", "Client", "Plaintiff",
        "Lead Defendant", "Plaintiff Ref. #", "Plaintiff Billing Ref. #", "Court", "JobID", "Case #", "Order #", "ReceivedDate", "Defendant",
        "Processed Date",  "Sent Status",
        "Served Status"]
    } else {
      header = ["Serial", "Client", "Plaintiff",
        "Lead Defendant", "Plaintiff Ref. #", "Plaintiff Billing Ref. #", "Court", "JobID", "Case #", "Order #", "ReceivedDate", "Defendant",
        "Processed Date", "Sent Status",
        "Served Status","Served Date"]
    }
    let t = 0;
    this.clientsListFiltered = new Array<Clientmodel>();
    this.clientsList.forEach((value, index) =>{
      value.serialNo = t + 1;
      value.lawFirm_Name = value.lawFirm_Name;
      value.name = value.name;
      value.leadDefendantName = value.leadDefendantName;
      value.plaintiffIdentifier = value.plaintiffIdentifier;
      value.plaintiffIdentifierBilling = value.plaintiffIdentifierBilling;
      value.courtName = value.courtName;
      value.receivedDate = value.receivedDate;
      value.def_name = value.def_name;
      value.status = value.status;
      value.jobID = value.jobID;
      value.ordernumber = value.ordernumber;
      value.processed_date = value.processed_date;
      value.case_num = value.case_num;
      value.service_Status = value.service_Status;
      t++;
      this.clientsListFiltered.push(value);
    });
    this.excelService.exportAsExcelFile(this.clientsListFiltered, "clientdetails", header);
  }
  //printAllDisplayedRows() {
  //  var count = this.gridApi.getDisplayedRowCount();
  //  this.clientsListFiltered = new Array<Clientmodel>();
  //  //console.log("## printAllDisplayedRows");
  //  for (var i = 0; i < count; i++) {
  //    var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
  //    let model = new Clientmodel();
  //    model.serialNo = i + 1;
  //    model.lawFirm_Name = rowNode.data.lawFirm_Name;
  //    model.name = rowNode.data.name;
  //    model.leadDefendantName = rowNode.data.leadDefendantName;
  //    model.plaintiffIdentifier = rowNode.data.plaintiffIdentifier;
  //    model.plaintiffIdentifierBilling = rowNode.data.plaintiffIdentifierBilling;
  //    let index = this.clientsList.findIndex(i => i.plaintiffIdentifier == model.plaintiffIdentifier && i.leadDefendantName == model.leadDefendantName);
  //    model.courtName = this.clientsList[index].courtName;
  //    model.receivedDate = rowNode.data.receivedDate;
  //    model.processed_date = rowNode.data.processed_date;
  //    model.service_Status = rowNode.data.service_Status;
  //    model.served_date = rowNode.data.served_date;
  //    this.clientsListFiltered.push(model);
  //  }
  //}
  sortByconditions(colname: string, sortvalue: string) {
    // console.log("sorted")
    var sort = [
      {
        colId: colname,
        sort: sortvalue
      }
    ];
    if (!isUndefined(this.gridApi)) {
      this.gridApi.setSortModel(sort);
    }
  }
}
