export class Role {
  roleid: number;
  roleName: string;
}
export  class Roles {
  static  KSAdmin = "KSAdmin";
  static NHAdmin = "NHAdmin";
  static NHStaff = "NHStaff";
  static ClientAdmin = "ClientAdmin";
  static ClientStaff = "ClientStaff";
}
