import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiderSummaryComponent } from './rider-summary/rider-summary.component';
import { clientsrouting } from './clientsrouter';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RiderSummaryComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    clientsrouting,
    AgGridModule.withComponents([RiderSummaryComponent])
  ]
})
export class ClientsModule { }
