import { WorkAreaView } from './WorkAreaModel';
import { DefendantModel } from './DefendantModel';

export class WorkAreaBatchModel {
  workAreaSummaryStarted: string;
  workAreaDetailsStarted: string;
  workAreaSummaryEnded: string;
  workAreaDetailsEnded: string;
  workAreaNextUpdate: string;
  signalRStarted: string;
  signalRUpdatedIn: string;
  workStatusInProgress: Array<WorkAreaView>;
  defStatCompleted: Array<DefendantModel>;
}
