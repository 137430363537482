import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from 'src/app/Core/Services/authenticate.service';
import { DataService } from 'src/app/Core/Services/data.service';
import { Router } from '@angular/router';
import { NavigationModel } from 'src/app/Core/Models/Navigation/NavigationModel';
import { Roles } from 'src/app/Core/Models/Admin/NHAdmin/role';
import { isUndefined } from 'util';
import { Userdetails } from 'src/app/Core/Models/UserManagment/userdetails';

@Component({
  selector: 'nh-adminnav',
  templateUrl: './adminnav.component.html',
  styleUrls: ['./adminnav.component.less']
})
export class AdminnavComponent implements OnInit {
  authenticateService: AuthenticateService;
  private NavigationList: Array<NavigationModel>;
  private AdminNavList: Array<NavigationModel>;
  userDetails: Userdetails;
  userRoles: Array<string>;
  constructor(private authentication: AuthenticateService, private router: Router
    , private ds: DataService) {

    this.authenticateService = authentication;
  }

  ngOnInit() {

    this.userDetails = this.authentication.getLoggedInUser();


    if (this.NavigationList == undefined) {

      this.NavigationList = new Array<NavigationModel>();
      //TODO We need to get the menu items from Database
      for (var i = 0; i < 6; i++) {
        switch (i) {
          case 0:
            let nv = new NavigationModel();
            nv.redirectURL = "/admin";
            nv.title = "Admin";
            nv.attrib = "admin";
            nv.nestedActive = true;
            this.NavigationList.push(nv);
            break;   break;
          case 1:
            let nv1 = new NavigationModel();
            nv1.redirectURL = "/services-status";
            nv1.title = "Service Info";
            nv1.attrib = "Services";
            this.NavigationList.push(nv1);
            break;
          case 2:
            //let out = new NavigationModel();
            //out.redirectURL = "/nhusers";
            //out.title = "User Roles";
            //out.attrib = "User-Roles";

            //this.NavigationList.push(out);
            break;
          case 3:
            //let upload = new NavigationModel();
            //upload.redirectURL = "/create-jobs";
            //upload.title = "Create Jobs";
            //upload.attrib = "Create-Jobs";
            //this.NavigationList.push(upload);
            break;
          case 4:
            //let fromExcel = new NavigationModel();
            //fromExcel.redirectURL = "/create-jobs";
            //fromExcel.title = "Create Job";
            //fromExcel.attrib = "Create-Jobs";
            //fromExcel.nestedNav.push({
            //  redirectURL: "/create-jobs",
            //  title: "import from excel",
            //  attrib: "import from excel"
            //});
            //fromExcel.nestedNav.push({
            //  redirectURL: "from-form",
            //  title: "manual",
            //  attrib: "manual"
            //});
            //this.NavigationList.push(fromExcel);
            break;
          case 5:
            break;
        }
      }
      //if (this.userDetails != null && !isUndefined(this.userDetails) && this.userDetails.roles != null && !isUndefined(this.userDetails.roles) && this.userDetails.roles.indexOf(',') >= 0) {
      //  if (this.userDetails.roles.split(',').findIndex(i => i == Roles.NHAdmin) >= 0) {
      //    this.AdminNavList = new Array<NavigationModel>();
      //    for (var i = 0; i < 4; i++) {
      //      switch (i) {
      //        case 0:
      //          this.AdminNavList.push({ title: "Add User", redirectURL: "/nhregisteruser", attrib: "nhadduser", nestedActive: false, nestedNav: null })
      //          break;
      //        case 1:
      //          this.AdminNavList.push({ title: "Reset User", redirectURL: "/nhresetuser", attrib: "nhresetuser", nestedActive: false, nestedNav: null })
      //          break;
      //        case 2:
      //          this.AdminNavList.push({ title: "Clients", redirectURL: "/nhclientlist", attrib: "nhclientlist", nestedActive: false, nestedNav: null })
      //          break;
      //        case 3:
      //          this.AdminNavList.push({ title: "Pending Services", redirectURL: "/nhpendingservices", attrib: "nhpendingservices", nestedActive: false, nestedNav: null })
      //          break;
      //        default:
      //          break
      //      }
      //    }
      //  }
      //}
      //else {
      //  if (this.userDetails != null && !isUndefined(this.userDetails) && this.userDetails.roles != null && !isUndefined(this.userDetails.roles) && this.userDetails.roles == Roles.NHAdmin) {
      //    this.AdminNavList = new Array<NavigationModel>();
      //    for (var i = 0; i < 4; i++) {
      //      switch (i) {
      //        case 0:
      //          this.AdminNavList.push({ title: "Add User", redirectURL: "/nhregisteruser", attrib: "nhadduser", nestedActive: false, nestedNav: null })
      //          break;
      //        case 1:
      //          this.AdminNavList.push({ title: "Reset User", redirectURL: "/nhresetuser", attrib: "nhresetuser", nestedActive: false, nestedNav: null })
      //          break;
      //        case 2:
      //          this.AdminNavList.push({ title: "Clients", redirectURL: "/nhclientlist", attrib: "nhclientlist", nestedActive: false, nestedNav: null })
      //          break;
      //        case 3:
      //          this.AdminNavList.push({ title: "Pending Services", redirectURL: "/nhpendingservices", attrib: "nhpendingservices", nestedActive: false, nestedNav: null })
      //          break;

      //        default:
      //          break
      //      }
      //    }
      //  }
      //}
      //console.log(this.NavigationList);
    }
  }
  logout() {
    // console.log('logging out');
    sessionStorage.removeItem('currentUser');
    // this.NavigationList.find(i => i.title == "Work Area") ? this.NavigationList.find(i => i.title == "Work Area").isActive = true :
    //localStorage.removeItem('currentUser');
    //this.authenticateService.currentUserSubject.next(null);
    this.authenticateService.logout();
    //this.router.navigate(['/authentication'])
  }
  navClicked(redirectUrl: string) {

    this.NavigationList.forEach(function (value, index) {
      if (redirectUrl == value.redirectURL) {
        value.nestedActive = true;
      } else {
        value.nestedActive = false;
      }
    });

  }

}
