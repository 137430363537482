import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from '../authenticate.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate  {

  constructor(private _authService: AuthenticateService, private _router: Router) {
    
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = this._authService.getLoggedInUser();
   // console.log(next.data.role, user.roles);
    if (JSON.stringify(user.roles) === JSON.stringify(next.data.role)) {
      return true;
    } else if ((user.roles.indexOf(next.data.role) >= 0) || next.data.role.indexOf(user.roles) >= 0) {
      // console.log(next.data.roles, user.roles);
      return true;
    } else {
      let list = next.data.role.split(',');
      for (var i = 0; i < list.length; i++) {
        if (user.roles.indexOf(list[i]) >= 0) {
          return true;
        }
      }
    }

    // navigate to not found page
    this._router.navigate(['/404']);
    return false;
  }

}
