import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlaintiffModel } from '../../Core/Models/WorkArea/PlainTiffModel'
import { DataService } from '../../Core/Services/data.service';
import { PopupComponent } from '../../Shared/Popup/popup.component';
import { PopupService } from '../../Shared/Popup/popup.service';
import { WorkareadetailsComponent } from '../workareadetails/workareadetails.component';
import { WorkAreaView, Priority } from 'src/app/Core/Models/WorkArea/WorkAreaModel';
import { DefendantModel } from 'src/app/Core/Models/WorkArea/DefendantModel';
import { SignalRService } from 'src/app/Core/Services/SignalR/signal-r.service';
import { WorkAreaBatchModel } from 'src/app/Core/Models/WorkArea/WorkAreaBatchModel';
import { LoaderService } from 'src/app/Core/Services/Loader/loader.service';
import { isUndefined } from 'util';
import { OrderNumberInfo } from 'src/app/Core/Models/CreateJob/lawFrimModel';
//import { AlertComponent } from 'src/app/Shared/alert/alert.component';

@Component({
  selector: 'nh-workarea',
  templateUrl: './workarea.component.html',
  styleUrls: ['./workarea.component.less'],
  host: { '[class]': 'myCustomStyle' }
})

export class WorkareaComponent implements OnInit, AfterViewInit {
  show: boolean = false;
  public pingResponse: string;
  ComponentName = "Work-Area";
  myCustomStyle: string = "wrapper";

  //workAreaServices: WorkareaService;
  router: Router;
  //stocks = [];
  plaintiffs: Array<PlaintiffModel>;
  workAreaDashBoard: Array<WorkAreaView> = new Array<WorkAreaView>();
  completedDefendants: Array<DefendantModel>;
  temp: WorkAreaBatchModel;
  workAreaCompleted: Array<DefendantModel> = new Array<DefendantModel>();
  workAreaSearch: Array<WorkAreaView>;
  workAreaBatchModel: WorkAreaBatchModel;
  showNoRecords: boolean = false;
  areaStatus: String;
  showFilteredText: boolean = false;
  textMessage: string;
  constructor(
    private route: Router,
    private workAreaService: DataService,
    private popupService: PopupService,
    private signalR: SignalRService,
    private loader: LoaderService) {
    this.workAreaService.IsAdminPage = false;
    this.router = route;

    try {
      var _this = this;
      this.loader.show();
      this.workAreaService.getWorkAreaDashBoard().subscribe((data) => {
        this.workAreaBatchModel = data;
        this.loader.hide();
        if (this.workAreaBatchModel != undefined) {
          this.workAreaDashBoard = this.workAreaBatchModel.workStatusInProgress;
          this.workAreaService.serviceTime = this.workAreaBatchModel.workAreaNextUpdate;
          if (this.workAreaDashBoard != undefined) {
            if (this.workAreaDashBoard.length == 0) {
              this.showNoRecords = true;
              this.textMessage = "All done."
            }
            for (var i = 0; i < this.workAreaDashBoard.length; i++) {
              //console.log(this.workAreaDashBoard[i].defStatInProgress);
              if (this.workAreaDashBoard[i].defStatInProgress != undefined) {
                this.workAreaDashBoard[i].defStatInProgress.forEach(function (v) {
                  v.def_trimmedName = _this.workAreaService.wrapText(v.def_name);
                });
                //this.workAreaDashBoard[i].heighOfDefList = this.workAreaDashBoard[i].defStatInProgress.length * 30+"px";
                if (this.workAreaDashBoard[i].defStatInProgress[0] != undefined)
                  this.workAreaDashBoard[i].defStatInProgress[0].isActive = true;
              }
              this.workAreaDashBoard[i].displayDefendant = "none";
              this.workAreaDashBoard[i].showDefendants = false;
              //console.log("dasd");
            }
          }
        }
        // let newObj = JSON.parse(JSON.stringify(this.workAreaBatchModel));
        //var copyArray = $.extend({}, countryDataMap);
        this.temp = JSON.parse(JSON.stringify(this.workAreaBatchModel));

        //this.temp = Object.create(this.workAreaBatchModel);
      });
      if (!signalR.connectionIsEstablished) {
        signalR.connectionEstablished.subscribe(() => {
          //console.log('started ')
          this.openWorkarea();
          //Open connection and Start straming workarea!!
        });
        signalR.wokrAreaStarted.subscribe(() => {
          this.areaStatus = 'open';
          this.startStrearming();
        });
      }
    } catch (e) {
      this.loader.hide();
      console.log(e);
    }

  }

  updateWorkArea(workAreaBatchModel: WorkAreaBatchModel) {
    //Need to modify this need to update the required components

    try {

      // console.log(this.workAreaService.searchedWord.length);

      if (this.workAreaService.searchedWord.length < 1) {
        // console.log("Updated", new Date());
        if (workAreaBatchModel != undefined) {

          this.workAreaCompleted = workAreaBatchModel.defStatCompleted;

          if (this.workAreaDashBoard != undefined) {
            let _this = this;

            if (this.workAreaDashBoard.length > 0) {

              workAreaBatchModel.workStatusInProgress.forEach(function (value, index) {

                _this.workAreaDashBoard.forEach(function (value1, index1) {

                  if (value.lawFirm_ID == value1.lawFirm_ID) {

                    value1.defendantCount = value.defendantCount;
                    value1.plaintiffsCount = value.plaintiffsCount;
                    let temp = value.defStatInProgress.find(i => i.buttonText == "Process");

                    if (!isUndefined(temp)) {
                      value1.buttonText = "All to Process";
                      value1.buttonDisabled = false;
                    }

                    _this.showFilteredText = false;
                    //  if (value1.defStatInProgress.length != value.defStatInProgress.length)
                    value1.defStatInProgress = value.defStatInProgress;
                    value1.defStatInProgress.forEach(function (v) {
                      v.def_trimmedName = _this.workAreaService.wrapText(v.def_name);
                    });
                    if (value1.priorities != value.priorities)
                      value1.priorities = value.priorities;

                  }

                });

              });

            } else {

              this.workAreaDashBoard = workAreaBatchModel.workStatusInProgress;

              if (workAreaBatchModel.workStatusInProgress.length == 0) {
                this.showNoRecords = true;
                this.textMessage = "All done."
              }

            }

          }

        }
      }

    } catch (e) {
      console.log(e);
    }

  }

  openWorkarea() {
    this.signalR.openWorkArea();
  }

  startStrearming() {
    //console.log("straming");
    try {
      this.signalR.startStreaming().subscribe({
        next: (data) => {
          // console.log("updated");
          //console.log(data);
          let workAreadata: WorkAreaBatchModel = data;
          this.workAreaService.serviceTime = workAreadata.workAreaNextUpdate;
          // console.log(this.workAreaService.serviceTime);
            this.updateWorkArea(workAreadata);
        },
        error: function (err) {
          console.log('Error:' + err);
        },
        complete: function () {
          console.log('completed');
        }
      });
    } catch (e) {
      console.log(e);
    }

  }

  ngAfterViewInit() { }

  ngOnInit() {
  }

  //getOrders() {
  //  let or = new OrderNumberInfo();
  //  or.apiKey = "Test";
  //  or.firmKey = "Test";
  //  or.orders = new Array<string>();
  //  or.orders.push("2163-386");
  //  or.orders.push("297-254");
  //  or.orders.push("2163-356");
  //  console.log(JSON.stringify(or))
  //  this.workAreaService.getOrderNumbers(or).subscribe(data => {
  //    console.log(data,"OutPUT");
  //  });
  //}

  @ViewChild('viewDetailsPopup') viewDetail: PopupComponent;
  viewDetails(model: DefendantModel) {
    this.popupService.open(WorkareadetailsComponent, { data: { message: model }, pageName: "workareadetails", list: this.workAreaDashBoard.find(i => i.lawFirm_ID == model.lawFirm_ID).defStatInProgress, vH: 55, vW: 74 });
  }

  makeActive(workArea: WorkAreaView, model: DefendantModel) {
    try {
      if (model.isActive) {
        model.isActive = false;
      } else {
        workArea.defStatInProgress.forEach(function (value) {
          value.isActive = false;
        });
        model.isActive = true;

      }
    } catch (e) {
      console.log(e);
    }

  }

  showDefendants(workArea: WorkAreaView) {
    try {
      if (workArea.showDefendants || workArea.displayDefendant == "flex") {
        workArea.displayDefendant = "none";
        workArea.showDefendants = false;
      }
      else {
        // console.log("dada")
        this.workAreaDashBoard.forEach(function (value) {
          if (value.showDefendants) {
            value.displayDefendant = "none";
            value.showDefendants = false;
          }
        });
        workArea.displayDefendant = "flex";
        workArea.showDefendants = true;

      }
    } catch (e) {
    }
  }

  //showDefendantsCompleted(workArea: WorkAreaModel) {
  //  try {
  //    if (workArea.showDefendants || workArea.displayDefendant == "flex") {
  //      workArea.displayDefendant = "none";
  //      workArea.showDefendants = false;
  //    } else {
  //      this.workAreaCompleted.forEach(function (value) {
  //        if (value.showDefendants) {
  //          value.displayDefendant = "none";
  //          value.showDefendants = false;
  //        }
  //      });
  //      workArea.displayDefendant = "flex";
  //      workArea.showDefendants = true;

  //    }
  //  } catch (e) {
  //  }
  //}

  sendToPST(defendantModel: DefendantModel) {
    try {
      if (defendantModel != undefined) {
        if (defendantModel.defIDS != null && defendantModel.defIDS != "") {
          //defendantModel.
          this.workAreaService.sendToPST(defendantModel).subscribe(data => {
            if (data != undefined) {
              let response = (data as string);
              if (response.includes("Success")) {
                defendantModel.logStatus = "Queued";
                defendantModel.buttonDisabled = true;
                defendantModel.buttonText = "Queued";
              }
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
    //this.popupService.open(AlertComponent, { data: { message: defendantModel }, pageName: "alert" } );
  }

  sendAllToPST(workarea: WorkAreaView) {
    try {
      if (workarea != undefined) {
        workarea.buttonDisabled = true;
        this.workAreaService.sendAllToPST(workarea).subscribe(data => {
          if (data != undefined) {
            let response = (data as string);
            if (response.includes("Success")) {
              workarea.buttonText = "Queued";
              workarea.defStatInProgress.forEach(function (value) {
                value.buttonText = "Queued";
                value.buttonDisabled = true;
              })
            }
          }

        });
      }
    } catch (e) {
      workarea.buttonDisabled = false;

    }
  }

  // get's searched details from the search component to the incoming log.
  searchValues(data: any) {
    try {

      if (!isUndefined(data)) {
        if (data == 'reset') {
          this.showFilteredText = false;
          this.showNoRecords = false;
          //console.log('return search....', data);
          this.workAreaDashBoard = this.temp.workStatusInProgress;
        } else if (data == 'expand all') {
          this.showFilteredText = false;
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = true;
            value.displayDefendant = "flex";
          });
        } else if (data == 'collapse all') {
          this.showFilteredText = false;
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = false;
            value.displayDefendant = "none";
          });
        } else {
          let count = this.workAreaDashBoard.length;
          // console.log('return search....', data);
          this.workAreaDashBoard = data;
          this.showFilteredText = true;
          this.workAreaDashBoard.forEach(function (value) {
            value.showDefendants = true;

            value.filteredCount = "Filtered Defs: " + value.defStatInProgress.length;
            value.displayDefendant = "flex";
            if (value.defStatInProgress.length == 0)
              count = count - 1;
            //this.wo
          });
          if (count <= 0) {
            this.textMessage = "No records found."
            this.showNoRecords = true
          }
          else
            this.showNoRecords = false;
        }
      }
    } catch (e) {
      console.log(e);
    }


  }

}
