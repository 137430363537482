import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'nh-defendantaddress',
  templateUrl: './defendantaddress.component.html',
  styleUrls: ['./defendantaddress.component.less']
})
export class DefendantaddressComponent implements ICellRendererAngularComp {

  //constructor() { }
  address= new Array<string>();
  public params: any;

  agInit(params: any): void {
  //  console.log(params.data.defFullAddress);
    params.data.defFullAddress.split("#").forEach((v, i) => {
     this.address.push(v);
    });
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

}
